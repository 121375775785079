import { State } from 'modules';

export const emailTemplate = (state: State) => state.settings.emailTemplate;
export const selectedTemplateBlockId = (state: State) =>
  state.settings.selectedBlockId;

export const selectedSidebarTab = (state: State) =>
  state.settings.selectedSidebarTab;

export const selectedScreenSize = (state: State) =>
  state.settings.selectedScreenSize;

export const selectedMainTab = (state: State) => state.settings.selectedMainTab;
export const selectedTemplate = (state: State) =>
  state.settings.selectedTemplate;

export const loadCheckTemplatePending = (state: State) =>
  state.settings.loadCheckTemplatePending;

export const saveCheckTemplatePending = (state: State) =>
  state.settings.saveCheckTemplatePending;
