import {
  OrganizationAdminMenu,
  OrganizationUserMenu,
} from './OrganizationMenu';
import { UserMenu } from './UserMenu';

import { useSelector } from 'third-party';

import { appSelectors } from 'selectors';

export const useMenu = () => {
  const selected = useSelector(appSelectors.selectedOrganization);
  const isAdmin = useSelector(appSelectors.isAdminUser);

  if (!selected) {
    return UserMenu();
  }

  if (selected && isAdmin) {
    return [
      ...OrganizationUserMenu(selected.organization.id),
      ...OrganizationAdminMenu(selected.organization.id),
    ];
  }

  return OrganizationUserMenu(selected.organization.id);
};
