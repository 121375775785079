import React, { useEffect, useRef, useState } from 'react';

import {
  barcodes,
  checkbox,
  dateTime,
  ellipse,
  image,
  line,
  radioGroup,
  rectangle,
  select,
  table,
  text,
  time,
} from '@pdfme/schemas';
import { Viewer } from '@pdfme/ui';
import {
  AvatarPhotoEditor,
  Box,
  Button,
  Loader,
  LoadingButton,
  PageHeader,
  SignaturePad,
  Tab,
  Tabs,
} from 'components';
import { useNotifications } from 'hooks';

import {
  Designer,
  Template,
  getInputFromTemplate,
  useNavigate,
  useSelector,
  useTheme,
  useTranslation,
} from 'third-party';

import { bankCheckListUrl } from 'constants/url';

import { useSettings } from 'modules/setting';

import { appSelectors, settingSelectors } from 'selectors';

import { getSampleTemplate } from 'features/CheckDesigner/sampleData';

import { getImageBase64FromForm } from 'utils/check';
import { removeNullFields } from 'utils/helper';

import { PrintType } from 'types/api';

const plugins = {
  text,
  image,
  qrcode: barcodes.qrcode,
  line,
  table,
  rectangle,
  ellipse,
  dateTime,
  time,
  select,
  checkbox,
  radioGroup,
};

const defaultTemplate = getSampleTemplate();
type CreateEditProps = {
  onModalClose: () => void;
  bankId?: string | null;
};

export const SimpleBankDesigner: React.FC<CreateEditProps> = ({
  onModalClose,
  bankId,
}) => {
  const loadCheckTemplatePending = useSelector(
    settingSelectors.loadCheckTemplatePending,
  );

  const [templates, setTemplates] = useState<Template[]>([]);

  const { loadCheckTemplate } = useSettings();

  useEffect(() => {
    const loadTemplate = async (bankId: string, type: PrintType) => {
      const result = await loadCheckTemplate(bankId, type);

      if (result.payload == null) {
        setTemplates(it => [defaultTemplate, ...it]);
        return;
      }

      const cleanPayload = removeNullFields(result.payload);
      setTemplates(it => [cleanPayload, ...it]);
    };

    if (!bankId) {
      return;
    }

    loadTemplate(bankId, PrintType.Check).then(() =>
      loadTemplate(bankId, PrintType.WhitePaper),
    );
  }, [bankId, loadCheckTemplate]);

  if (loadCheckTemplatePending || templates.length < 1) {
    return <Loader />;
  }

  return (
    <CreateEditForm
      bankId={bankId}
      checkTemplates={templates}
      onModalClose={onModalClose}
    />
  );
};

type Props = {
  checkTemplates: Template[];
  bankId?: string | null;
  onModalClose: () => void;
};

export const CreateEditForm: React.FC<Props> = ({
  checkTemplates,
  bankId,
  onModalClose,
}) => {
  const avatarEditorRef =
    React.useRef<React.ElementRef<typeof AvatarPhotoEditor>>(null);

  const signaturePadRef =
    React.useRef<React.ElementRef<typeof SignaturePad>>(null);

  const { t } = useTranslation();
  const navigate = useNavigate();

  const saveCheckTemplatePending = useSelector(
    settingSelectors.saveCheckTemplatePending,
  );

  const organizationId = useSelector(appSelectors.organizationId);
  const designerRef = useRef<HTMLDivElement | null>(null);
  const designer = useRef<Designer | null>(null);
  const theme = useTheme();
  const { success } = useNotifications();

  const [templates, setTemplates] = useState<Template[]>(checkTemplates);
  const [prevDesignerRef, setPrevDesignerRef] = useState<Designer | null>(null);

  const { saveCheckTemplate } = useSettings();

  useEffect(() => {
    //@ts-ignore
    if (designerRef.current && designerRef != prevDesignerRef) {
      if (prevDesignerRef && designer.current) {
        designer.current.destroy();
      }

      // Moved buildDesigner function inside useEffect
      if (designerRef.current && templates) {
        //@ts-ignore
        designer.current = new Viewer({
          domContainer: designerRef.current,
          template: templates[0],
          inputs: getInputFromTemplate(templates[0]),
          plugins: plugins,
          options: {
            lang: 'en',
            theme: {
              token: {
                colorPrimary: theme.custom.palette.primary300,
                colorPrimaryHover: theme.custom.palette.primary800,
              },
            },
          },
        });
      }

      //@ts-ignore
      setPrevDesignerRef(designerRef);
    }
  }, [templates, prevDesignerRef, theme]);

  const handleSave = async () => {
    if (bankId && templates) {
      await saveCheckTemplate(bankId, templates[0], PrintType.WhitePaper);
      await saveCheckTemplate(bankId, templates[1], PrintType.Check);

      success(t('simpleCheckDesigner.success'));
      onModalClose();
    }
  };

  const handleCancel = () => {
    onModalClose();
  };

  const updateTemplate = (
    index: number,
    filedName: 'logo' | 'signature',
    content: string,
  ) => {
    const result = templates[index]?.schemas[0].map(item => {
      if (item.name === filedName) {
        return { ...item, content: content };
      }
      return item;
    });

    if (result) {
      const obj = {
        ...templates[index],
        schemas: [result],
        basePdf: templates[index]?.basePdf || '',
      } as Template;

      setTemplates(it => it.map((item, i) => (i === index ? obj : item)));
      setPrevDesignerRef(null);
      designer.current?.updateTemplate(obj);
    }
  };

  const handleAvatarUpdate = async () => {
    const avatarFormData = await avatarEditorRef.current?.getAvatarImage();
    if (!avatarFormData) {
      return;
    }

    const logoContent = await getImageBase64FromForm(avatarFormData);

    updateTemplate(0, 'logo', logoContent);
    updateTemplate(1, 'logo', logoContent);
  };

  const handleAdvancedDesignerClick = () => {
    navigate(bankCheckListUrl(organizationId, bankId!));
  };

  const avatar = templates[0]?.schemas[0].find(item => item.name === 'logo');
  const signature = templates[0]?.schemas[0].find(
    item => item.name === 'signature',
  );

  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  function CustomTabPanel(props: any) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box sx={{ pt: 2 }}>{children}</Box>}
      </div>
    );
  }

  const handleSignatureSave = async (form: FormData | null) => {
    if (!form) return;

    const logoContent = await getImageBase64FromForm(form, 'signature');

    updateTemplate(0, 'signature', logoContent);
    updateTemplate(1, 'signature', logoContent);
  };

  return (
    <Box padding="1rem  1rem  0 1rem">
      <PageHeader
        header={t('simpleCheckDesigner.header')}
        subHeader={t('simpleCheckDesigner.subHeader')}
      >
        <Box display="flex" columnGap="1rem">
          <LoadingButton
            type="submit"
            variant="outlined"
            disabled={saveCheckTemplatePending}
            loading={saveCheckTemplatePending}
            onClick={handleSave}
            size="medium"
            color="primary"
          >
            {t('common.form.save')}
          </LoadingButton>
          <Button
            variant="outlined"
            disabled={saveCheckTemplatePending}
            onClick={handleAdvancedDesignerClick}
            size="medium"
            color="secondary"
          >
            {t('simpleCheckDesigners.advancedDesigner')}
          </Button>
          <Button
            type="button"
            variant="outlined"
            onClick={handleCancel}
            size="medium"
            color="error"
          >
            {t('common.form.cancel')}
          </Button>
        </Box>
      </PageHeader>
      <Box display="flex" flexDirection="row" gap="2rem">
        <Box width="20rem">
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab label="Check Logo" {...a11yProps(0)} />
              <Tab label="Signature" {...a11yProps(1)} />
            </Tabs>
          </Box>
          <CustomTabPanel value={value} index={0}>
            <AvatarPhotoEditor
              ref={avatarEditorRef}
              //@ts-ignore
              isRound={false}
              avatarUrl={avatar?.content ?? ''}
              onAvatarSave={handleAvatarUpdate}
            />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <Box width="19rem" height="10rem" border="1px solid black">
              <SignaturePad
                ref={signaturePadRef}
                defaultSignature={signature?.content ?? ''}
                onSave={handleSignatureSave}
              />
            </Box>
          </CustomTabPanel>
        </Box>
        <div
          ref={designerRef}
          style={{
            width: '25rem',
          }}
        />
      </Box>
    </Box>
  );
};
