import React from 'react';

import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
import ApiOutlinedIcon from '@mui/icons-material/ApiOutlined';
import BarChartRoundedIcon from '@mui/icons-material/BarChartRounded';
import CreditCardRoundedIcon from '@mui/icons-material/CreditCardRounded';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import HomeWorkOutlinedIcon from '@mui/icons-material/HomeWorkOutlined';
import ManageSearchRoundedIcon from '@mui/icons-material/ManageSearchRounded';
import PaymentsOutlinedIcon from '@mui/icons-material/PaymentsOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';

import i18n from 'i18n';

import {
  apiSpecUrl,
  appUrl,
  bankListUrl,
  memberListUrl,
  organizationDashboardUrl,
  payeeListUrl,
  paymentListUrl,
  settingsUrl,
  userActivityListUrl,
  userOrganizationPayeeListUrl,
  userOrganizationPaymentListUrl,
} from 'constants/url';

import { MenuType } from 'types/app';

export const OrganizationUserMenu = (organizationId: string): MenuType[] => [
  {
    subHeader: 'Main menu',
    items: [
      {
        url: () => appUrl(),
        text: 'Back to home',
        icon: <HomeWorkOutlinedIcon color="primary" fontSize="small" />,
      },
      {
        url: () => organizationDashboardUrl(organizationId),
        text: 'Dashboard',
        icon: <BarChartRoundedIcon color="primary" fontSize="small" />,
      },
      {
        url: () => userOrganizationPaymentListUrl(organizationId),
        text: i18n.t('menu.myPayments'),
        icon: <CreditCardRoundedIcon color="primary" fontSize="small" />,
      },
      {
        url: () => userOrganizationPayeeListUrl(organizationId),
        text: i18n.t('menu.myPayees'),
        icon: <PaymentsOutlinedIcon color="primary" fontSize="small" />,
      },
    ],
  },
];

export const OrganizationAdminMenu = (organizationId: string): MenuType[] => [
  {
    subHeader: 'Organization',
    items: [
      {
        url: () => bankListUrl(organizationId),
        text: 'Banks',
        icon: <AccountBalanceOutlinedIcon color="primary" fontSize="small" />,
      },
      {
        url: () => paymentListUrl(organizationId),
        text: i18n.t('menu.allPayments'),
        icon: <CreditCardRoundedIcon color="primary" fontSize="small" />,
      },
      {
        url: () => payeeListUrl(organizationId),
        text: i18n.t('menu.allPayees'),
        icon: <PaymentsOutlinedIcon color="primary" fontSize="small" />,
      },
      {
        url: () => memberListUrl(organizationId),
        text: 'Members',
        icon: <GroupOutlinedIcon color="primary" fontSize="small" />,
      },
      {
        url: () => userActivityListUrl(organizationId),
        text: 'User activity',
        icon: <ManageSearchRoundedIcon color="primary" fontSize="small" />,
      },
      {
        url: () => settingsUrl(organizationId),
        text: 'Settings',
        icon: <SettingsOutlinedIcon color="primary" fontSize="small" />,
      },
      {
        url: () => apiSpecUrl(organizationId),
        text: 'API',
        icon: <ApiOutlinedIcon color="primary" fontSize="small" />,
      },
    ],
  },
];
