import React, { useCallback, useEffect, useRef, useState } from 'react';

import ClearIcon from '@mui/icons-material/Clear';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';

import {
  BusinessOutlined,
  CreditCardOutlined,
  DateRangeOutlined,
  FilterAlt,
  FilterAltOff,
  LocalPrintshopOutlined,
  PaidOutlined,
  PaymentsOutlined,
  PrintDisabledOutlined,
} from '@mui/icons-material';
import {
  Box,
  Button,
  CardBox,
  CardBoxLabel,
  Chip,
  CircularProgress,
  EmptyState,
  IconButton,
  Loader,
  ModalBox,
  PageHeader,
  TextField,
  Tooltip,
} from 'components';
import { useIsMobile } from 'hooks';

import { PaymentRequestForm } from './PaymentRequestForm';

import {
  _,
  dayjs,
  i18n,
  styled,
  useNavigate,
  useParams,
  useSelector,
  useTranslation,
} from 'third-party';

import {
  PaymentColors,
  PaymentStatus,
  TOOLTIP_APPEAR_DELAY,
  USER_INPUT_DEBOUNCE_TIME_MS,
} from 'constants/common';
import {
  printAnonECheckUrl,
  userPaymentDetailsUrl,
  userPaymentOrganizationDetailsUrl,
} from 'constants/url';

import { usePayment } from 'modules/payment';

import { paymentSelectors } from 'selectors';

import {
  getOrganizationAvatarUrl,
  moneyFormatter,
  userCanDownloadCheck,
} from 'utils/app';

import { APIPayment, APIPaymentStatus } from 'types/api';

const StyleInput = styled(TextField)`
  .MuiInput-input {
    padding: 0.4rem !important;
    min-width: 18rem;
  }
`;

const cardActions = (item: APIPayment) => {
  const canUserDownloadCheck = userCanDownloadCheck(item);

  return (
    <Box
      display="flex"
      width="100%"
      alignItems="center"
      justifyContent="space-between"
    >
      <Chip
        label={PaymentStatus[item.status as APIPaymentStatus]}
        style={{
          height: '1.5rem',
          padding: '0rem',
          marginLeft: '0.3rem',
          marginTop: '0.2rem',
          backgroundColor: PaymentColors[item.status as APIPaymentStatus],
        }}
      />
      {canUserDownloadCheck && (
        <Tooltip
          placement="top-start"
          enterDelay={TOOLTIP_APPEAR_DELAY}
          enterNextDelay={TOOLTIP_APPEAR_DELAY}
          title={`${i18n.t('paymentList.cardActions.print')}`}
        >
          <IconButton
            aria-label="Open"
            color="primary"
            onClick={() => window.open(printAnonECheckUrl(item.id), '_blank')}
          >
            <LocalPrintshopOutlined />
          </IconButton>
        </Tooltip>
      )}
      {!canUserDownloadCheck && (
        <Tooltip
          placement="top-start"
          enterDelay={TOOLTIP_APPEAR_DELAY}
          enterNextDelay={TOOLTIP_APPEAR_DELAY}
          title={`${i18n.t('paymentList.cardActions.printDisabled')}`}
        >
          {/*@ts-ignore */}
          <IconButton aria-label="Open" color="disabled">
            <PrintDisabledOutlined />
          </IconButton>
        </Tooltip>
      )}
    </Box>
  );
};

export const UserPaymentList = () => {
  const { t } = useTranslation();
  const [isFilteringOn, setIsFilteringOn] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { organizationId } = useParams();
  const isMobile = useIsMobile();

  const ref = useRef<HTMLInputElement>(null);
  const items = useSelector(paymentSelectors.paymentList);
  const allPaymentLoaded = useSelector(paymentSelectors.allPaymentsLoaded);
  const paymentFilters = useSelector(paymentSelectors.paymentFilters);
  const loadPaymentsPending = useSelector(
    paymentSelectors.loadUserPaymentsPending,
  );
  const loadMoreUserPaymentsPending = useSelector(
    paymentSelectors.loadMoreUserPaymentsPending,
  );

  const {
    loadUserPayments,
    loadUserMorePayments,
    updatePaymentFilters,
    resetPaymentFilters,
  } = usePayment();

  useEffect(() => {
    loadUserPayments();
  }, [paymentFilters, loadUserPayments, organizationId]);

  const navigate = useNavigate();

  const onCardClick = useCallback(
    (item: APIPayment) => {
      if (organizationId) {
        navigate(userPaymentOrganizationDetailsUrl(organizationId, item.id));
        return;
      }

      navigate(userPaymentDetailsUrl(item.id));
    },
    [navigate, organizationId],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onSearchInputChange = useCallback(
    _.debounce((event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target) {
        updatePaymentFilters({
          ...paymentFilters,
          name: event.target.value,
        });
      }
    }, USER_INPUT_DEBOUNCE_TIME_MS),
    [updatePaymentFilters, paymentFilters],
  );

  const handleScroll = useCallback(
    (event: React.UIEvent<HTMLElement>) => {
      const target = event.target as HTMLElement;
      const bottom =
        Math.abs(target.scrollHeight - target.scrollTop - target.clientHeight) <
        1;

      if (bottom && !loadMoreUserPaymentsPending && !allPaymentLoaded) {
        loadUserMorePayments();
      }
    },
    [allPaymentLoaded, loadMoreUserPaymentsPending, loadUserMorePayments],
  );

  const onFilterReset = useCallback(() => {
    resetPaymentFilters();
    if (ref.current) {
      ref.current.value = '';
    }
  }, [resetPaymentFilters]);

  const onModalClose = useCallback(() => {
    setIsModalOpen(false);
  }, []);

  return (
    <Box width="100%" overflow="auto" onScroll={handleScroll}>
      <Box
        position="sticky"
        top={0}
        zIndex={11}
        bgcolor="white"
        alignItems="center"
      >
        <PageHeader
          header={t('userPaymentList.header')}
          subHeader={t('userPaymentList.subHeader')}
        >
          <>
            <Button
              variant="text"
              onClick={() => setIsModalOpen(true)}
              startIcon={<PaymentsOutlined />}
            >
              {!isMobile && t('userPayment.requestPayment')}
            </Button>
            {isFilteringOn && (
              <Button variant="text" onClick={onFilterReset}>
                <ClearIcon />
              </Button>
            )}
            <Button variant="text" onClick={() => setIsFilteringOn(it => !it)}>
              {isFilteringOn ? <FilterAlt /> : <FilterAltOff />}
            </Button>
          </>
        </PageHeader>

        {isFilteringOn && (
          <Box padding="1rem 1rem 0 0 ">
            <SearchOutlinedIcon color="primary" />
            <StyleInput
              inputRef={ref}
              autoComplete="off"
              autoCorrect="off"
              id="input-with-sx"
              onChange={onSearchInputChange}
              variant="standard"
            />
          </Box>
        )}
      </Box>
      {loadPaymentsPending && <Loader />}
      {!loadPaymentsPending && (
        <Box
          paddingTop="1rem"
          display="flex"
          flexWrap="wrap"
          gap="1.5rem"
          sx={{
            overflow: 'hidden',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {items?.map(it => (
            <CardBox<APIPayment>
              item={it}
              key={it.id}
              avatarUrl={
                it.organization
                  ? getOrganizationAvatarUrl(it.organization as any)
                  : undefined
              }
              title={it.payee.nameOnCheck ?? ''}
              width="17rem"
              height="16rem"
              subTitle={it.organization.name}
              onClick={onCardClick}
              cardActions={cardActions}
            >
              <CardBoxLabel
                label={moneyFormatter(it.amount)}
                icon={PaidOutlined}
              />
              <CardBoxLabel
                label={dayjs(it.checkDate).format('MM/DD/YYYY')}
                icon={DateRangeOutlined}
              />
              <CardBoxLabel label={it.checkNumber} icon={CreditCardOutlined} />
              <CardBoxLabel
                label={`${it.payee.city}, ${it.payee.address}, ${it.payee.address}`}
                icon={BusinessOutlined}
              />
            </CardBox>
          ))}
          {!items?.length && (
            <EmptyState
              showResetFilterButton={isFilteringOn}
              onFilterReset={onFilterReset}
            />
          )}
        </Box>
      )}
      {loadMoreUserPaymentsPending && (
        <Box display="flex" justifyContent="space-around" padding="1rem">
          <CircularProgress />
        </Box>
      )}
      {isModalOpen && (
        <ModalBox width="33rem" height="27rem" onClose={onModalClose}>
          <PaymentRequestForm onModalClose={onModalClose} />
        </ModalBox>
      )}
    </Box>
  );
};
