import { useMemo } from 'react';

import { State } from 'modules';

import { bindActionCreators, useDispatch, useSelector } from 'third-party';

import { DEFAULT_PAGE_SIZE } from 'constants/common';
import { bankEndpoint, bankSingleEndpoint } from 'constants/endpoints';

import { appSelectors } from 'selectors';

import { getPageNumberAfterLoadMore } from 'utils/helper';
import { httpMethod, simplifyBuilder } from 'utils/sra';

import {
  APIBank,
  APIBankSearchFilter,
  APIBankSearchResult,
  APISortOrder,
} from 'types/api';

export type BankState = {
  banksList: APIBank[];
  bank: APIBank | null;
  loadBankPending: boolean;
  createBankPending: boolean;
  loadBanksPending: boolean;
  allBanksLoaded: boolean;
  banksListLoaded: boolean;
  filters: APIBankSearchFilter;
  pagination: {
    pageNumber: APIBankSearchResult['pageNumber'];
    pageSize: APIBankSearchResult['pageSize'];
  };
};

export const bankState: BankState = {
  banksList: [],
  bank: null,
  loadBankPending: false,
  filters: {
    searchSortOrders: [
      {
        sortField: 'createdOn',
        sortOrder: APISortOrder.DESC,
      },
    ],
  },
  createBankPending: false,
  loadBanksPending: false,
  banksListLoaded: false,
  allBanksLoaded: false,
  pagination: { pageNumber: 1, pageSize: DEFAULT_PAGE_SIZE },
};

const builder = simplifyBuilder(bankState, {});

const updateBankFilters = builder.createReduxAction(
  (filters: APIBankSearchFilter) => ({
    name: 'updateBankFilters',
    updater: () => ({
      filters,
    }),
  }),
);

export const loadBanks = builder.createServerAction(
  (organizationId: string) => ({
    name: 'loadBanks',
    url: bankEndpoint(organizationId),
    body: (state: State) => ({
      pageNumber: 1,
      pageSize: DEFAULT_PAGE_SIZE,
      ...state.bank.filters,
    }),
    method: httpMethod.post,
    onSuccess: (state: BankState, payload: APIBankSearchResult) => ({
      banksList: payload.result,
      banksListLoaded: true,
      allBanksLoaded: payload.result.length < payload.pageSize,
      pagination: {
        pageNumber: payload.pageNumber,
        pageSize: payload.pageSize,
      },
    }),
  }),
);

export const loadMoreBanks = builder.createServerAction(
  (organizationId: string) => ({
    name: 'loadMoreBanks',
    url: bankEndpoint(organizationId),
    body: (state: State) => ({
      pageNumber: getPageNumberAfterLoadMore(
        state.bank.banksList,
        state.bank.pagination.pageSize,
      ),
      ...state.bank.filters,
    }),
    method: httpMethod.post,
    onSuccess: (state: BankState, payload: APIBankSearchResult) => ({
      banksList: [...(state.banksList || []), ...payload.result],
      banksListLoaded: true,
      allBanksLoaded: payload.result.length < payload.pageSize,
      pagination: {
        pageNumber: payload.pageNumber,
        pageSize: payload.pageSize,
      },
    }),
  }),
);

export const loadBank = builder.createServerAction(
  (organizationId: string, bankId: string) => ({
    name: 'loadBank',
    url: bankSingleEndpoint(organizationId, bankId),
    method: httpMethod.get,
    onSuccess: (state: BankState, payload: APIBank) => ({
      bank: payload,
    }),
  }),
);

export const deleteBank = builder.createServerAction(
  (organizationId: string, bankId: string) => ({
    name: 'deleteBank',
    url: bankSingleEndpoint(organizationId, bankId),
    method: httpMethod.delete,
    onSuccess: (state: BankState) => ({
      banksList: state.banksList.filter(bank => bank.id !== bankId),
    }),
  }),
);

export const updateBank = builder.createServerAction(
  (organizationId: string, bankId: string, model: APIBank) => ({
    name: 'updateBank',
    url: bankSingleEndpoint(organizationId, bankId),
    method: httpMethod.post,
    body: model,
    onSuccess: (state: BankState, result: APIBank) => ({
      banksList: state.banksList.map(bank =>
        bank.id === bankId ? result : bank,
      ),
    }),
  }),
);

export const createBank = builder.createServerAction(
  (organizationId: string, model: APIBank) => ({
    name: 'createBank',
    url: bankEndpoint(organizationId),
    method: httpMethod.put,
    body: model,
    onSuccess: (state: BankState, result: APIBank) => ({
      banksList: [result, ...state.banksList],
    }),
  }),
);

export const clearBank = builder.createReduxAction(() => ({
  name: 'clearBank',
  updater: () => ({
    bank: null,
  }),
}));

export const useBank = () => {
  const dispatch = useDispatch();
  const organizationId = useSelector(appSelectors.organizationId);

  return useMemo(
    () =>
      bindActionCreators(
        {
          updateBankFilters,
          clearBank,
          createBank: createBank.bind(null, organizationId),
          updateBank: updateBank.bind(null, organizationId),
          deleteBank: deleteBank.bind(null, organizationId),
          loadBank: loadBank.bind(null, organizationId),
          loadBanks: loadBanks.bind(null, organizationId),
          loadMoreBanks: loadMoreBanks.bind(null, organizationId),
        },
        dispatch,
      ),
    [dispatch, organizationId],
  );
};

export const bankReducer = builder.getReducers();
