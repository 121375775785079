export const APP_PATH = process.env['APP_PATH'];
export const API_PATH = process.env['API_PATH'];
export const CDN_PATH = process.env['CDN_PATH'];

export const appUrl = () => `${APP_PATH}`;
export const notFoundUrl = () => `${appUrl()}404/`;

// anonymous routes
export const userFormUrl = () => `${appUrl()}user/`;
export const loginUrl = () => `${userFormUrl()}login`;
export const registerUrl = () => `${userFormUrl()}register`;
export const forgotPasswordUrl = () => `${userFormUrl()}forgot-password`;

// anonymous check routes
export const verifyCheckUrl = () => `${appUrl()}verify/`;
export const verifyCheckWithIdUrl = (code: string) =>
  `${verifyCheckUrl()}${code}`;

export const printECheckUrl = () => `${appUrl()}print-echeck/`;
export const printAnonECheckUrl = (eCheckId: string) =>
  `${printECheckUrl()}${eCheckId}`;

// user routes
export const organizationListUrl = () => `${appUrl()}organization/`;
export const userPayeeListUrl = () => `${appUrl()}payees/`;
export const dashboardUrl = () => `${appUrl()}`;
export const resetPasswordUrl = () => `${appUrl()}reset-password/`;
export const resetUserPasswordUrl = (email: string, token: string) =>
  `${resetPasswordUrl()}${email}/${token}`;
export const userPaymentListUrl = () => `${appUrl()}payments/`;
export const userPaymentDetailsUrl = (paymentId: string) =>
  `${appUrl()}payment/${paymentId}/`;

// organization routes
export const apiSpecUrl = (organizationId: string) =>
  `${organizationUrl(organizationId)}docs`;
export const organizationUrl = (organizationId: string) =>
  `${organizationListUrl()}${organizationId}/`;
export const paymentListUrl = (organizationId: string) =>
  `${organizationUrl(organizationId)}payment/`;

export const userOrganizationPaymentListUrl = (organizationId: string) =>
  `${organizationUrl(organizationId)}payments/`;

export const organizationDashboardUrl = (organizationId: string) =>
  `${organizationUrl(organizationId)}`;

export const bankListUrl = (organizationId: string) =>
  `${organizationUrl(organizationId)}bank/`;

export const bankCheckListUrl = (organizationId: string, bankId: string) =>
  `${bankListUrl(organizationId)}${bankId}/check`;

export const userPaymentOrganizationDetailsUrl = (
  organizationId: string,
  paymentId: string,
) => `${organizationUrl(organizationId)}payments/${paymentId}/`;

export const payeeListUrl = (organizationId: string) =>
  `${organizationUrl(organizationId)}payee/`;

export const userOrganizationPayeeListUrl = (organizationId: string) =>
  `${organizationUrl(organizationId)}payees/`;

export const memberListUrl = (organizationId: string) =>
  `${organizationUrl(organizationId)}members/`;

export const userActivityListUrl = (organizationId: string) =>
  `${organizationUrl(organizationId)}activity/`;

export const settingsUrl = (organizationId: string) =>
  `${organizationUrl(organizationId)}settings/`;

export const userProfileUrl = (organizationId?: string | undefined) =>
  organizationId
    ? `${organizationUrl(organizationId)}user/profile/`
    : `${appUrl()}user/profile/`;
