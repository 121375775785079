import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isToday from 'dayjs/plugin/isToday';
import isTomorrow from 'dayjs/plugin/isTomorrow';
import isYesterday from 'dayjs/plugin/isYesterday';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import i18n from 'i18n';

export { i18n };

export {
  default as styled,
  ThemeProvider as StyledThemeProvider,
  createGlobalStyle,
  css,
  keyframes,
  useTheme,
} from 'styled-components';
export { default as cssResetStyles } from 'styled-reset';
export { CreatableProps } from 'react-select/creatable';
export { default as classNames } from 'classnames';
export { useBottomScrollListener } from 'react-bottom-scroll-listener';
export {
  Route,
  Routes,
  useParams,
  Outlet as RouterOutlet,
  useLocation,
  useNavigation,
  Navigate,
  useMatch,
  useResolvedPath,
  matchPath,
  createRoutesFromChildren,
  matchRoutes,
  useNavigationType,
  resolvePath,
  useSearchParams,
} from 'react-router-dom';

export {
  combineReducers,
  legacy_createStore as createStore,
  applyMiddleware,
  compose,
  bindActionCreators,
} from 'redux';
export { Provider } from 'react-redux';
export { useSelector, useDispatch } from 'react-redux';
export { v4 as uuid } from 'uuid';
export { useTranslation } from 'react-i18next';
export {
  useForm,
  useFieldArray,
  useFormContext,
  Controller,
  FormProvider,
} from 'react-hook-form';
export type {
  FieldError,
  Control,
  UseFormSetValue,
  UseFieldArrayProps,
  FieldPath,
} from 'react-hook-form';

export { useCallbackRef } from 'use-callback-ref';

export {
  default as Select,
  components as reactSelectComponents,
} from 'react-select';
export { default as AsyncSelect } from 'react-select/async';
export { default as AsyncCreatableSelect } from 'react-select/async-creatable';
export { AsyncPaginate, wrapMenuList } from 'react-select-async-paginate';
export { withAsyncPaginate } from 'react-select-async-paginate';

dayjs.extend(isTomorrow);
dayjs.extend(isYesterday);
dayjs.extend(isToday);
dayjs.extend(isSameOrBefore);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(customParseFormat);
export { dayjs };
export { createTheme } from '@mui/material';
export { IMaskInput, IMask } from 'react-imask';
export { useNavigate } from 'react-router';
export { default as _ } from 'lodash';

export {
  SnackbarProvider,
  VariantType,
  useSnackbar,
  SnackbarKey,
} from 'notistack';

export { default as copyToClipboard } from 'copy-to-clipboard';

export { default as printJS } from 'print-js';
export { default as Dropzone } from 'react-dropzone';
export { default as AvatarEditor } from 'react-avatar-editor';
export { Template, cloneDeep, getInputFromTemplate } from '@pdfme/common';
export { Designer } from '@pdfme/ui';

export { default as Signature } from '@uiw/react-signature';
