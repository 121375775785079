import React from 'react';

import { Box, List, Typography } from '@mui/material';
import { NavigationLink } from 'components';
import { useIsMobile } from 'hooks';

import { MenuType } from 'types/app';

type Props = {
  menu: MenuType;
  isCollapsed: boolean;
};

export const NavigationMenu: React.FC<Props> = ({ menu, isCollapsed }) => {
  const isMobile = useIsMobile();

  return (
    <Box margin={isMobile ? '0rem 0.5rem' : '0rem 1rem 0rem 1rem'}>
      <List
        subheader={
          <Typography
            variant="subtitle1"
            marginLeft={isMobile ? '1.5rem' : '2.5rem'}
            marginBottom="0.3rem"
            textAlign="left"
            maxWidth="25rem"
          >
            {!isCollapsed && menu.subHeader}
          </Typography>
        }
      >
        {menu.items.map((it, index) => (
          <NavigationLink key={index} item={it} isCollapsed={isCollapsed} />
        ))}
      </List>
    </Box>
  );
};
