import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';

import {
  ClearOutlined,
  Code as CodeIcon,
  RestartAltOutlined,
  SaveOutlined,
} from '@mui/icons-material';
import { Box, IconButton, Slider, Tooltip, Typography } from '@mui/material';
import Signature, {
  type SignatureRef,
  type StrokeOptions,
  defaultOptions,
} from '@uiw/react-signature';

import { styled, useTranslation } from 'third-party';

import { TOOLTIP_APPEAR_DELAY } from 'constants/common';

const Wrapper = styled.div`
  border-radius: 4px;
  flex: 1;
`;

type Props = {
  defaultSignature: string;
  onSave?: (formData: FormData | null) => void;
};

export interface SignatureMethods {
  getSignature: () => void;
}

export const SignaturePad = React.forwardRef<SignatureMethods, Props>(
  ({ defaultSignature, onSave }, ref) => {
    const [options, setOptions] = useState<StrokeOptions>(defaultOptions);

    const $svg = useRef<SignatureRef>(null);
    const defaultImageRef = useRef<SVGImageElement | null>(null);

    const { t } = useTranslation();

    useEffect(() => {
      if ($svg?.current?.svg && defaultSignature) {
        const img = document.createElementNS(
          'http://www.w3.org/2000/svg',
          'image',
        );
        img.setAttributeNS(null, 'href', defaultSignature);
        img.setAttributeNS(null, 'x', '0');
        img.setAttributeNS(null, 'y', '0');
        img.setAttributeNS(null, 'width', '270');
        img.setAttributeNS(null, 'height', '160');
        $svg.current.svg.appendChild(img);
        defaultImageRef.current = img;
      }
    }, [$svg, defaultSignature]);

    const resetOption = () => setOptions(defaultOptions);
    const handleClear = () => {
      $svg.current?.clear();

      // Remove the default signature image if it exists
      if (defaultImageRef.current && $svg.current?.svg) {
        try {
          $svg.current.svg.removeChild(defaultImageRef.current);
          defaultImageRef.current = null;
        } catch (error) {
          console.error('Failed to remove default signature image:', error);
        }
      }
    };

    const handleSVGCopy = () => {
      const svgelm = $svg.current?.svg?.cloneNode(true) as SVGSVGElement;
      const clientWidth = $svg.current?.svg?.clientWidth;
      const clientHeight = $svg.current?.svg?.clientHeight;
      svgelm.removeAttribute('style');
      svgelm.setAttribute('width', `${clientWidth}px`);
      svgelm.setAttribute('height', `${clientHeight}px`);
      svgelm.setAttribute('viewbox', `${clientWidth} ${clientHeight}`);
    };

    const getSignature = () => {
      return new Promise<FormData | null>(resolve => {
        const svgelm = $svg.current?.svg?.cloneNode(true) as SVGSVGElement;
        if (!svgelm || !$svg.current?.svg) {
          resolve(null);
          return;
        }

        const clientWidth = $svg.current.svg.clientWidth;
        const clientHeight = $svg.current.svg.clientHeight;
        svgelm.removeAttribute('style');
        svgelm.setAttribute('width', `${clientWidth}px`);
        svgelm.setAttribute('height', `${clientHeight}px`);
        svgelm.setAttribute('viewbox', `${clientWidth} ${clientHeight}`);

        const data = new XMLSerializer().serializeToString(svgelm);
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        if (!ctx) {
          resolve(null);
          return;
        }

        const img = new Image();
        img.onload = () => {
          canvas.width = clientWidth || 0;
          canvas.height = clientHeight || 0;
          ctx.drawImage(img, 0, 0);
          canvas.toBlob(blob => {
            if (!blob) {
              console.error('Failed to create blob from canvas');
              resolve(null);
              return;
            }

            const formData = new FormData();
            formData.append('signature', blob, 'signature.png');
            resolve(formData);
          }, 'image/png');
        };

        img.src = `data:image/svg+xml;base64,${window.btoa(
          unescape(encodeURIComponent(data)),
        )}`;
      });
    };

    useImperativeHandle(ref, () => ({
      getSignature,
    }));

    const handleSave = async () => {
      if (!$svg.current) return;

      try {
        const formData = await getSignature();
        onSave?.(formData);
      } catch {
        onSave?.(null);
      }
    };

    return (
      <Wrapper>
        <Signature
          style={{
            width: 270,
            height: 160,
            borderRadius: 5,
          }}
          ref={$svg}
          options={options}
        />

        <Box>
          <Box pt={1}>
            <Typography variant="body2">
              {t('signaturePad.size')}: {options.size}
            </Typography>
            <Slider
              value={options.size}
              max={50}
              min={1}
              onChange={(_, value) => {
                setOptions({
                  ...options,
                  size: value as number,
                });
              }}
              valueLabelDisplay="auto"
            />
          </Box>
          <Box>
            <Typography variant="body2">
              {t('signaturePad.smoothing')}: {options.smoothing}
            </Typography>
            <Slider
              value={options.smoothing}
              max={0.99}
              min={-0.99}
              step={0.01}
              onChange={(_, value) => {
                setOptions({
                  ...options,
                  smoothing: value as number,
                });
              }}
              valueLabelDisplay="auto"
            />
          </Box>
          <Box>
            <Typography variant="body2">
              {t('signaturePad.thinning')}: {options.thinning}
            </Typography>

            <Slider
              value={options.thinning}
              max={0.99}
              min={-0.99}
              step={0.01}
              onChange={(_, value) => {
                setOptions({
                  ...options,
                  thinning: value as number,
                });
              }}
              valueLabelDisplay="auto"
            />
          </Box>
          <Box>
            <Typography variant="body2">
              {t('signaturePad.streamline')}: {options.streamline}
            </Typography>
            <Slider
              value={options.streamline}
              max={0.99}
              min={0.01}
              step={0.01}
              onChange={(_, value) => {
                setOptions({
                  ...options,
                  streamline: value as number,
                });
              }}
              valueLabelDisplay="auto"
            />
          </Box>
        </Box>

        <Box display="flex" justifyContent="space-between">
          <Box>
            <Tooltip
              placement="top-start"
              enterDelay={TOOLTIP_APPEAR_DELAY}
              enterNextDelay={TOOLTIP_APPEAR_DELAY}
              title={t('signaturePad.clear')}
            >
              <IconButton onClick={handleClear}>
                <ClearOutlined fontSize="medium" />
              </IconButton>
            </Tooltip>
            <Tooltip
              placement="top-start"
              enterDelay={TOOLTIP_APPEAR_DELAY}
              enterNextDelay={TOOLTIP_APPEAR_DELAY}
              title={t('signaturePad.resetSettings')}
            >
              <IconButton onClick={resetOption}>
                <RestartAltOutlined fontSize="medium" />
              </IconButton>
            </Tooltip>
          </Box>

          <Box>
            <Tooltip
              placement="top-start"
              enterDelay={TOOLTIP_APPEAR_DELAY}
              enterNextDelay={TOOLTIP_APPEAR_DELAY}
              title={t('avatar.open')}
            >
              <IconButton onClick={handleSVGCopy}>
                <CodeIcon fontSize="medium" color="primary" />
              </IconButton>
            </Tooltip>

            {handleSave && (
              <Tooltip
                placement="top-start"
                enterDelay={TOOLTIP_APPEAR_DELAY}
                enterNextDelay={TOOLTIP_APPEAR_DELAY}
                title={t('signaturePad.save')}
              >
                <IconButton onClick={handleSave}>
                  <SaveOutlined fontSize="medium" color="primary" />
                </IconButton>
              </Tooltip>
            )}
          </Box>
        </Box>
      </Wrapper>
    );
  },
);
