import React, { useCallback, useEffect } from 'react';

import {
  Box,
  Button,
  FormField,
  FormNumberField,
  LoadingButton,
  PageHeader,
  TypeaheadFormField,
  Typography,
} from 'components';
import { useNotifications } from 'hooks';

import { FieldError, useForm, useSelector, useTranslation } from 'third-party';

import { useCache } from 'modules/cache';
import { usePayment } from 'modules/payment';

import { paymentSelectors } from 'selectors';

import {
  APIGenericList,
  APIPayee,
  APIPaymentUserRequest,
  APIResponse,
} from 'types/api';
import { TypeaheadOption } from 'types/app';

enum FormFields {
  BankId = 'bankId',
  PayeeId = 'payeeId',
  Amount = 'amount',
  OrganizationId = 'organizationId',
}

type FormData = {
  [FormFields.BankId]: {
    label: string | null | undefined;
    value: string | undefined;
  } | null;
  [FormFields.PayeeId]: {
    label: string | null | undefined;
    value: string | undefined;
  } | null;
  [FormFields.Amount]: string;
  [FormFields.OrganizationId]: string;
};

type CreateEditProps = {
  onModalClose: () => void;
};

export const PaymentRequestForm: React.FC<CreateEditProps> = ({
  onModalClose,
}) => {
  const { clearPayment } = usePayment();

  useEffect(() => {
    return () => {
      clearPayment();
    };
  });

  return <RequestForm onModalClose={onModalClose} />;
};

type Props = {
  onModalClose: () => void;
};

export const RequestForm: React.FC<Props> = ({ onModalClose }) => {
  const { error, success } = useNotifications();
  const { requestPayment } = usePayment();
  const { loadBankTypeahead, loadPayeeTypeahead, organizationTypeahead } =
    useCache();
  const { t } = useTranslation();
  const createPaymentPending = useSelector(
    paymentSelectors.createPaymentPending,
  );
  const isFormPending = useSelector(paymentSelectors.requestPaymentPending);
  const disabledEdit = isFormPending;

  const {
    handleSubmit,
    control,
    setValue,
    watch,
    formState: { errors },
  } = useForm<FormData>({
    mode: 'onChange',
  });

  const organizationId = watch(FormFields.OrganizationId);

  const handleCancel = () => {
    onModalClose();
  };

  const onSubmit = async (formData: FormData) => {
    const result = await requestPayment(formData['organizationId'], {
      bankId: formData['bankId']?.value || '',
      payeeId: formData['payeeId']?.value || '',
      amount: formData['amount'],
    } as unknown as APIPaymentUserRequest);
    if (result.error) {
      error(result.payload.body?.title || t('paymentRequestForm.edit.error'));
    } else {
      success(t('paymentRequestForm.edit.sucscefull'));
      onModalClose();
    }
  };

  const loadBank = async (value: string) => {
    const result: APIResponse<{ name: string; id: string }[]> =
      await loadBankTypeahead(organizationId, value);
    let options: TypeaheadOption[] = [];
    if (!result.error) {
      options = result.payload.map(it => ({
        label: it.name,
        value: it.id,
      }));
    }
    return {
      options,
    };
  };

  const loadPayees = async (value: string) => {
    const result: APIResponse<APIGenericList<APIPayee>> =
      await loadPayeeTypeahead(organizationId, value, { userSearch: true });

    let options: TypeaheadOption[] = [];
    if (!result.error) {
      options = result.payload?.result.map(it => {
        const data = [it.city, it.state, it.email].filter(Boolean).join(', ');

        return {
          label: `${it.nameOnCheck} (${data})`,
          value: it.id,
          ...it,
        };
      });
    }

    return {
      options,
    };
  };

  const onBankChange = useCallback(
    (option: TypeaheadOption | null) => {
      setValue(
        FormFields.BankId,
        option || { label: undefined, value: undefined },
        {
          shouldValidate: true,
          shouldDirty: true,
        },
      );
    },
    [setValue],
  );

  const onPayeeChange = useCallback(
    (option: APIPayee | null) => {
      setValue(FormFields.PayeeId, option as any, {
        shouldValidate: true,
        shouldDirty: true,
      });
    },
    [setValue],
  );

  const loadOrganization = async (value: string) => {
    const result: APIResponse<{ name: string; id: string }[]> =
      await organizationTypeahead(value);
    let options: TypeaheadOption[] = [];
    if (!result.error) {
      options = result.payload.map(it => ({
        label: it.name,
        value: it.id,
      }));
    }
    return {
      options,
    };
  };

  const onOrganizationChange = useCallback(
    (option: TypeaheadOption | null) => {
      setValue(FormFields.OrganizationId, option?.value || '', {
        shouldValidate: true,
        shouldDirty: true,
      });
    },
    [setValue],
  );

  return (
    <Box padding="1.5rem">
      <form>
        <PageHeader
          header={t('paymentRequestForm.create.header')}
          subHeader={t('paymentRequestForm.create.subHeader')}
        >
          <Box display="flex" columnGap="1rem">
            <LoadingButton
              type="submit"
              variant="outlined"
              loading={createPaymentPending}
              onClick={handleSubmit(onSubmit)}
              size="medium"
              color="primary"
            >
              {t('common.form.request')}
            </LoadingButton>

            <Button
              type="button"
              disabled={createPaymentPending}
              variant="outlined"
              onClick={handleCancel}
              size="medium"
              color="error"
            >
              {t('common.form.cancel')}
            </Button>
          </Box>
        </PageHeader>
        <FormField
          fieldName={FormFields.OrganizationId}
          isDisabled={!organizationId}
          label={t('payeeList.column.organization')}
          fieldError={errors[FormFields.OrganizationId] as FieldError}
          requiredErrorMessage={t('common.form.emptyFieldError')}
          control={control}
          render={() => (
            <TypeaheadFormField
              searchIcon
              placeholder={
                t('common.form.typeToSearchValueFor') +
                t('payeeList.column.organization')
              }
              loadOptions={loadOrganization}
              onChange={onOrganizationChange}
              isDisabled={isFormPending}
            />
          )}
        />
        {!organizationId && (
          <Typography
            variant="body2"
            sx={{
              color: 'text.secondary',
              display: '-webkit-box',
              fontSize: '0.7rem',
              overflow: 'hidden',
              WebkitBoxOrient: 'vertical',
              WebkitLineClamp: 2,
            }}
          >
            {t('paymentRequestForm.helpTex')}
          </Typography>
        )}
        <FormField
          fieldName={FormFields.BankId}
          label={t('paymentList.column.bank')}
          fieldError={errors[FormFields.BankId] as FieldError}
          requiredErrorMessage={t('common.form.emptyFieldError')}
          control={control}
          render={() => (
            <TypeaheadFormField
              searchIcon
              placeholder={
                t('common.form.typeToSearchValueFor') +
                t('paymentList.column.bank')
              }
              loadOptions={loadBank}
              onChange={onBankChange}
              isDisabled={disabledEdit || !organizationId}
            />
          )}
        />
        <FormField
          isDisabled={!organizationId}
          fieldName={FormFields.PayeeId}
          label={t('paymentList.column.payee')}
          fieldError={errors[FormFields.PayeeId] as FieldError}
          requiredErrorMessage={t('common.form.emptyFieldError')}
          control={control}
          isRequired={false}
          render={() => (
            <TypeaheadFormField
              searchIcon
              placeholder={
                t('common.form.typeToSearchValueFor') +
                t('paymentList.column.payee')
              }
              loadOptions={loadPayees}
              onChange={onPayeeChange}
              isDisabled={disabledEdit || !organizationId}
            />
          )}
        />
        <FormNumberField
          fieldName={FormFields.Amount}
          precision={2}
          fieldError={errors[FormFields.Amount] as FieldError}
          label={t('paymentList.column.amount')}
          placeholder={
            t('common.form.enterValueFor') + t('paymentList.column.amount')
          }
          requiredErrorMessage={t('common.form.fieldRequired')}
          control={control}
          maxLength={10}
          isRequired
          isDisabled={disabledEdit}
        />
      </form>
    </Box>
  );
};
