import React, {
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import styled, { css } from 'styled-components';

import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import GetAppOutlinedIcon from '@mui/icons-material/GetAppOutlined';

import { tooltipClasses } from '@mui/material';
import { TooltipProps } from '@mui/material/Tooltip/Tooltip';
import { styled as muiStyled } from '@mui/material/styles';
import {
  StyledComponentBase, // @ts-ignore
} from '@types/styled-components';
import {
  Box,
  Button,
  MUITable,
  Menu,
  PageHeader,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableSortLabelMUI,
  Tooltip,
} from 'components';
import {
  CircularProgress,
  LoadingButton,
  TableRow,
  Typography,
} from 'components';

import { StringFilter } from './Filtering/StringFilter';
import { getOptionColumn } from './TableMenu';
import { TableSettings } from './TableSettings';
import { TableColumn } from './types';

import {
  _,
  classNames,
  useBottomScrollListener,
  useCallbackRef,
  useTranslation,
} from 'third-party';

import {
  INFINITE_SCROLL_BOTTOM_OFFSET_PX,
  INFINITE_SCROLL_DEBOUNCE_TIME_MS,
  TOOLTIP_APPEAR_DELAY,
} from 'constants/common';

import { isTitleString } from 'utils/helper';

import { APISortOrder } from 'types/api';
import { TableSettingsT } from 'types/app';

const COLUMN_RESIZER_WIDTH = 20;
const SCROLL_WIDTH = 30;
const SPAN_COLUMN_ID = 'span-column';

const fixedStyles = {
  position: 'sticky',
  left: 0,
  backgroundColor: 'white',
  zIndex: 3,
};

const emptyTableSortHeaderStyles = {
  textDecoration: 'none',
  pointerEvents: 'none',
};

export const TableSortLabel = styled(TableSortLabelMUI)`
  font-weight: 400;
  font-size: 0.875rem !important;
  line-height: 1.75;
  text-decoration: underline;
  text-transform: uppercase;

  &.Mui-active,
  &:hover {
    color: ${props => props.theme.custom.palette.secondary900};
    .MuiTableSortLabel-icon {
      opacity: 1;
    }
  }
  color: ${props => props.theme.custom.palette.primary900};
  .MuiTableSortLabel-icon {
    fill: ${props => props.theme.custom.palette.secondary900};
    stroke: ${props => props.theme.custom.palette.secondary900};
  }
`;

const CellTooltip = muiStyled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    whiteSpace: 'pre-line',
  },
}));

const TableWrapper = styled.div<{ minheight: string }>`
  position: relative;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  min-height: ${props => props.minheight || '0'};
`;

const StyledTableContainer = styled(TableContainer)`
  flex-grow: 1;
`;

const ProgressWrapper = styled.div<{ headerheight: string }>`
  position: absolute;
  background-color: rgba(255, 255, 255, 0.5);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 5;
  padding-top: ${props => props.headerheight};
`;

export const StyledTable = styled(MUITable)`
  table-layout: fixed;
`;

export const StyledTableBodyCell = styled(TableCell)<{ height: string }>`
  height: ${props => props.height};
  padding: 0 1rem;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 0;
  box-sizing: border-box;
`;

const StyledTableBody = styled(TableBody)`
  flex-grow: 1;
  overflow-y: auto;
`;

const StyledTableHeaderCell = styled(TableCell)<{ headerheight: string }>`
  height: ${props => props.headerheight};
  padding: 0 1.6rem;
  border-bottom: 1px solid ${props => props.theme.custom.palette.borderColor};
  border-top: 1px solid ${props => props.theme.custom.palette.borderColor};

  white-space: nowrap;
  box-sizing: border-box;
  z-index: 3;
`;

export const StyledTableColumnLabel = styled(Typography)`
  text-transform: uppercase;
  color: ${props => props.theme.custom.palette.primary900};
`;

const StyledTableRow = styled(TableRow)<{ rowhighlightcolor: string }>`
  border: 1px solid ${props => props.theme.custom.palette.borderColor};
  padding: 0;
  height: 1rem;
  background-color: ${props => props.rowhighlightcolor || 'white'};
  &:not(.MuiTableRow-head) {
    &.active,
    &:hover {
      cursor: pointer;
    }

    &.active {
      background-color: ${props => props.theme.custom.palette.primary50};
    }

    &:hover {
      background-color: ${props => props.theme.custom.palette.primary50};
    }
  }
`;

const StyledTableSection = styled(TableCell)<{ top: string; height: string }>`
  border: 1px solid #d4d4d4;
  padding: 0 1.6rem;
  background-color: ${props => props.theme.custom.palette.secondary50};
  position: sticky;
  height: 4rem;
  top: ${props => props.top};
  z-index: 4;
`;

const TableBodySectionRow = styled(TableRow)``;

const StyledTableSectionText = styled(Typography)`
  color: ${props => props.theme.custom.palette.secondary900};
`;

const StyledTableBodyCellInner = styled(Typography)`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const EmptyResultRow = styled(TableRow)`
  padding: 1rem 0;
`;

const EmptyResultCell = styled(TableCell)`
  font-size: 1.6rem;
  font-weight: 600;
  color: ${props => props.theme.custom.palette.muted800};
  border: none;
  text-align: center;
`;

const LoadMoreRow = styled(TableRow)`
  padding: 1rem 0;
`;

const LoadMoreTableCell = styled(TableCell)`
  border-bottom: none;
  text-align: center;
`;

const Resizer = styled.div`
  width: 2px;
  cursor: col-resize;

  display: block;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
  border-right: 2px solid transparent;
  user-select: none;
  &:hover {
    border-color: #ccc;
  }

  &.active {
    border-color: #517ea5;
    width: 2px;
  }
`;

const StyledLoadMoreButton = styled(LoadingButton)``;

type TableComponents =
  | 'Table'
  | 'TableHead'
  | 'TableBodyCell'
  | 'TableBody'
  | 'TableHeaderCell'
  | 'TableSortLabel'
  | 'TableColumnLabel'
  | 'TableHeaderRow'
  | 'TableBodyRow'
  | 'TableBodySection'
  | 'TableBodyCellInner'
  | 'LoadMoreButton';

export type SectionOption<ItemT> = {
  shouldRender: (currentItem: ItemT, previousItem: ItemT) => boolean;
  render: (
    currentItem: ItemT,
  ) => React.ReactElement | React.ReactElement[] | null | string | undefined;
};

export type SectionOptions<ItemT> = Record<string, SectionOption<ItemT>>;

export interface TableProps<ItemT> {
  className?: string;
  filterable?: boolean;
  header?: string;
  subHeader?: string;
  columns: TableColumn<ItemT>[];
  columnsWidth?: Record<string, number>;
  items: ItemT[];
  allItemsLoaded: boolean;
  itemsLoaded: boolean;
  loadItemsPending: boolean;
  loadMoreItemsPending?: boolean;
  infiniteScroll?: boolean;
  infiniteScrollOffset?: number;
  sortField?: keyof ItemT;
  sortOrder?: APISortOrder;
  rowOptionsMenu?: (
    item: ItemT,
    onRowMenuClose: () => void,
  ) => React.ReactElement[];
  isExportPending?: boolean;
  onExportClick?: () => void;
  onLoadMoreClicked?: () => void;
  onNewItemButtonClicked?: () => void;
  onColumnHeaderClicked?: (columnDataId: keyof ItemT) => void;
  onRowClick?: (item: ItemT) => void;
  onBodyRowMouseEnter?: (item: ItemT) => void;
  onBodyRowMouseLeave?: (item: ItemT) => void;
  onColumnFilterChange?: (columnId: string, value: string) => void;
  onFilterReset?: () => void;
  onColumnVisibilityChanged?: (columnId: string, value: boolean) => void;
  components?: {
    [key in TableComponents]?: StyledComponentBase<any, any>;
  };
  tableMinHeight?: string;
  hideHeaders?: boolean;
  headerRowHeight?: string;
  bodyRowHeight?: string;
  isRowActive?: (item: ItemT) => boolean;
  rowHighlightColor?: (item: ItemT) => boolean;
  onColumnSizeChanged?: (
    columnId: string,
    width: string,
    tableColumns: (TableColumn<ItemT> & { ref: RefObject<HTMLElement> })[],
  ) => void;
  emptyText?: string;
  loadMoreButtonText?: string;
  hiddenColumns?: TableSettingsT['hiddenColumns'];
  autoAdjustColumnsWidth?: boolean;
  sectionOptions?: SectionOptions<ItemT>;
  highlightSearchResults?: boolean;
  onScrollEnd?: (scrollPosition: number) => void;
  scrollPosition?: number;
}

export const LastColumnCellStyles = css`
  &:before {
    display: none;
  }
`;

const lastColumn: TableColumn<any> = {
  dataId: SPAN_COLUMN_ID,
  label: '',
  width: '',
  hidden: false,
  order: 1,
  align: 'right',
  sortable: false,
  // @ts-ignore
  cellStyles: LastColumnCellStyles,
};

const getColumnWidth = <T,>(
  column: Pick<TableColumn<any>, 'dataId' | 'minWidth' | 'width'>,
  columnsWidth: TableProps<T>['columnsWidth'],
) => {
  return (
    columnsWidth?.[column.dataId.toString()] || column.width || column.minWidth
  );
};

export const Table = <
  ItemT extends {
    id: number | string;
    highlights?: Record<string, string[] | null> | undefined;
  },
>({
  className,
  filterable = false,
  header,
  subHeader,
  columns,
  items,
  allItemsLoaded,
  itemsLoaded,
  loadItemsPending,
  loadMoreItemsPending,
  sortField,
  sortOrder,
  isExportPending = false,
  rowOptionsMenu,
  onExportClick,
  onLoadMoreClicked,
  onNewItemButtonClicked,
  onColumnFilterChange,
  onFilterReset,
  onColumnVisibilityChanged,
  onColumnHeaderClicked,
  onRowClick,
  onBodyRowMouseEnter,
  onBodyRowMouseLeave,
  components = {},
  hideHeaders,
  tableMinHeight = '0',
  headerRowHeight = '6.6rem',
  bodyRowHeight = '5.7rem',
  isRowActive,
  rowHighlightColor,
  emptyText,
  loadMoreButtonText,
  infiniteScroll = false,
  hiddenColumns = [],
  onColumnSizeChanged,
  infiniteScrollOffset = INFINITE_SCROLL_BOTTOM_OFFSET_PX,
  autoAdjustColumnsWidth = false,
  sectionOptions,
  highlightSearchResults,
  onScrollEnd,
  scrollPosition,
  columnsWidth,
}: TableProps<ItemT>) => {
  const TableComponent: React.ElementType = components.Table || StyledTable;
  const TableHeaderRowComponent: React.ElementType =
    components.TableHeaderRow || StyledTableRow;
  const TableBodyRowComponent: React.ElementType =
    components.TableBodyRow || StyledTableRow;
  const TableBodySectionComponent: React.ElementType =
    components.TableBodySection || StyledTableSection;
  const TableHeadComponent: React.ElementType =
    components.TableHead || TableHead;
  const TableBodyComponent: React.ElementType =
    components.TableBody || StyledTableBody;
  const TableHeaderCellComponent: React.ElementType =
    components.TableHeaderCell || StyledTableHeaderCell;
  const TableSortLabelComponent: React.ElementType =
    components.TableSortLabel || TableSortLabel;
  const TableColumnLabelComponent: React.ElementType =
    components.TableColumnLabel || StyledTableColumnLabel;
  const TableBodyCellComponent: React.ElementType =
    components.TableBodyCell || StyledTableBodyCell;
  const TableBodyCellInnerComponent: React.ElementType =
    components.TableBodyCellInner || StyledTableBodyCellInner;
  const LoadMoreButtonComponent: React.ElementType =
    components.LoadMoreButton || StyledLoadMoreButton;

  const { t } = useTranslation();

  const onRowMenuClicked = useCallback(
    (event: React.MouseEvent<HTMLElement>, item: ItemT) => {
      setOpenedMenuOrder(item);
      setAnchorEl(event.currentTarget);
    },
    [],
  );

  const onRowMenuClose = useCallback(() => {
    setOpenedMenuOrder(null);
    setAnchorEl(null);
  }, []);

  const tableColumns = useMemo(() => {
    const initArray = rowOptionsMenu
      ? [
          {
            ...getOptionColumn(onRowMenuClicked),
            ref: React.createRef<HTMLDivElement>(),
          },
        ]
      : [];

    return [
      ...initArray,
      ...columns.map(it => ({
        ...it,
        ref: React.createRef<HTMLDivElement>(),
      })),
    ];
  }, [columns, onRowMenuClicked, rowOptionsMenu]);

  const [tableWidth, setTableWidth] = useState<null | number>(null);
  const [tableHeight, setTableHeight] = useState<null | number>(null);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [openedMenu, setOpenedMenuOrder] = React.useState<null | ItemT>(null);

  const tableRef = useCallbackRef<HTMLDivElement | null>(null, tableEl => {
    if (tableEl) {
      setTableWidth(tableEl.getBoundingClientRect().width);
    }
  });

  const isTableEmpty = items?.length === 0;

  const showEmptyText =
    items?.length === 0 && itemsLoaded && !loadItemsPending && emptyText;

  const hasLoadMoreButton = onLoadMoreClicked && itemsLoaded && !allItemsLoaded;

  // creating ref for always having the actual
  // value inside `onScrollToBottom` callback,
  const hasLoadMoreButtonRef = useRef(hasLoadMoreButton);
  hasLoadMoreButtonRef.current = hasLoadMoreButton;

  const [activeColumn, setActiveColumn] = useState<string | null>(null);
  const isLoadingMoreRef = useRef<typeof loadMoreItemsPending>(false);

  const onScrollToBottom = () => {
    if (hasLoadMoreButtonRef.current && !isLoadingMoreRef.current) {
      onLoadMoreClicked?.();
      // using ref to instantly update this value and
      // avoid bugs with the late update when using similar redux value
      isLoadingMoreRef.current = true;
    }
  };

  const onScrollToBottomDebounced = _.throttle(
    onScrollToBottom,
    INFINITE_SCROLL_DEBOUNCE_TIME_MS,
  );

  useEffect(() => {
    // sync the local ref value with the latest redux`loadMoreItemsPending`
    // when it's finally updated
    isLoadingMoreRef.current = loadMoreItemsPending;
  }, [loadMoreItemsPending]);

  useEffect(() => {
    if (tableRef.current) {
      setTableHeight(tableRef.current.getBoundingClientRect().height);
    }
  }, [items.length, tableRef]);

  const scrollRef = useBottomScrollListener<HTMLDivElement>(
    onScrollToBottomDebounced,
    {
      offset: infiniteScrollOffset,
      triggerOnNoScroll: false,
    },
  );

  useEffect(() => {
    if (scrollRef) {
      scrollRef.current?.scrollTo({ top: scrollPosition });
    }
    // only on initial load
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (scrollPosition === 0) {
      scrollRef.current?.scrollTo({ top: 0 });
    }
    // react only when resetting to 0
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollPosition]);

  useEffect(() => {
    let onScrollStopDebounced = _.noop;
    if (onScrollEnd && scrollRef?.current) {
      onScrollStopDebounced = _.debounce(event => {
        onScrollEnd(event.target.scrollTop);
      }, 500);
      scrollRef.current?.addEventListener?.('scroll', onScrollStopDebounced);
    }
    return () =>
      scrollRef &&
      // eslint-disable-next-line react-hooks/exhaustive-deps
      scrollRef?.current?.removeEventListener?.(
        'scroll',
        onScrollStopDebounced,
      );
  }, [scrollRef, onScrollEnd]);

  const visibleColumns = useMemo(() => {
    return hiddenColumns?.length
      ? tableColumns.reduce<typeof tableColumns>((result, it) => {
          return hiddenColumns.includes(it.dataId.toString())
            ? result
            : [...result, it];
        }, [])
      : tableColumns;
  }, [tableColumns, hiddenColumns]);

  const fullWidthColspan = useMemo(() => {
    const isTableResizable = _.some(columns, { resizable: true });
    // resizable table has one extra column for smooth resize
    return (
      _.reject(visibleColumns, { hidden: true }).length +
      (isTableResizable ? 1 : 0)
    );
  }, [columns, visibleColumns]);

  const onColumnMouseDown = (index: string | null) => {
    setActiveColumn(index);
  };

  const onResizeMouseMove = useCallback(
    (event: MouseEvent) => {
      tableColumns.forEach(it => {
        if (it.dataId.toString() == activeColumn && it.ref.current) {
          const width =
            event.clientX - it.ref.current.getBoundingClientRect().left;

          it.ref.current.style.width =
            Math.max(
              it.minWidth || 0,
              width < COLUMN_RESIZER_WIDTH ? COLUMN_RESIZER_WIDTH : width,
            ) + 'px';
        }
      });
    },
    [activeColumn, tableColumns],
  );

  const sortedColumns = useMemo(() => {
    const columns = _.orderBy(visibleColumns, 'order');
    const isTableResizable = _.some(columns, { resizable: true });

    // trick: if all width are set, then browser will autosize columns
    // therefore we add one empty column without width, this will make resize smooth
    // and size other columns will not be affected
    return isTableResizable
      ? [...columns, { ...lastColumn, ref: React.createRef<HTMLDivElement>() }]
      : columns;
  }, [visibleColumns]);

  const clearListeners = useCallback(() => {
    window.removeEventListener('mousemove', onResizeMouseMove);
    window.removeEventListener('mouseup', clearListeners);
  }, [onResizeMouseMove]);

  const onResizeMouseUp = useCallback(() => {
    if (activeColumn) {
      const foundColumn = tableColumns.find(
        it => it.dataId.toString() === activeColumn,
      );
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      foundColumn?.ref?.current &&
        onColumnSizeChanged?.(
          activeColumn,
          foundColumn.ref.current.style.width,
          tableColumns,
        );
    }
    setActiveColumn(null);
    clearListeners();
  }, [activeColumn, clearListeners, tableColumns, onColumnSizeChanged]);

  useEffect(() => {
    if (autoAdjustColumnsWidth && tableWidth) {
      const totalColumnsWidth = visibleColumns.reduce((result, column) => {
        return result + Number(getColumnWidth(column, columnsWidth));
      }, 0);
      if (totalColumnsWidth < tableWidth) {
        _.forEach(visibleColumns, column => {
          if (column.ref.current) {
            column.ref.current.style.width =
              Math.max(
                column.minWidth || 0,
                (Number(getColumnWidth(column, columnsWidth)) *
                  (tableWidth - SCROLL_WIDTH)) /
                  totalColumnsWidth,
              ) + 'px';
          }
        });
      }
    }
  }, [tableWidth, autoAdjustColumnsWidth, visibleColumns, columnsWidth]);

  useEffect(() => {
    if (activeColumn !== null) {
      window.addEventListener('mousemove', onResizeMouseMove);
      window.addEventListener('mouseup', onResizeMouseUp);
    }

    return () => {
      clearListeners();
    };
  }, [activeColumn, onResizeMouseMove, onResizeMouseUp, clearListeners]);

  const [isFilteringOn, setIsFilteringOn] = useState(false);

  return (
    <>
      <PageHeader header={header} subHeader={subHeader}>
        <Box minHeight="3.4rem">
          {filterable && (
            <>
              {isFilteringOn &&
                onFilterReset &&
                columns.some(it => it.filter) && (
                  <Button variant="text" onClick={onFilterReset}>
                    <ClearIcon />
                  </Button>
                )}
              {onNewItemButtonClicked && (
                <Button variant="text" onClick={onNewItemButtonClicked}>
                  <AddIcon />
                </Button>
              )}
              <Button
                variant="text"
                onClick={() => setIsFilteringOn(it => !it)}
              >
                {isFilteringOn ? <FilterAltIcon /> : <FilterAltOffIcon />}
              </Button>
            </>
          )}
          {onExportClick && (
            <LoadingButton loading={isExportPending} variant="text" onClick={onExportClick}>
              <GetAppOutlinedIcon />
            </LoadingButton>
          )}

          {onColumnVisibilityChanged && (
            <TableSettings
              columns={tableColumns}
              onColumnVisibilityChanged={onColumnVisibilityChanged}
            />
          )}
        </Box>
      </PageHeader>

      <TableWrapper className={className} minheight={tableMinHeight}>
        <StyledTableContainer ref={infiniteScroll ? scrollRef : null}>
          <TableComponent stickyHeader aria-label="sticky table" ref={tableRef}>
            <TableHeadComponent>
              <TableHeaderRowComponent>
                {sortedColumns.map(column =>
                  column.hidden ? null : (
                    <TableHeaderCellComponent
                      key={column.dataId.toString()}
                      ref={column.ref}
                      align={column.align}
                      style={{
                        padding: '0.5rem 1rem 0.5rem 1rem',
                        width: getColumnWidth(column, columnsWidth),
                        ...(column.fixed && {
                          ...fixedStyles,
                          top: 0,
                          zIndex: 4,
                        }),
                      }}
                      headerheight={hideHeaders ? '0' : headerRowHeight}
                    >
                      {hideHeaders ? null : onColumnHeaderClicked &&
                        column.sortable !== false ? (
                        <TableSortLabelComponent
                          hideSortIcon={!column.dataId || isTableEmpty}
                          active={
                            !isTableEmpty &&
                            itemsLoaded &&
                            sortField === column.dataId
                          }
                          style={
                            isTableEmpty
                              ? emptyTableSortHeaderStyles
                              : { userSelect: 'none' }
                          }
                          direction={sortOrder?.toLowerCase()}
                          onClick={
                            !isTableEmpty && column.dataId
                              ? () =>
                                  onColumnHeaderClicked?.(
                                    column.dataId as keyof ItemT,
                                  )
                              : undefined
                          }
                        >
                          {
                            // @ts-ignore
                            t(column.label) || ''
                          }
                        </TableSortLabelComponent>
                      ) : (
                        <TableColumnLabelComponent variant="h3">
                          {
                            // @ts-ignore
                            t(column.label) || ''
                          }
                        </TableColumnLabelComponent>
                      )}
                      {isFilteringOn && column.dataId != SPAN_COLUMN_ID && (
                        <Box height="1.3rem">
                          {!column.notFilterable &&
                            (column.customFilter ? (
                              <column.customFilter
                                value={column.filter}
                                columnId={column.dataId}
                                onFilterChange={onColumnFilterChange}
                              />
                            ) : (
                              <StringFilter
                                columnId={column.dataId}
                                value={column.filter}
                                onFilterChange={onColumnFilterChange}
                              />
                            ))}
                        </Box>
                      )}
                      <Resizer
                        style={{
                          display: column.resizable ? 'block' : 'none',
                          height: items.length ? tableHeight || 0 : '100%',
                        }}
                        onMouseDown={() =>
                          onColumnMouseDown(column.dataId.toString())
                        }
                        className={
                          activeColumn === column.dataId.toString()
                            ? 'active'
                            : 'idle'
                        }
                      />
                    </TableHeaderCellComponent>
                  ),
                )}
              </TableHeaderRowComponent>
            </TableHeadComponent>
            <TableBodyComponent>
              {items.map((item, index) => {
                const section = sectionOptions?.[(sortField || '').toString()];
                const shouldRenderSection = section?.shouldRender(
                  item,
                  items?.[index - 1],
                );
                const sectionElement = section?.render?.(item);

                return [
                  ...[
                    shouldRenderSection
                      ? [
                          <TableBodySectionRow key={`section-${item.id}`}>
                            <TableBodySectionComponent
                              height={bodyRowHeight}
                              top={hideHeaders ? '0' : headerRowHeight}
                              colSpan={fullWidthColspan}
                            >
                              {isTitleString(sectionElement) ? (
                                <StyledTableSectionText>
                                  {sectionElement}
                                </StyledTableSectionText>
                              ) : (
                                sectionElement
                              )}
                            </TableBodySectionComponent>
                          </TableBodySectionRow>,
                        ]
                      : [],
                  ],
                  <TableBodyRowComponent
                    key={item.id}
                    className={classNames({
                      active: isRowActive?.(item),
                    })}
                    onClick={() => onRowClick?.(item)}
                    onMouseEnter={() => onBodyRowMouseEnter?.(item)}
                    onMouseLeave={() => onBodyRowMouseLeave?.(item)}
                    item={item}
                    rowhighlightcolor={rowHighlightColor?.(item)}
                  >
                    {sortedColumns.map(column => {
                      if (column.hidden) {
                        return null;
                      }
                      const rawValue = column.dataId
                        ? _.get(item, column.dataId)
                        : '';
                      // @ts-ignore TODO: find out the right constraint for `anything with .toString() method`
                      const { value = rawValue?.toString(), element = null } =
                        column.formatter?.(
                          rawValue,
                          item,
                          highlightSearchResults
                            ? item.highlights?.[
                                column.highlightKey || (column.dataId as string)
                              ]
                            : undefined,
                        ) || { value: rawValue };

                      return (
                        <TableBodyCellComponent
                          key={column.dataId.toString() + item.id}
                          align={column.align}
                          height={bodyRowHeight}
                          cellstyles={column.cellStyles}
                          style={{
                            padding: '0.7rem 1rem 0.7rem 1rem',
                            ...(column.fixed && {
                              ...fixedStyles,
                              backgroundColor: 'inherit',
                            }),
                          }}
                        >
                          <CellTooltip
                            placement="top-start"
                            enterDelay={TOOLTIP_APPEAR_DELAY}
                            enterNextDelay={TOOLTIP_APPEAR_DELAY}
                            title={value}
                          >
                            {element || (
                              <TableBodyCellInnerComponent variant="p">
                                {value}
                              </TableBodyCellInnerComponent>
                            )}
                          </CellTooltip>
                        </TableBodyCellComponent>
                      );
                    })}
                  </TableBodyRowComponent>,
                ];
              })}
              {showEmptyText && (
                <EmptyResultRow>
                  <EmptyResultCell colSpan={fullWidthColspan}>
                    {emptyText}
                  </EmptyResultCell>
                </EmptyResultRow>
              )}
              {hasLoadMoreButton && (
                <LoadMoreRow>
                  <LoadMoreTableCell colSpan={fullWidthColspan}>
                    <LoadMoreButtonComponent
                      loading={loadMoreItemsPending}
                      disabled={loadMoreItemsPending}
                      onClick={onLoadMoreClicked}
                    >
                      {loadMoreButtonText}
                    </LoadMoreButtonComponent>
                  </LoadMoreTableCell>
                </LoadMoreRow>
              )}
            </TableBodyComponent>
          </TableComponent>
        </StyledTableContainer>
        {loadItemsPending && (
          <ProgressWrapper headerheight={headerRowHeight}>
            <CircularProgress color="primary" />
          </ProgressWrapper>
        )}
        {openedMenu && (
          <Menu
            id={`long-menu-for-}`}
            anchorEl={anchorEl}
            open={!!openedMenu}
            onClose={onRowMenuClose}
          >
            {rowOptionsMenu?.(openedMenu, onRowMenuClose)}
          </Menu>
        )}
      </TableWrapper>
    </>
  );
};
