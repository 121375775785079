import React, { useRef, useState } from 'react';

import VerifiedUserOutlinedIcon from '@mui/icons-material/VerifiedUserOutlined';

import { Box, Button, PageHeader, TextField } from 'components';

import { useNavigate, useTranslation } from 'third-party';

import { verifyCheckWithIdUrl } from 'constants/url';

export const VerifyCheck = () => {
  const { t } = useTranslation();
  const ref = useRef<HTMLInputElement>();
  const [error, setError] = useState(false);

  const navigate = useNavigate();

  const handleCodeVerification = () => {
    if (ref.current && ref.current.value) {
      navigate(verifyCheckWithIdUrl(ref.current.value));
    }
    if (ref.current && ref.current.value === '') {
      setError(true);
    }
  };

  return (
    <>
      <Box display="flex" flexDirection="column" alignItems="center">
        <>
          <PageHeader
            textAlignment="center"
            header={t('verifyCheck.header')}
            subHeader={t('verifyCheck.subHeader')}
          />
          <Box
            pt="1rem"
            display="flex"
            gap="1rem"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            <TextField
              inputRef={ref}
              error={error}
              id="outlined-basic"
              label={t('verifyCheck.code')}
              variant="outlined"
              autoComplete="off"
              sx={{ width: '13rem' }}
            />
            <Button
              variant="outlined"
              size="medium"
              color="primary"
              onClick={handleCodeVerification}
            >
              <VerifiedUserOutlinedIcon />
              {t('verifyCheck.verify')}
            </Button>
          </Box>
        </>
      </Box>
    </>
  );
};
