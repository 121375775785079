import React from 'react';

import { HelpOutlineOutlined } from '@mui/icons-material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {
  Box,
  IconButton,
  ModalBox,
  PageHeader,
  Tooltip,
  Typography,
} from 'components';

import { useTranslation } from 'third-party';

export const DesignHelper = () => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = React.useState(false);

  const onModalClose = () => {
    setIsModalOpen(false);
  };

  const rows = [
    {
      keyword: '{{user_name}}',
      description: t('checkDesigner.help.keywords.user_name'),
    },
    {
      keyword: '{{amount}}',
      description: t('checkDesigner.help.keywords.amount'),
    },
    {
      keyword: '{{link}}',
      description: t('checkDesigner.help.keywords.link'),
    },
    {
      keyword: '{{verify}}',
      description: t('checkDesigner.help.keywords.verify'),
    },
  ];

  return (
    <>
      <Tooltip title={t('emailDesigner.actions.help')}>
        <IconButton onClick={() => setIsModalOpen(true)}>
          <HelpOutlineOutlined fontSize="small" />
        </IconButton>
      </Tooltip>
      {isModalOpen && (
        <ModalBox width="30rem" height="30rem" onClose={onModalClose}>
          <Box padding="1rem">
            <PageHeader
              header={t('emailDesigner.help.header')}
              subHeader={t('emailDesigner.help.subHeader')}
            />

            <Typography variant="h3" paddingTop="1rem">
              {t('checkDesigner.help.description')}
            </Typography>
            <TableContainer component={Paper} sx={{ marginTop: '1rem' }}>
              <Table sx={{ minWidth: '15rem' }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      {t('checkDesigner.help.keywords.title')}
                    </TableCell>
                    <TableCell align="right">
                      {t('checkDesigner.help.keywords.description')}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map(row => (
                    <TableRow
                      key={row.keyword}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        <Typography variant="h4" color="primary">
                          ${row.keyword}
                        </Typography>
                      </TableCell>
                      <TableCell align="right">{row.description}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </ModalBox>
      )}
    </>
  );
};
