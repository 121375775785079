import React from 'react';

import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  Grid2,
  Typography,
} from 'components';
import { useTranslation } from 'react-i18next';

import { dayjs } from 'third-party';

import { moneyFormatter } from 'utils/app';

import { APIPayment } from 'types/api';

type CheckItemProps = {
  title: string;
  value: string | null;
};
const CheckItem: React.FC<CheckItemProps> = ({ title, value }) => {
  return (
    <>
      <Typography variant="h3" gutterBottom>
        {title}
      </Typography>
      <Typography variant="h4" gutterBottom>
        {value}
      </Typography>
    </>
  );
};

type Props = {
  payment: APIPayment;
};

export const PaymentInfo: React.FC<Props> = ({ payment }) => {
  const { t } = useTranslation();
  return (
    <Box maxWidth="60rem">
      <Card sx={{ margin: '1rem' }}>
        <CardActionArea
          data-active={true}
          sx={{
            '&[data-active]': {
              backgroundColor: 'action.selected',
              '&:hover': {
                backgroundColor: 'action.selectedHover',
              },
            },
          }}
        >
          <CardContent sx={{ height: '5rem' }}>
            <Typography variant="h1" marginBottom="1rem" color="text.primary">
              {moneyFormatter(payment.amount)}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {payment.bank.bankName} {payment.company}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {payment.bank.routing} {payment.bank.account}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>

      <Grid2 container spacing={2} margin="1rem">
        <Grid2 size={6}>
          <CheckItem
            title={t('paymentList.column.checkNumber')}
            value={payment.checkNumber}
          />
        </Grid2>

        <Grid2 size={6}>
          <CheckItem
            title={t('paymentList.column.checkDate')}
            value={
              payment.checkDate
                ? dayjs(payment.checkDate).format('MM/DD/YYYY')
                : null
            }
          />
        </Grid2>

        <Grid2 size={6}>
          <CheckItem
            title={t('paymentList.column.nameOnCheck')}
            value={payment.payee?.nameOnCheck ?? ''}
          />
        </Grid2>

        <Grid2 size={6}>
          <CheckItem
            title={t('paymentList.column.referenceId')}
            value={payment.referenceId}
          />
        </Grid2>

        <Grid2 size={6}>
          <CheckItem
            title={t('paymentList.column.email')}
            value={payment.payee?.email ?? ''}
          />
        </Grid2>

        <Grid2 size={6}>
          <CheckItem
            title={t('paymentList.column.phone')}
            value={payment.payee?.phone ?? ''}
          />
        </Grid2>

        <Grid2 size={6}>
          <CheckItem
            title={t('paymentList.column.address')}
            value={payment.payee?.address ?? ''}
          />
        </Grid2>

        <Grid2 size={6}>
          <CheckItem
            title={t('paymentList.column.city')}
            value={payment.payee?.city ?? ''}
          />
        </Grid2>

        <Grid2 size={6}>
          <CheckItem
            title={t('paymentList.column.status')}
            value={payment.status}
          />
        </Grid2>

        <Grid2 size={6}>
          <CheckItem
            title={t('paymentList.column.note')}
            value={payment.note}
          />
        </Grid2>
      </Grid2>
    </Box>
  );
};
