import { API_PATH, CDN_PATH } from './url';

import { APISettingName, PrintType } from 'types/api';

export const apiSpecEndpoint = () => `${API_PATH}docs`;
export const cdnEndpoint = () => `${CDN_PATH}`;

// anonymous APIs
export const userEndpoint = () => `${API_PATH}user/`;
export const userAuthEndpoint = () => `${userEndpoint()}auth`;
export const userPasswordResetLinkEndpoint = (email: string) =>
  `${userEndpoint()}password-reset-link?email=${email}`;
export const validateUserResetTokenPasswordEndpoint = (
  email: string,
  token: string,
) => `${userEndpoint()}validate-reset-token?token=${token}&email=${email}`;
export const userResetPasswordEndpoint = () =>
  `${userEndpoint()}reset-password`;
export const userRefreshTokenEndpoint = () => `${userEndpoint()}token`;

export const anonymousPaymentEndpoint = (paymentId: string) =>
  `${API_PATH}payment/${paymentId}/`;

export const userPaymentDetailsEndpoint = (paymentId: string) =>
  `${anonymousPaymentEndpoint(paymentId)}details`;

export const anonymousPrintPaymentEndpoint = (id: string, type: PrintType) =>
  `${anonymousPaymentEndpoint(id)}download/${type}/`;

export const finishPrintingEndpoint = (id: string) =>
  `${anonymousPaymentEndpoint(id)}printed/`;

export const verifyCheckEndpoint = (id: string) =>
  `${API_PATH}payment/verify/${id}/`;

export const userInfoEndpoint = () => `${userEndpoint()}me`;

// user APIs
export const userAvatarEndpoint = () => `${userEndpoint()}avatar/`;
export const userPayeeEndpoint = () => `${API_PATH}payee/`;
export const payeeEditEndpoint = (payeeId: string) =>
  `${userPayeeEndpoint()}${payeeId}`;
export const userPaymentEndpoint = () => `${API_PATH}payment`;

export const requestPaymentEndpoint = (organizationId: string) =>
  `${userPaymentEndpoint()}/${organizationId}/request`;

// organization APIs
export const baseEndpoint = (organizationId: string) =>
  `${organizationEndpoint()}${organizationId}/`;
export const organizationEndpoint = () => `${API_PATH}organization/`;
export const organizationSingleEndpoint = baseEndpoint;

export const payeeEndpoint = (organizationId: string) =>
  `${baseEndpoint(organizationId)}payee/`;

export const payeesExport = (organizationId: string) =>
  `${payeeEndpoint(organizationId)}export/`;

export const payeeSingleEndpoint = (organizationId: string, payeeId: string) =>
  `${payeeEndpoint(organizationId)}${payeeId}/`;

export const paymentSingleEndpoint = (
  organizationId: string,
  paymentId: string,
) => `${baseEndpoint(organizationId)}payment/${paymentId}/`;

export const paymentDownloadEndpoint = (
  organizationId: string,
  paymentId: string,
) => `${paymentSingleEndpoint(organizationId, paymentId)}download/`;

export const paymentResendEndpoint = (
  organizationId: string,
  paymentId: string,
) => `${paymentSingleEndpoint(organizationId, paymentId)}resend/`;

export const paymentsExport = (organizationId: string) =>
  `${baseEndpoint(organizationId)}payment/export/`;

export const paymentEndpoint = (organizationId: string) =>
  `${baseEndpoint(organizationId)}payment`;

export const bankEndpoint = (organizationId: string) =>
  `${baseEndpoint(organizationId)}bank/`;

export const organizationTypeaheadEndpoint = () =>
  `${API_PATH}organization/typeahead`;

export const userTypeaheadEndpoint = (organizationId: string) =>
  `${baseEndpoint(organizationId)}user/typeahead`;

export const payeeTypeaheadEndpoint = (organizationId: string) =>
  `${baseEndpoint(organizationId)}payee/typeahead`;

export const userListEndpoint = (organizationId: string) =>
  `${baseEndpoint(organizationId)}user/`;

export const userSingleEndpoint = (organizationId: string, userId: string) =>
  `${userListEndpoint(organizationId)}/${userId}/`;

export const bankSingleEndpoint = (organizationId: string, bankId: string) =>
  `${bankEndpoint(organizationId)}${bankId}/`;

export const bankCheckTemplateEndpoint = (
  organizationId: string,
  bankId: string,
  type: PrintType,
) => `${bankSingleEndpoint(organizationId, bankId)}template/${type}/`;

export const bankTypeaheadEndpoint = (organizationId: string) =>
  `${bankEndpoint(organizationId)}typeahead/`;

// setting
export const settingEndpoint = (organizationId: string) =>
  `${baseEndpoint(organizationId)}setting/`;

export const settingSingleEndpoint = (
  organizationId: string,
  name: APISettingName,
) => `${settingEndpoint(organizationId)}${name}`;
