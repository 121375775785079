import React from 'react';

import { FilterAltOff, InboxOutlined } from '@mui/icons-material';
import { Box, Button, Paper, Typography } from 'components';

import { useTranslation } from 'third-party';

type Props = {
  showResetFilterButton?: boolean;
  onFilterReset?: () => void;
};

export const EmptyState: React.FC<Props> = ({
  showResetFilterButton,
  onFilterReset,
}) => {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: 'calc(100vh - 200px)',
        minHeight: '400px',
      }}
    >
      <Paper
        elevation={0}
        sx={{
          maxWidth: '600px',
          width: '100%',
          py: 6,
          px: 3,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: 'background.default',
          border: 'none',
          borderRadius: '8px',
        }}
      >
        <InboxOutlined
          color="primary"
          sx={{ fontSize: '4rem', mb: 2, opacity: 0.9 }}
        />
        <Typography variant="h2" color="primary" gutterBottom>
          {t('common.emptyState')}
        </Typography>
        <Typography variant="body1" color="text.secondary" align="center">
          {t('common.emptyState.description')}
        </Typography>

        {showResetFilterButton && onFilterReset && (
          <Button
            variant="outlined"
            onClick={onFilterReset}
            startIcon={<FilterAltOff />}
            sx={{ mt: 3 }}
          >
            {t('common.emptyState.clearFilters')}
          </Button>
        )}
      </Paper>
    </Box>
  );
};
