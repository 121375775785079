/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export enum APIAccessLevel {
  User = 'User',
  SuperVisor = 'SuperVisor',
  Admin = 'Admin',
  ReadOnly = 'ReadOnly',
}

/** Represents banking information for payment processing */
export interface APIBankModel {
  /** @format uuid */
  id: string;
  /**
   * The bank routing number used for payment transactions
   * @minLength 1
   * @maxLength 22
   * @example "021000021"
   */
  routing: string;
  /**
   * The bank account number
   * @minLength 1
   * @maxLength 22
   * @example "123456789"
   */
  account: string;
  /**
   * The name associated with the bank account
   * @minLength 1
   * @maxLength 100
   * @example "Checking Account"
   */
  accountName: string;
  /**
   * The name of the financial institution
   * @minLength 1
   * @maxLength 100
   * @example "First National Bank"
   */
  bankName: string;
  /**
   * The name of the company that owns the bank account
   * @minLength 1
   * @maxLength 100
   * @example "Acme Corporation"
   */
  companyName: string;
  /**
   * The street address associated with the bank account
   * @minLength 1
   * @maxLength 255
   * @example "123 Main Street"
   */
  address: string;
  /**
   * The city associated with the bank account address
   * @minLength 1
   * @maxLength 100
   * @example "Boston"
   */
  city: string;
  /**
   * The state or province associated with the bank account address
   * @maxLength 2
   * @example "MA"
   */
  state?: string | null;
  /**
   * The postal code associated with the bank account address
   * @minLength 1
   * @maxLength 20
   * @example "02101"
   */
  zip: string;
  /**
   * The date and time when this bank record was created
   * @format date-time
   * @example "2023-01-15T14:30:00Z"
   */
  createdOn?: string;
}

export interface APIBankModelSearchResult {
  /**
   * The search page number, will be used for offset
   * @format int32
   * @example 0
   */
  pageNumber?: number;
  /**
   * The count of entities on the page
   * @format int32
   * @example 20
   */
  pageSize?: number;
  /** The list of the searched entities */
  result?: APIBankModel[] | null;
}

export interface APIBankRequestModel {
  /**
   * The bank routing number used for payment transactions
   * @minLength 1
   * @maxLength 22
   * @example "021000021"
   */
  routing: string;
  /**
   * The bank account number associated with the payment
   * @minLength 1
   * @maxLength 22
   * @example "12345678901234"
   */
  account: string;
  /**
   * The name of the account holder as it appears on the bank account
   * @minLength 1
   * @maxLength 100
   * @example "John Doe"
   */
  accountName: string;
  /**
   * The name of the financial institution where the account is held
   * @minLength 1
   * @maxLength 100
   * @example "Chase Bank"
   */
  bankName: string;
  /**
   * The name of the company associated with the bank account
   * @minLength 1
   * @maxLength 100
   * @example "Acme Corporation"
   */
  companyName: string;
  /**
   * The street address associated with the bank account or company
   * @minLength 1
   * @maxLength 255
   * @example "123 Main Street"
   */
  address: string;
  /**
   * The city of the address associated with the bank account or company
   * @minLength 1
   * @maxLength 100
   * @example "New York"
   */
  city: string;
  /**
   * The state of the address associated with the bank account or company
   * @minLength 1
   * @maxLength 2
   * @example "NY"
   */
  state: string;
  /**
   * The ZIP code of the address associated with the bank account or company
   * @minLength 1
   * @maxLength 20
   * @example "10001"
   */
  zip: string;
}

/** Filter for bank search */
export interface APIBankSearchFilter {
  /**
   * The search page number, will be used for offset
   * @format int32
   * @example 0
   */
  pageNumber?: number;
  /**
   * The count of entities on the page
   * @format int32
   * @example 20
   */
  pageSize?: number;
  /** a collection of sort fields */
  searchSortOrders?: APISearchSortOrder[] | null;
  /** The search parameter that will be used to search contains criteria the id */
  id?: string | null;
  /** The search parameter that will be used to search contains criteria the routing number */
  routing?: string | null;
  /** The search parameter that will be used to search contains criteria the account number */
  account?: string | null;
  /** The search parameter that will be used to search contains criteria the account name */
  accountName?: string | null;
  /** The search parameter that will be used to search contains criteria the bank name */
  bankName?: string | null;
  /** The search parameter that will be used to search contains criteria the company name */
  companyName?: string | null;
  /** The search parameter that will be used to search contains criteria the address */
  address?: string | null;
  /** The search parameter that will be used to search contains criteria the city */
  city?: string | null;
  /** The search parameter that will be used to search contains criteria the state */
  state?: string | null;
  /** The search parameter that will be used to search contains criteria the zip */
  zip?: string | null;
}

export interface APIBodyRange {
  /** @format double */
  start?: number;
  /** @format double */
  end?: number | null;
}

export interface APICheckTemplateModel {
  basePdf?: string | null;
  schemas?: APISchema[][] | null;
  pdfmeVersion?: string | null;
}

export interface APIGuidTypeaheadModel {
  /**
   * The item id
   * @format uuid
   */
  id?: string;
  /**
   * The name of an item
   * @example "TestName"
   */
  name?: string | null;
}

export interface APIGuidTypeaheadRequestModel {
  /** The value pattern to search */
  query?: string | null;
  /**
   * The number of result items to return
   * @format int32
   * @example 5
   */
  count?: number;
  /**
   * Ids to exclude from search result
   * @example [1,2,3]
   */
  excludedIds?: string[] | null;
  /**
   * Ids to include in search result regardless of other criteria
   * @example [4,5,6]
   */
  includedIds?: string[] | null;
  /**
   * The organization ids to filter the search results by
   * @example [101,102]
   */
  organizationIds?: string[] | null;
}

/** Represents an organization within the system */
export interface APIOrganizationModel {
  /** @format uuid */
  id: string;
  /**
   * The name of the organization
   * @minLength 1
   * @maxLength 150
   * @example "Acme Corporation"
   */
  name: string;
  /**
   * A brief description of the organization and its purpose
   * @maxLength 255
   * @example "A leading provider of innovative solutions"
   */
  description?: string | null;
  /**
   * The unique identifier or filename of the organization's avatar image
   * @maxLength 40
   * @example "org_logo_123456.png"
   */
  avatar?: string | null;
}

export interface APIOrganizationModelSearchResult {
  /**
   * The search page number, will be used for offset
   * @format int32
   * @example 0
   */
  pageNumber?: number;
  /**
   * The count of entities on the page
   * @format int32
   * @example 20
   */
  pageSize?: number;
  /** The list of the searched entities */
  result?: APIOrganizationModel[] | null;
}

/** Represents an organization within the system */
export interface APIOrganizationRequestModel {
  /** @format uuid */
  id: string;
  /**
   * The name of the organization
   * @minLength 1
   * @maxLength 150
   * @example "Acme Corporation"
   */
  name: string;
  /**
   * A brief description of the organization and its purpose
   * @maxLength 255
   * @example "A leading provider of innovative solutions"
   */
  description?: string | null;
  /**
   * /// The avatar image file for the organization
   * ///
   * @format binary
   */
  file?: File | null;
}

/** Filter for user search */
export interface APIOrganizationSearchFilter {
  /**
   * The search page number, will be used for offset
   * @format int32
   * @example 0
   */
  pageNumber?: number;
  /**
   * The count of entities on the page
   * @format int32
   * @example 20
   */
  pageSize?: number;
  /** a collection of sort fields */
  searchSortOrders?: APISearchSortOrder[] | null;
  /** The search parameter that will be used to search contains criteria the organization ids */
  organizationIds?: string[] | null;
  /**
   * The search parameter that will be used to search contains criteria the id
   * @format uuid
   */
  id?: string | null;
  /** The search parameter that will be used to search contains criteria the name */
  name?: string | null;
  /** The search parameter that will be used to search contains criteria the welcome text */
  description?: string | null;
}

/** Represents a payee entity for check payments */
export interface APIPayeeModel {
  /** @format uuid */
  id: string;
  /** Represents a user in the EasyECheck system with their personal information and organizational relationships */
  user?: APIUserModel;
  /**
   * The name that will appear on the check
   * @example "John Smith"
   */
  nameOnCheck?: string | null;
  /**
   * The email address for contacting the payee
   * @example "john.smith@example.com"
   */
  email?: string | null;
  /**
   * The phone number for contacting the payee
   * @example "(555) 123-4567"
   */
  phone?: string | null;
  /**
   * The street address of the payee
   * @example "123 Main Street, Apt 4B"
   */
  address?: string | null;
  /**
   * The city of the payee's address
   * @example "New York"
   */
  city?: string | null;
  /**
   * The state or province of the payee's address
   * @example "NY"
   */
  state?: string | null;
  /**
   * The postal code of the payee's address
   * @example "10001"
   */
  zip?: string | null;
  status?: APIPayeeStatus;
  /**
   * Indicates whether this payee is currently disabled
   * @example false
   */
  isDisabled?: boolean;
  /** Represents an organization within the system */
  organization?: APIOrganizationModel;
  /**
   * The timestamp when this payee record was created
   * @format date-time
   * @example "2025-03-13T10:30:00Z"
   */
  createdOn?: string;
}

export interface APIPayeeModelSearchResult {
  /**
   * The search page number, will be used for offset
   * @format int32
   * @example 0
   */
  pageNumber?: number;
  /**
   * The count of entities on the page
   * @format int32
   * @example 20
   */
  pageSize?: number;
  /** The list of the searched entities */
  result?: APIPayeeModel[] | null;
}

/** Model for payee information when making payment requests */
export interface APIPayeeRequestModel {
  /**
   * The unique identifier of the user associated with this payee
   * @format uuid
   * @example "3fa85f64-5717-4562-b3fc-2c963f66afa6"
   */
  userId?: string;
  status?: APIPayeeStatus;
  /**
   * The name that will appear on the check
   * @example "John Doe"
   */
  nameOnCheck?: string | null;
  /**
   * The email address of the payee
   * @example "john.doe@example.com"
   */
  email?: string | null;
  /**
   * The contact phone number of the payee
   * @example "+1 (555) 123-4567"
   */
  phone?: string | null;
  /**
   * The street address of the payee
   * @example "123 Main Street, Apt 4B"
   */
  address?: string | null;
  /**
   * The city of the payee's address
   * @example "New York"
   */
  city?: string | null;
  /**
   * The state or province of the payee's address
   * @example "NY"
   */
  state?: string | null;
  /**
   * The postal code of the payee's address
   * @example "10001"
   */
  zip?: string | null;
}

/** Filter for bank search */
export interface APIPayeeSearchFilter {
  /**
   * The search page number, will be used for offset
   * @format int32
   * @example 0
   */
  pageNumber?: number;
  /**
   * The count of entities on the page
   * @format int32
   * @example 20
   */
  pageSize?: number;
  /** a collection of sort fields */
  searchSortOrders?: APISearchSortOrder[] | null;
  /** The search parameter that will be used to search contains criteria the name on check */
  nameOnCheck?: string | null;
  /** The search parameter that will be used to search contains criteria the email */
  email?: string | null;
  /** The search parameter that will be used to search contains criteria the phone */
  phone?: string | null;
  /** The search parameter that will be used to search contains criteria the address */
  address?: string | null;
  /** The search parameter that will be used to search contains criteria the city */
  city?: string | null;
  /** The search parameter that will be used to search contains criteria the state */
  state?: string | null;
  /** The search parameter that will be used to search contains criteria the zip */
  zip?: string | null;
  /** The search parameter that will be used to search contains criteria the organization ids */
  organizationIds?: string[] | null;
}

export enum APIPayeeStatus {
  NotActive = 'NotActive',
  Deleted = 'Deleted',
  Archive = 'Archive',
  Active = 'Active',
  Rejected = 'Rejected',
  PendingForReview = 'PendingForReview',
}

/** Represents a payment transaction in the system */
export interface APIPaymentModel {
  /** @format uuid */
  id: string;
  /** Represents banking information for payment processing */
  bank: APIBankModel;
  /**
   * The monetary value of the payment
   * @format double
   * @example 1250.75
   */
  amount: number;
  /**
   * The date when the check was issued
   * @format date-time
   * @example "2023-10-15"
   */
  checkDate?: string;
  /**
   * The unique number assigned to the check
   * @maxLength 100
   * @example "CH-10045678"
   */
  checkNumber?: string | null;
  /**
   * Additional information or comments about the payment
   * @maxLength 255
   * @example "Payment for Q3 services"
   */
  note?: string | null;
  /**
   * Brief description or purpose of the payment
   * @maxLength 255
   * @example "October invoice"
   */
  memo?: string | null;
  /**
   * Name of the payment recipient or sender
   * @maxLength 100
   * @example "John Doe"
   */
  name?: string | null;
  /**
   * Company name associated with the payment
   * @maxLength 100
   * @example "Acme Corp"
   */
  company?: string | null;
  /**
   * Verification code used to validate the payment
   * @maxLength 100
   * @example "VC-987654"
   */
  verifyCode?: string | null;
  /**
   * External reference identifier for the payment
   * @maxLength 100
   * @example "REF-2023-10-15-001"
   */
  referenceId?: string | null;
  /** Represents a payee entity for check payments */
  payee: APIPayeeModel;
  /**
   * Indicates whether this is an electronic check payment
   * @example true
   */
  eCheck?: boolean;
  status: APIPaymentStatus;
  /** Represents an organization within the system */
  organization: APIOrganizationModel;
  /**
   * Key identifier used for payment tracking
   * @maxLength 100
   * @example "K-12345"
   */
  kId?: string | null;
  /**
   * Identifier of the user who created this payment
   * @format uuid
   * @example "3fa85f64-5717-4562-b3fc-2c963f66afa6"
   */
  createdById: string;
  /**
   * Date and time when the payment was created
   * @format date-time
   * @example "2023-10-15T14:30:00Z"
   */
  createdOn: string;
  /**
   * Identifier of the user who last updated this payment
   * @format uuid
   * @example "4fa85f64-5717-4562-b3fc-2c963f66afb7"
   */
  updatedById?: string | null;
  /**
   * Date and time when the payment was last updated
   * @format date-time
   * @example "2023-10-16T09:45:00Z"
   */
  updatedOn?: string | null;
}

export interface APIPaymentModelSearchResult {
  /**
   * The search page number, will be used for offset
   * @format int32
   * @example 0
   */
  pageNumber?: number;
  /**
   * The count of entities on the page
   * @format int32
   * @example 20
   */
  pageSize?: number;
  /** The list of the searched entities */
  result?: APIPaymentModel[] | null;
}

export interface APIPaymentRequestModel {
  /**
   * The payment amount
   * @format double
   * @example 100.5
   */
  amount: number;
  /**
   * The date associated with the check
   * @format date-time
   * @example "2023-05-15"
   */
  checkDate: string;
  /**
   * The check number identifier
   * @maxLength 100
   * @example "CH1234567"
   */
  checkNumber?: string | null;
  /**
   * Additional notes for the payment
   * @maxLength 255
   * @example "Payment for services rendered in May"
   */
  note?: string | null;
  /**
   * Memo line for the payment
   * @maxLength 255
   * @example "Invoice #INV-2023-05"
   */
  memo?: string | null;
  /**
   * Name of the payment recipient
   * @maxLength 100
   * @example "John Doe"
   */
  name?: string | null;
  /**
   * Company name associated with the payment
   * @maxLength 100
   * @example "Acme Corporation"
   */
  company?: string | null;
  /**
   * Verification code for the payment
   * @maxLength 100
   * @example "VC12345"
   */
  verifyCode?: string | null;
  /**
   * External reference ID for the payment
   * @maxLength 100
   * @example "REF-2023-1234"
   */
  referenceId?: string | null;
  /**
   * Indicates if payment is processed as an electronic check
   * @example true
   */
  eCheck?: boolean;
  /**
   * Unique identifier for the bank
   * @format uuid
   * @example "3fa85f64-5717-4562-b3fc-2c963f66afa6"
   */
  bankId: string;
  /**
   * Key identifier for payment tracking
   * @maxLength 100
   * @example "K123456"
   */
  kId?: string | null;
  status?: APIPaymentStatus;
  /**
   * Unique identifier for the payee
   * @format uuid
   * @example "3fa85f64-5717-4562-b3fc-2c963f66afa6"
   */
  payeeId?: string | null;
  /**
   * Name as it should appear on the check
   * @maxLength 100
   * @example "John A. Doe"
   */
  nameOnCheck?: string | null;
  /**
   * Email address of the payee
   * @format email
   * @maxLength 100
   * @example "john.doe@example.com"
   */
  email?: string | null;
  /**
   * Phone number of the payee
   * @format tel
   * @maxLength 20
   * @example "555-123-4567"
   */
  phone?: string | null;
  /**
   * Street address of the payee
   * @maxLength 200
   * @example "123 Main Street, Apt 4B"
   */
  address?: string | null;
  /**
   * City of the payee's address
   * @maxLength 100
   * @example "Springfield"
   */
  city?: string | null;
  /**
   * State of the payee's address
   * @maxLength 50
   * @example "IL"
   */
  state?: string | null;
  /**
   * ZIP code of the payee's address
   * @maxLength 20
   * @example "62701"
   */
  zip?: string | null;
}

/** Filter for bank search */
export interface APIPaymentSearchFilter {
  /**
   * The search page number, will be used for offset
   * @format int32
   * @example 0
   */
  pageNumber?: number;
  /**
   * The count of entities on the page
   * @format int32
   * @example 20
   */
  pageSize?: number;
  /** a collection of sort fields */
  searchSortOrders?: APISearchSortOrder[] | null;
  /** The search parameter that will be used to search contains criteria the organization ids */
  organizationIds?: string[] | null;
  /** The search parameter that will be used to search contains criteria the id */
  id?: string | null;
  /**
   * The search parameter that will be used to search contains criteria the bank id
   * @format double
   */
  amount?: number | null;
  /** The search parameter that will be used to search contains criteria the check date */
  checkNumber?: string | null;
  /** The search parameter that will be used to search contains criteria the check number */
  note?: string | null;
  /** The search parameter that will be used to search contains criteria the memo */
  memo?: string | null;
  /** The search parameter that will be used to search contains criteria the name */
  name?: string | null;
  /** The search parameter that will be used to search contains criteria the company */
  company?: string | null;
  /** The search parameter that will be used to search contains criteria the name on check */
  nameOnCheck?: string | null;
  /** The search parameter that will be used to search contains criteria the reference id */
  referenceId?: string | null;
  /** The search parameter that will be used to search contains criteria the email */
  email?: string | null;
  /** The search parameter that will be used to search contains criteria the phone */
  phone?: string | null;
  /** The search parameter that will be used to search contains criteria the address */
  address?: string | null;
  /** The search parameter that will be used to search contains criteria the city */
  city?: string | null;
  /** The search parameter that will be used to search contains criteria the state */
  state?: string | null;
  /** The search parameter that will be used to search contains criteria the zip */
  zip?: string | null;
  /** The search parameter that will be used to search equals criteria echeck */
  eCheck?: boolean | null;
  status?: APIPaymentStatus;
  /** The search parameter that will be used to search contains criteria the kId */
  kId?: string | null;
}

export enum APIPaymentStatus {
  Draft = 'Draft',
  Requested = 'Requested',
  Created = 'Created',
  Void = 'Void',
  OpenToPrint = 'OpenToPrint',
  TryToPrint = 'TryToPrint',
  CouldNotPrint = 'CouldNotPrint',
  Printed = 'Printed',
  Emailed = 'Emailed',
  Mailed = 'Mailed',
  Expired = 'Expired',
  Cleared = 'Cleared',
}

export interface APIPaymentUserRequestModel {
  /**
   * The payment amount
   * @format double
   * @example 100.5
   */
  amount: number;
  /**
   * The unique identifier of the organization initiating the payment
   * @format uuid
   * @example "3fa85f64-5717-4562-b3fc-2c963f66afa6"
   */
  organizationId: string;
  /**
   * Unique identifier for the bank
   * @format uuid
   * @example "3fa85f64-5717-4562-b3fc-2c963f66afa6"
   */
  bankId: string;
  /**
   * Unique identifier for the payee
   * @format uuid
   * @example "3fa85f64-5717-4562-b3fc-2c963f66afa6"
   */
  payeeId: string;
}

export interface APIPosition {
  /** @format double */
  x?: number;
  /** @format double */
  y?: number;
}

export interface APIPrintResult {
  success?: boolean;
}

export interface APISchema {
  name?: string | null;
  type?: string | null;
  content?: string | null;
  position?: APIPosition;
  /** @format double */
  width?: number;
  /** @format double */
  height?: number;
  /** @format double */
  rotate?: number | null;
  /** @format double */
  opacity?: number | null;
  readOnly?: boolean | null;
  required?: boolean | null;
  bodyRange?: APIBodyRange;
  isSplit?: boolean | null;
}

export interface APISearchSortOrder {
  /**
   * The name of a sort field
   * @default "Id"
   * @example "OrderNumber"
   */
  sortField?: string | null;
  sortOrder?: APISortOrder;
}

export interface APISettingModel {
  name: APISettingName;
  /** @minLength 1 */
  value: string;
}

export enum APISettingName {
  NewECheckReceiveEmail = 'NewECheckReceiveEmail',
  AddedToOrganizationEmail = 'AddedToOrganizationEmail',
}

export enum APISortOrder {
  ASC = 'ASC',
  DESC = 'DESC',
}

export interface APITypeaheadPayeeFilter {
  /** The value pattern to search */
  query?: string | null;
  /**
   * The number of result items to return
   * @format int32
   * @example 5
   */
  count?: number;
  /**
   * Ids to exclude from search result
   * @example [1,2,3]
   */
  excludedIds?: string[] | null;
  /**
   * Ids to include in search result regardless of other criteria
   * @example [4,5,6]
   */
  includedIds?: string[] | null;
  /**
   * The organization ids to filter the search results by
   * @example [101,102]
   */
  organizationIds?: string[] | null;
  /** @format uuid */
  userId?: string | null;
  userSearch?: boolean;
}

export interface APIUserAuthModel {
  email?: string | null;
  password?: string | null;
}

/** Represents a user in the EasyECheck system with their personal information and organizational relationships */
export interface APIUserModel {
  /** @format uuid */
  id: string;
  /**
   * The user's first name
   * @minLength 1
   * @maxLength 200
   * @example "John"
   */
  firstName: string;
  /**
   * The user's last name
   * @minLength 1
   * @maxLength 200
   * @example "Smith"
   */
  lastName: string;
  /**
   * The user's email address used for communication and login
   * @minLength 1
   * @maxLength 200
   * @example "john.smith@example.com"
   */
  email: string;
  /**
   * The user's phone number for contact purposes
   * @minLength 1
   * @maxLength 25
   * @example "+1-555-123-4567"
   */
  phone: string;
  /**
   * URL or path to the user's profile image
   * @example "/images/avatars/user123.jpg"
   */
  avatar?: string | null;
  userType?: APIUserType;
  accessLevel?: APIAccessLevel;
  /** The organizations this user belongs to with their specific roles */
  userOrganizations: APIUserOrganizationModel[];
}

export interface APIUserModelSearchResult {
  /**
   * The search page number, will be used for offset
   * @format int32
   * @example 0
   */
  pageNumber?: number;
  /**
   * The count of entities on the page
   * @format int32
   * @example 20
   */
  pageSize?: number;
  /** The list of the searched entities */
  result?: APIUserModel[] | null;
}

export interface APIUserOrganizationModel {
  /** Represents an organization within the system */
  organization: APIOrganizationModel;
  userType?: APIUserType;
  accessLevel?: APIAccessLevel;
}

export interface APIUserRegisterModel {
  /** The first name of the registering user. */
  firstName?: string | null;
  /** The last name of the registering user. */
  lastName?: string | null;
  /** The email address of the registering user. */
  email?: string | null;
  /** The password of the registering user. */
  password?: string | null;
}

export interface APIUserResetPasswordModel {
  /** @minLength 1 */
  email: string;
  /**
   * Gets or sets the password for the user.
   * @minLength 1
   */
  password: string;
  /** @format uuid */
  resetPasswordToken: string;
}

/** Filter for user search */
export interface APIUserSearchFilter {
  /**
   * The search page number, will be used for offset
   * @format int32
   * @example 0
   */
  pageNumber?: number;
  /**
   * The count of entities on the page
   * @format int32
   * @example 20
   */
  pageSize?: number;
  /** a collection of sort fields */
  searchSortOrders?: APISearchSortOrder[] | null;
  /** The search parameter that will be used to search contains criteria the id */
  id?: string | null;
  /** The search parameter that will be used to search contains criteria the first name */
  firstName?: string | null;
  /** The search parameter that will be used to search contains criteria the last name */
  lastName?: string | null;
  /** The search parameter that will be used to search contains criteria the email */
  email?: string | null;
  userType?: APIUserType;
  userStatus?: APIUserStatus;
  accessLevel?: APIAccessLevel;
  /** The search parameter that will be used to search contains criteria the organization id */
  organizationIds?: string[] | null;
}

export enum APIUserStatus {
  Active = 'Active',
  Inactive = 'Inactive',
  Deleted = 'Deleted',
  NotRegistered = 'NotRegistered',
}

export enum APIUserType {
  User = 'User',
  Admin = 'Admin',
}
