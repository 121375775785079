import React, { useEffect } from 'react';

import { Box, Loader, PaymentInfo, Typography } from 'components';

import { useParams, useSelector, useTranslation } from 'third-party';

import { usePayment } from 'modules/payment';

import { paymentSelectors } from 'selectors';

export const PaymentDetail = () => {
  const { paymentId } = useParams<{ paymentId: string }>();

  const { loadUserPayment } = usePayment();

  const paymentPending = useSelector(paymentSelectors.paymentPending);
  const payment = useSelector(paymentSelectors.payment);
  const { t } = useTranslation();

  useEffect(() => {
    if (paymentId) {
      loadUserPayment(paymentId);
    }
  }, [loadUserPayment, paymentId]);

  if (paymentPending || !payment) {
    return <Loader />;
  }

  return (
    <Box>
      <Typography variant="h2" color="text.primary" margin="1rem">
        {t('userPayment.checkDetails')}
      </Typography>
      <PaymentInfo payment={payment} />
    </Box>
  );
};
