import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

export const resources = {
  en: {
    translation: {
      'common.delete': 'Delete',
      'common.apply': 'Apply',
      'common.reset': 'Reset',
      'common.approve': 'Approve',
      'common.loading': 'Loading ...',
      'common.loadMore': 'Load more',
      'common.send': 'Send',
      'common.slogan': 'Easy electronic check management',
      'common.logout': 'Log out',
      'common.termsAgreement': 'I agree to ',
      'common.termsAndConditions': 'Terms and Conditions',
      'common.note': 'Note',
      'common.archive': 'Archive',
      'common.activate': 'Activate',

      'common.emptyState': 'No items found',
      'common.emptyState.clearFilters': 'Clear filters',
      'common.emptyState.description':
        'There are no items to display at the moment. \nTry adjusting your filters or check back later.',

      // user menu
      'menu.myPayments': 'My payments',
      'menu.myPayees': 'My payees',
      'menu.allPayees': 'All payees',
      'menu.allPayments': 'All payments',
      'menu.logOut': 'Log out',
      'menu.profile': 'Profile',

      // empty
      'common.empty.header': 'This page is under development',
      'common.empty.subHeader': 'This is empty sub header',

      // common navigation
      'common.navigation.backToLogin': 'Back to login',

      // forms
      'common.form.email.label': 'email',
      'common.form.email.placeholder': 'Enter email address',
      'common.form.email.error.invalid': 'Invalid Email Address',
      'common.form.email.notFoundText': 'No email found',
      'common.form.emptyFieldError': 'This field is required',

      'common.form.password.label': 'Password',
      'common.form.password.placeholder': 'Enter Password',
      'common.form.password.hint':
        '{{minLength}} characters minimum, case sensitive.',
      'common.form.save': 'Save',
      'common.form.request': 'Request',
      'common.form.saveAsDraft': 'Draft',
      'common.form.cancel': 'Cancel',
      'common.form.deleteConfirm': 'Are you sure you want to delete this item?',
      'common.form.confirm': 'Confirm',
      'common.form.fieldRequired': 'This field is required',
      'common.form.enterValueFor': 'Enter value for ',
      'common.form.typeToSearchValueFor': 'Type to search value for ',

      'common.typeahead.notFound': 'Not Found',
      'common.typeahead.nothingFound': 'Nothing Found',
      'common.typeahead.create': 'Create',
      'common.typeahead.noMatchingUser': 'No matching user',

      // avatar
      'avatar.upload': 'Upload',
      'avatar.change': 'Save',
      'avatar.zoom': 'Zoom',
      'avatar.rotation': 'Rotation',
      'avatar.rotate': 'Rotate',
      'avatar.save': 'Save Avatar',
      'avatar.open': 'Open file for avatar',

      // user profile
      'userProfile.header': 'User Profile',
      'userProfile.subHeader': 'Here you can see and edit your profile details',
      'userProfile.firstName': 'First Name',
      'userProfile.lastName': 'Last Name',
      'userProfile.email': 'Email',
      'userProfile.phone': 'Phone',

      // table
      'table.columnVisibility': 'Column visibility',
      'table.empty': 'There are no items to display',
      'table.loadMore': 'Load more',
      'table.delete': 'Delete',
      'table.edit': 'Edit',
      'table.download': 'Download',
      'table.resend': 'Resend',

      // common errors
      'errors.fields.fieldLongerThen': 'Field is longer than',
      'errors.fields.characters': 'characters',
      'errors.fields.emptyFieldError': "Field can't be empty",

      // login form
      'login.header': 'Login into account',
      'login.subHeader': 'Enter your email and password to your account',
      'login.signIn': 'Sing in',
      'login.register': 'Register',
      'login.forgotPassword': 'Forgot password?',
      'login.continueWith': 'or continue with',
      'login.invalidCredentials': 'Invalid email or password',
      'login.terms':
        'By clicking continue, you agree to our Terms of Service and Privacy Policy.',

      // forgot password form
      'forgotPassword.header': 'Forgot Your Password?',
      'forgotPassword.subHeader':
        'Enter your email address to receive a password reset link',
      'forgotPassword.fields.email': 'Email',
      'forgotPassword.sendResetEmail': 'Send reset link',
      'forgotPassword.emailSent.header': 'Password Reset Submitted',
      'forgotPassword.emailSent.subHeader':
        'If we have an account matching your email address, you will receive an email to reset your password.',
      'forgotPassword.genericError': 'Error sending password reset link',

      // reset password form
      'resetPassword.header': 'Create New Password',
      'resetPassword.subHeader': 'Enter your new password',
      'resetPassword.completed.header': 'Password Reset Completed',
      'resetPassword.completed.subHeader':
        'You can now log in with your new password.',
      'resetPassword.confirm': 'Confirm',
      'resetPassword.invalidTokenOrEmail': 'Invalid token or email',

      'register.header': 'User Registration',
      'register.subHeader':
        'Enter your email and password to register your account',
      'register.fields.firstName': 'First Name',
      'register.fields.lastName': 'Last Name',
      'register.fields.email': 'Email',
      'register.fields.password': 'Password',
      'register.fields.confirmPassword': 'Confirm Password',
      'register.genericError': 'Error registering user',
      'register.error.passwordNotSame': 'The passwords should be the same',
      'register.success': 'User registered successfully',
      'register.terms':
        'By clicking register, you agree to our Terms of Service and Privacy Policy.',

      // print echeck
      'printECheck.header': 'Print your e-check',
      'printECheck.printing': 'Printing document',
      'printECheck.checkPrintedHeader': 'The check has been printed',
      'printECheck.checkPrintedInfo': 'You can now close this window',
      'printECheck.subHeader':
        'Please print your e-check on check paper or plain white paper. Note that only a single printout is allowed.',
      'printECheck.amount': 'Amount: ',
      'printECheck.dateIssued': 'Issued Date: ',
      'printECheck.onWhitePaper': 'Print on white paper',
      'printECheck.onCheckPaper': 'Print on check paper',
      'printECheck.allowPopupsMessage':
        'To print the check, please allow pop-ups from this site. You can do this by going to your browser settings and selecting',
      'printECheck.alwaysAllowPopups': "'Always allow pop-ups from this site'",
      'printECheck.error': 'Error printing echeck',
      'printECheck.printing.endTitle': 'How was you printing experience?',
      'printECheck.printing.endText':
        'if it was successful, please click confirm, if not cancel',
      'printECheck.aboutInfo':
        "Our checks work just like regular checks. Bank Account must be verified for the sender to use this feature. However, we are not a bank and do not hold the check's funds. If you have printed the check on white paper, you may notice that the check is printed upside.",

      // Common Scams
      'commonScams.header': 'Common Scams',
      'commonScams.subHeader':
        'Each scam involving a fraudulent check may be different, but some of the more common scenarios are:',
      'commonScams.sellingGoods.title': 'Selling Goods',
      'commonScams.sellingGoods.description':
        'You sell products in the marketplace, for example, over the Internet. A buyer sends you a check for the price you agreed on, and you ship the goods to the buyer. The check turns out to be fraudulent.',

      'commonScams.excessPurchase.title': 'Excess of the Purchase Price',
      'commonScams.excessPurchase.description':
        'This scenario is similar to the one described above. However, the buyer sends you a check for more than the purchase price and asks you to wire some or all of the excess to a third party, often in a foreign country. The check turns out to be fraudulent.',

      'commonScams.unexpectedWindfall.title': 'Unexpected windfall',
      'commonScams.unexpectedWindfall.description':
        "You receive a letter or email stating that you have won a foreign lottery or are the beneficiary of someone's estate. The letter/email will say you have to pay a processing or transfer tax/fee before receiving the money, but a check will be enclosed to cover that fee. You are asked to deposit the check and wire the fee to a third party, often in a foreign country. The check turns out to be fraudulent.",

      'commonScams.mysteryShopping.title': 'Mystery Shopping',
      'commonScams.mysteryShopping.description':
        'You receive a letter or email informing you that you have been chosen to act as a mystery shopper, along with a check to deposit. You are told to use a portion of the funds to purchase merchandise, transfer a part of the funds to a third party, and keep the remainder. The check turns out to be fraudulent.',

      // organization list
      'organizationList.header': 'Organization List',
      'organizationList.subHeader':
        "Here's a list of all active organizations in your account",
      'organizationList.shareSuccess': 'Organization link copied to clipboard',
      'organizationList.menuItem.edit': 'Edit',
      'organizationList.menuItem.remove': 'Remove',
      'organizationList.deleteConfirm':
        'Are you sure you want to delete this organization?',
      'organizationList.userRole.user': 'Organization member',
      'organizationList.userRole.admin': 'Organization administrator',
      'organizationList.userRole.notInTheOrganization':
        'Not in this organization',

      // organization form
      'organizationForm.create.header': 'Organization Form',
      'organizationForm.create.subHeader':
        'Fill in the details of the new organization',
      'organizationForm.create.sucscefull': 'Organization created successfully',
      'organizationForm.create.error': 'Error creating organization',
      'organizationForm.edit.header': 'Edit organization',
      'organizationForm.edit.subHeader': 'Edit organization details',
      'organizationForm.edit.sucscefull': 'Organization updated successfully',
      'organizationList.column.name': 'Name',
      'organizationList.column.description': 'Description',
      'organizationForm.edit.error': 'Error updating organization',

      // payments list
      'payments.header': 'Payments',
      'payments.filters.placeholder': 'filter by paid and status',
      'payments.filters.selected': 'Selected',
      'payments.filters.paidTo': 'Paid to',
      'payments.filters.status': 'Status',
      'payments.subHeader': "Here's a list of all active payments",

      // payee list
      'payeeList.header': 'Payee List',
      'payeeList.subHeader':
        "Here's a list of all active payees in the account",
      'payeeList.menuItem.edit': 'Edit',
      'payeeList.menuItem.remove': 'Remove',
      'payeeList.deleteConfirm': 'Are you sure you want to delete this payee?',
      'payeeList.column.id': 'Id',
      'payeeList.column.user': 'User',
      'payeeList.column.organization': 'Organization',
      'payeeList.column.nameOnCheck': 'Name On Check',
      'payeeList.column.email': 'Email',
      'payeeList.column.phone': 'Phone',
      'payeeList.column.address': 'Address',
      'payeeList.column.city': 'City',
      'payeeList.column.state': 'State',
      'payeeList.column.status': 'Status',
      'payeeList.column.zip': 'Zip',
      'payeeList.column.user.firstName': 'User First Name',
      'payeeList.column.user.lastName': 'User Last Name',
      'payeeList.column.user.email': 'User Email',

      // payee form
      'payeeForm.create.header': 'Payee Form',
      'payeeForm.create.subHeader': 'Fill in the details of the new payee',
      'payeeForm.create.sucscefull': 'Payee created successfully',
      'payeeForm.create.error': 'Error creating payee',
      'payeeForm.edit.header': 'Edit payee',
      'payeeForm.edit.sucscefull': 'Payee updated successfully',
      'payeeForm.edit.subHeader': 'Edit payee details',
      'payeeForm.edit.error': 'Error updating payee',

      //bank list
      'bankList.header': 'Bank List',
      'bankList.subHeader': "Here's a list of all active banks in the account",
      'bankList.column.id': 'Id',
      'bankList.column.account': 'Account',
      'bankList.column.routing': 'Routing',
      'bankList.column.accountName': 'Account Name',
      'bankList.column.bankName': 'Bank Name',
      'bankList.column.companyName': 'Company Name',
      'bankList.column.address': 'Address',
      'bankList.column.city': 'City',
      'bankList.column.state': 'State',
      'bankList.column.zip': 'Zip',
      'bankList.column.createdOn': 'Created On',
      'bankList.deleteConfirm': 'Are you sure you want to delete this bank?',
      'bankList.actions.checkDesigner': 'Check designer',
      'bankList.actions.advancedCheckDesigner': 'Advanced designer',

      // bank form
      'bankForm.create.header': 'Bank Form',
      'bankForm.create.subHeader': 'Fill in the details of the new bank',
      'bankForm.create.sucscefull': 'Bank created successfully',
      'bankForm.create.error': 'Error creating bank',
      'bankForm.edit.header': 'Edit bank',
      'bankForm.edit.sucscefull': 'Bank updated successfully',
      'bankForm.edit.subHeader': 'Edit bank details',
      'bankForm.edit.error': 'Error updating bank',

      // payment list
      'paymentList.header': 'Payment List',
      'paymentList.subHeader':
        "Here's a list of all active payment in the account",
      'paymentList.deleteConfirm':
        'Are you sure you want to delete this payment?',
      'paymentList.column.id': 'Id',
      'paymentList.column.amount': 'Amount',
      'paymentList.column.bank': 'Bank Name',
      'paymentList.column.checkNumber': 'Check Number',
      'paymentList.column.checkDate': 'Check Date',
      'paymentList.column.note': 'Note',
      'paymentList.column.memo': 'Memo',
      'paymentList.column.name': 'Name',
      'paymentList.column.company': 'Company',
      'paymentList.column.nameOnCheck': 'Name On Check',
      'paymentList.column.verifyCode': 'Verify Code',
      'paymentList.column.referenceId': 'Reference Id',
      'paymentList.column.email': 'Email',
      'paymentList.column.phone': 'Phone',
      'paymentList.column.address': 'Address',
      'paymentList.column.city': 'City',
      'paymentList.column.state': 'State',
      'paymentList.column.zip': 'Zip',
      'paymentList.column.kId': 'KId',
      'paymentList.column.status': 'Status',
      'paymentList.column.payee': 'Payee',
      'paymentList.column.payee.label':
        'Type here to select existing payee, or create new one',
      'paymentList.column.payee.helpText':
        'Leave this field empty, if you would like to create a new payee',
      'paymentList.cardActions.print': 'Print check',
      'paymentList.cardActions.printDisabled':
        'You can not print this check, it is was either already printed or not approved',
      'paymentList.actions.resend': 'Payment has been resent',

      // user payments
      'userPaymentList.header': 'My Payments',
      'userPaymentList.subHeader': "Here's a list of all your active payments",
      'userPayment.checkDetails': 'Check details',
      'userPayment.requestPayment': 'Request payment',

      // payment request form
      'paymentRequestForm.create.header': 'Payment Request Form',
      'paymentRequestForm.create.subHeader':
        'Fill in the details of the new payment request',
      'paymentRequestForm.edit.sucscefull':
        'Payment request created successfully',
      'paymentRequestForm.edit.error': 'Error creating payment request',
      'paymentRequestForm.helpTex':
        'Select organization first to create payment request',

      // bank form
      'paymentForm.create.header': 'Payment Form',
      'paymentForm.create.subHeader': 'Create of the new payment',
      'paymentForm.create.sucscefull': 'Payment created successfully',
      'paymentForm.create.error': 'Error creating payment',
      'paymentForm.edit.header': 'Edit payment',
      'paymentForm.edit.sucscefull': 'Payment updated successfully',
      'paymentForm.edit.subHeader': 'Edit payment details',
      'paymentForm.edit.error': 'Error updating payment',

      // user list
      'userList.header': 'User List',
      'userList.subHeader': "Here's a list of all active users in the account",
      'userList.deleteConfirm': 'Are you sure you want to delete this user?',
      'userList.column.firstName': 'First Name',
      'userList.column.lastName': 'Last Name',
      'userList.column.email': 'Email',
      'userList.column.userType': 'User Type',
      'userList.column.accessLevel': 'Access Level',
      'userList.column.organization': 'Organization',

      // user form
      'userForm.create.header': 'User Form',
      'userForm.create.subHeader': 'Fill in the details of the new user',
      'userForm.create.sucscefull': 'User created successfully',
      'userForm.create.error': 'Error creating user',
      'userForm.edit.header': 'Edit user',
      'userForm.edit.sucscefull': 'User updated successfully',
      'userForm.edit.subHeader': 'Edit user details',
      'userForm.edit.error': 'Error updating user',

      // email designer
      'emailDesigner.tab': 'Email designer',
      'emailDesigner.tabs.edit': 'Edit',
      'emailDesigner.panel.text': 'Content',

      'emailDesigner.panel.spacer': 'Spacer block',
      'emailDesigner.panel.image.sourceUrl': 'Source URL',
      'emailDesigner.panel.image.altText': 'Alt Text',
      'emailDesigner.panel.image.clickUrl': 'Click through URL',
      'emailDesigner.panel.image.width': 'Width',
      'emailDesigner.panel.image.height': 'Height',
      'emailDesigner.panel.image.alignment': 'Alignment',
      'emailDesigner.panel.html': 'Content',
      'emailDesigner.panel.heading': 'Heading block',
      'emailDesigner.panel.heading.level': 'Level',
      'emailDesigner.panel.emailLayout': 'Global',
      'emailDesigner.panel.emailLayout.backdropColor': 'Backdrop color',
      'emailDesigner.panel.emailLayout.canvasColor': 'Canvas color',
      'emailDesigner.panel.emailLayout.canvasBorderColor':
        'Canvas border color',
      'emailDesigner.panel.emailLayout.canvasBorderRadius':
        'Canvas border radius',
      'emailDesigner.panel.emailLayout.fontFamily': 'Font family',
      'emailDesigner.panel.emailLayout.textColor': 'Text color',
      'emailDesigner.panel.dividerBlock': 'Divider block',
      'emailDesigner.panel.dividerBlock.color': 'Color',
      'emailDesigner.panel.dividerBlock.height': 'Height',
      'emailDesigner.panel.containerBlock': 'Container block',
      'emailDesigner.panel.columnsContainer': 'Number of columns',
      'emailDesigner.panel.columnsContainer.columnsGap': 'Columns gap',
      'emailDesigner.panel.columnsContainer.alignment': 'Alignment',
      'emailDesigner.panel.button': 'Button block',
      'emailDesigner.panel.button.text': 'Text',
      'emailDesigner.panel.button.url': 'Url',
      'emailDesigner.panel.button.width': 'Width',
      'emailDesigner.panel.button.width.full': 'Full',
      'emailDesigner.panel.button.width.auto': 'Auto',
      'emailDesigner.panel.button.size': 'Size',
      'emailDesigner.panel.button.style': 'Style',
      'emailDesigner.panel.button.style.rectangle': 'Rectangle',
      'emailDesigner.panel.button.style.rounded': 'Rounded',
      'emailDesigner.panel.button.style.pill': 'Pill',
      'emailDesigner.panel.button.style.textColor': 'Text color',
      'emailDesigner.panel.button.style.buttonColor': 'Button color',
      'emailDesigner.tabs.preview': 'Preview',
      'emailDesigner.actions.exportTemplate': 'Export template',
      'emailDesigner.actions.saveTemplate': 'Save template',
      'emailDesigner.actions.importTemplate': 'Import template',
      'emailDesigner.import.selectFileTitle':
        'Import Email Template file (.json)',
      'emailDesigner.import.selectFile': 'Import Email Template file (.json)',
      'emailDesigner.import.senseful': 'Document was imported successfully',
      'emailDesigner.save.senseful': 'Document was saved successfully',

      'emailDesigner.template.NewECheckReceiveEmail': 'ECheck Template',
      'emailDesigner.template.AddedToOrganizationEmail':
        'Added to organization',
      'emailDesigner.actions.revertTemplate': 'Revert template',
      'emailDesigner.actions.help': 'Design helper',
      'emailDesigner.error.invalidFile':
        'Cannot import this file, please check format',
      'emailDesigner.help.header': 'Design Helper',
      'emailDesigner.help.subHeader': 'Here you can get information about ',

      // check designer keywords help
      'checkDesigner.help.keywords.title': 'Keywords',
      'checkDesigner.help.keywords.description': 'Description',
      'checkDesigner.help.description':
        'You can use the following keywords in the template to display the actual data',
      'checkDesigner.help.keywords.user_name':
        'The name of the user who receives the check',
      'checkDesigner.help.keywords.amount': 'The amount of the transaction',
      'checkDesigner.help.keywords.link': 'The link to the print check page',
      'checkDesigner.help.keywords.verify': 'The link to the verify check page',

      'simpleCheckDesigners.advancedDesigner': 'Advanced designer',
      'simpleCheckDesigner.header': 'Simple Check Designer',
      'simpleCheckDesigner.subHeader':
        'Here you can design your check template',
      'simpleCheckDesigner.success': 'Template was successfully saved',

      // check designer
      'checkDesigner.actions.downloadTemplate': 'Download',
      'checkDesigner.actions.loadTemplate': 'Load Template',
      'checkDesigner.actions.changePDF': 'Change PDF',
      'checkDesigner.actions.previewPDF': 'Preview PDF',
      'checkDesigner.actions.saveComplete': 'Template was successfully saved',

      // signature pad
      'signaturePad.size': 'Size',
      'signaturePad.smoothing': 'Smoothing',
      'signaturePad.thinning': 'Thinning',
      'signaturePad.streamline': 'Streamline',
      'signaturePad.resetSettings': 'Reset settings',
      'signaturePad.clear': 'Clear',
      'signaturePad.save': 'Save',

      //check verification
      'verifyCheck.header': 'Check Verification',
      'verifyCheck.result': 'Verification Result',
      'verifyCheck.subHeader':
        'To verify the check, please enter the  verification code',
      'verifyCheck.code': 'verification code',
      'verifyCheck.verify': 'Verify Check',

      'verifyCheck.checkValid': 'Check is not valid',
      'verifyCheck.checkValidText':
        'The check you are looking with this <strong>{{code}}</strong> code, is not valid',
      'verifyCheck.returnBack': 'Return back to search',
    },
  },
  uk: {
    translation: {},
  },
};

i18next
  .use(initReactI18next)
  .use(LanguageDetector)
  .use(Backend)
  .init({
    resources,
    debug: process.env.I18N_DEBUG === 'true',
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
  });

export default i18next;
