import React, { useCallback, useEffect, useState } from 'react';

import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';

import { DialogModal, Loader, MenuItem, ModalBox, Table } from 'components';

import { UserCreateEditForm } from './UserCreateEditForm';
import { userColumns } from './userColumns';

import { useSelector, useTranslation } from 'third-party';

import { useUser } from 'modules/user';

import { appSelectors, userSelectors } from 'selectors';

import { APISortOrder, APIUser } from 'types/api';

export const UserList = () => {
  const { t } = useTranslation();

  const [selectedItem, setSelectedItem] = useState<APIUser | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { loadUsers, loadMoreUsers, updateUserFilters, deleteUser } = useUser();

  const organizationId = useSelector(appSelectors.organizationId);
  const items = useSelector(userSelectors.userList);
  const userListLoaded = useSelector(userSelectors.usersListLoaded);
  const allUsersLoaded = useSelector(userSelectors.allUsersLoaded);
  const userFilters = useSelector(userSelectors.userFilters);
  const loadUserPending = useSelector(userSelectors.loadUserPending);
  const loadMoreUsersPending = useSelector(userSelectors.loadMoreUsersPending);
  const [columns, setColumns] = useState(userColumns(organizationId));

  useEffect(() => {
    if (organizationId) {
      loadUsers();
      setColumns(userColumns(organizationId));
    }
  }, [userFilters, organizationId, loadUsers]);

  const onColumnFilterChange = useCallback(
    (columnId: string, value: string) => {
      setColumns(
        columns.map(column => {
          if (column.dataId === columnId) {
            return { ...column, filter: value };
          }
          return column;
        }),
      );

      updateUserFilters({ ...userFilters, [columnId]: value });
    },
    [userFilters, columns, updateUserFilters],
  );

  const onFilterReset = useCallback(() => {
    updateUserFilters({});
    setColumns(it => it.map(column => ({ ...column, filter: undefined })));
  }, [updateUserFilters]);

  const onColumnVisibilityChanged = useCallback(
    (columnId: string, value: boolean) => {
      setColumns(it =>
        it.map(column => {
          if (column.dataId === columnId) {
            return { ...column, hidden: !value };
          }
          return column;
        }),
      );
    },
    [],
  );

  const rowOptionsMenu = useCallback(
    (item: APIUser, onRowMenuClose: () => void): React.ReactElement[] => [
      <MenuItem
        key={'delete'}
        onClick={() => {
          setSelectedItem(item);
          onRowMenuClose();
        }}
      >
        <DeleteOutlineOutlinedIcon
          color="primary"
          style={{ paddingRight: '0.5rem' }}
        />
        {t('table.delete')}
      </MenuItem>,
      <MenuItem
        key={'edit'}
        onClick={() => {
          setIsModalOpen(true);
          setSelectedItem(item);
          onRowMenuClose();
        }}
      >
        <EditOutlinedIcon color="primary" style={{ paddingRight: '0.5rem' }} />
        {t('table.edit')}
      </MenuItem>,
    ],
    [t],
  );

  const onModalClose = useCallback(() => {
    setIsModalOpen(false);
    setSelectedItem(null);
  }, []);

  const onColumnHeaderClicked = useCallback(
    (columnDataId: string) => {
      const existingSortOrder = userFilters.searchSortOrders?.find(
        order => order.sortField === columnDataId,
      );

      const newSortOrder =
        existingSortOrder && existingSortOrder.sortOrder === APISortOrder.DESC
          ? APISortOrder.ASC
          : APISortOrder.DESC;

      updateUserFilters({
        ...userFilters,
        searchSortOrders: [
          {
            sortField: columnDataId,
            sortOrder: newSortOrder,
          },
        ],
      });
    },
    [userFilters, updateUserFilters],
  );

  if (!userListLoaded) {
    return <Loader />;
  }

  return (
    <>
      <Table<APIUser>
        header={t('userList.header')}
        subHeader={t('userList.subHeader')}
        filterable
        allItemsLoaded={allUsersLoaded}
        infiniteScroll
        tableMinHeight="20rem"
        columns={columns}
        items={items || []}
        sortOrder={userFilters.searchSortOrders?.[0]?.sortOrder}
        rowOptionsMenu={rowOptionsMenu}
        onColumnHeaderClicked={onColumnHeaderClicked}
        onColumnFilterChange={onColumnFilterChange}
        onColumnVisibilityChanged={onColumnVisibilityChanged}
        loadItemsPending={loadUserPending}
        loadMoreItemsPending={loadMoreUsersPending}
        onLoadMoreClicked={loadMoreUsers}
        onFilterReset={onFilterReset}
        bodyRowHeight="1rem"
        headerRowHeight="0.2rem"
        itemsLoaded={userListLoaded}
        onNewItemButtonClicked={() => setIsModalOpen(true)}
        emptyText={t('table.empty')}
        loadMoreButtonText={t('table.loadMore')}
      />
      {isModalOpen && (
        <ModalBox width="30rem" height="30rem" onClose={onModalClose}>
          <UserCreateEditForm
            onModalClose={onModalClose}
            userId={selectedItem?.id}
          />
        </ModalBox>
      )}
      <DialogModal
        onClose={() => setSelectedItem(null)}
        open={!!selectedItem && !isModalOpen}
        description={t('payeeList.deleteConfirm')}
        handleActionClick={() => {
          if (selectedItem) {
            deleteUser(selectedItem.id);
          }
          setSelectedItem(null);
        }}
      />
    </>
  );
};
