import { State } from 'modules';

import { APIAccessLevel, APIUserOrganization } from 'types/api';

export const user = (state: State) => state.app?.user;
export const selectedOrganization = (state: State) =>
  state.app?.user?.userOrganizations?.find(it => it.isSelected);

export const userId = (state: State) => state.app?.user?.id;

export const userType = (state: State) => state.app?.user?.userType;

export const organizationId = (state: State) =>
  state.app?.user?.userOrganizations?.find(it => it.isSelected)?.organization
    .id ?? '';

export const organizations = (state: State) =>
  state.app.user?.userOrganizations as APIUserOrganization[];

export const isAppLoaded = (state: State) => state.app.isAppLoaded;
export const userInfoPending = (state: State) => state.app.userInfoPending;
export const userLoginInPending = (state: State) =>
  state.app.userLoginInPending;

export const isNavigationCollapsed = (state: State) =>
  state.app.isNavigationCollapsed;

export const userRegisterPending = (state: State) =>
  state.app.userRegisterPending;

export const accessLevel = (state: State) =>
  state.app?.user?.userOrganizations?.find(it => it.isSelected)?.accessLevel;

export const isAdminUser = (state: State) => {
  const accessLevel = state.app?.user?.userOrganizations?.find(
    it => it.isSelected,
  )?.accessLevel;

  return (
    accessLevel === APIAccessLevel.Admin ||
    accessLevel === APIAccessLevel.SuperVisor
  );
};

export const updateAvatarPending = (state: State) =>
  state.app.updateAvatarPending;

export const updateSelfInfoPending = (state: State) =>
  state.app.updateSelfInfoPending;
