import React, { useEffect, useState } from 'react';

import {
  Box,
  Button,
  Checkbox,
  DialogModal,
  Divider,
  FormControlLabel,
  Link,
  Loader,
  PageHeader,
  Typography,
} from 'components';
import CheckPaper from 'images/check_c_p.svg';
import CheckWhitePaper from 'images/check_w_p.svg';

import {
  dayjs,
  useNavigate,
  useParams,
  useSelector,
  useTranslation,
} from 'third-party';

import { anonymousPrintPaymentEndpoint } from 'constants/endpoints';
import { notFoundUrl } from 'constants/url';

import { useAnonyms } from 'modules/anonyms';

import { anonymsSelectors } from 'selectors';

import { printPdf } from 'utils/print';

import { APIPaymentStatus, PrintType } from 'types/api';

export const PrintECheck = () => {
  const { t } = useTranslation();
  const { loadAnonymsPayment, finishPrinting } = useAnonyms();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const loadPaymentPending = useSelector(anonymsSelectors.loadPaymentPending);
  const loadAnonymsPaymentError = useSelector(
    anonymsSelectors.loadAnonymsPaymentError,
  );
  const payment = useSelector(anonymsSelectors.payment);
  const { eCheckId } = useParams<{ eCheckId: string }>();

  const navigate = useNavigate();

  useEffect(() => {
    if (eCheckId) {
      loadAnonymsPayment(eCheckId);
    }
  }, [loadAnonymsPayment, eCheckId]);

  useEffect(() => {
    if (loadAnonymsPaymentError) {
      navigate(notFoundUrl());
    }
  }, [navigate, loadAnonymsPaymentError]);

  const [agreed, setAgreed] = useState(false);

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAgreed(event.target.checked);
  };

  const printCheck = async (id: string, type: PrintType) => {
    printPdf(anonymousPrintPaymentEndpoint(id, type), () =>
      setIsModalOpen(true),
    );
  };

  if (!payment || loadPaymentPending) {
    return <Loader />;
  }

  if (payment.status == APIPaymentStatus.Printed) {
    return (
      <>
        <Divider sx={{ mt: 3 }} />
        <Typography variant="h1" sx={{ mt: 3, textAlign: 'center' }}>
          {t('printECheck.checkPrintedHeader')}
        </Typography>
        <Typography variant="h1" sx={{ mt: 1, textAlign: 'center' }}>
          {t('printECheck.checkPrintedInfo')}
        </Typography>

        <Divider sx={{ mt: 5 }} />
      </>
    );
  }

  return (
    <Box
      sx={{
        height: '90vh',
        p: '1.5rem',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <PageHeader
        header={t('printECheck.header')}
        subHeader={t('printECheck.subHeader')}
      />

      <Box
        sx={{
          mt: 3,
          flex: 1,
          minHeight: 0,
          display: 'flex',
          flexDirection: 'column',
          overflowY: 'auto',
          '&::-webkit-scrollbar': {
            width: '8px',
          },
          '&::-webkit-scrollbar-track': {
            background: '#f1f1f1',
            borderRadius: '4px',
          },
          '&::-webkit-scrollbar-thumb': {
            background: '#888',
            borderRadius: '4px',
          },
          '&::-webkit-scrollbar-thumb:hover': {
            background: '#555',
          },
        }}
      >
        <Divider sx={{ mb: 3 }} />

        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 2 }}>
          <Typography variant="h2">{t('printECheck.amount')}</Typography>
          <Typography variant="h2" color="text.secondary">
            ${payment.amount}
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 2 }}>
          <Typography variant="h2">{t('printECheck.dateIssued')}</Typography>
          <Typography variant="h2" color="text.secondary">
            {dayjs(payment.checkDate).format('YYYY-MM-DD')}
          </Typography>
        </Box>

        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 2 }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={agreed}
                onChange={handleCheckboxChange}
                color="primary"
              />
            }
            label={
              <Typography variant="body1">
                {t('common.termsAgreement')}
                <Link href="/terms" underline="hover">
                  {t('common.termsAndConditions')}
                </Link>
              </Typography>
            }
          />
        </Box>

        <Divider sx={{ my: 3 }} />

        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            gap: 4,
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            mb: 2,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: 2,
              flex: { xs: '1', md: '0 1 auto' },
            }}
          >
            <img
              alt="Check White Paper"
              width="220"
              height="263"
              src={CheckWhitePaper}
              style={{
                objectFit: 'cover',
                maxWidth: '100%',
                height: 'auto',
              }}
            />
            <Button
              variant="outlined"
              color="success"
              disabled={!agreed}
              sx={{ width: { xs: 'full', sm: 'auto' } }}
              onClick={() => printCheck(payment.id, PrintType.WhitePaper)}
            >
              {t('printECheck.onWhitePaper')}
            </Button>
          </Box>

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: 2,
              flex: { xs: '1', md: '0 1 auto' },
            }}
          >
            <Box
              component="img"
              alt="Check Paper"
              src={CheckPaper}
              sx={{
                display: { xs: 'none', md: 'block' },
                width: 220,
                objectFit: 'cover',
                maxWidth: '100%',
                height: 'auto',
              }}
            />
            <Button
              variant="contained"
              color="primary"
              disabled={!agreed}
              sx={{ width: { xs: 'full', sm: 'auto' } }}
              onClick={() => printCheck(payment.id, PrintType.Check)}
            >
              {t('printECheck.onCheckPaper')}
            </Button>
          </Box>
        </Box>
        <Divider sx={{ my: 2 }} />
        <Box>
          <Typography variant="subtitle1" fontWeight="bold">
            {t('common.note')}
          </Typography>
          <Typography variant="body2" sx={{ opacity: 0.6 }}>
            {t('printECheck.aboutInfo')}
          </Typography>
        </Box>

        <Box>
          <Typography variant="subtitle1" fontWeight="bold">
            {t('commonScams.header')}
          </Typography>
          <Box sx={{ ml: 2 }}>
            <Typography variant="body2" sx={{ opacity: 0.6, mb: 2 }}>
              {t('commonScams.subHeader')}
            </Typography>
            <ul style={{ marginLeft: '1rem' }}>
              <li style={{ marginBottom: '1rem' }}>
                <Typography variant="body2" fontWeight="bold">
                  {t('commonScams.sellingGoods.title')}
                </Typography>
                <Typography variant="body2" sx={{ opacity: 0.6 }}>
                  {t('commonScams.sellingGoods.description')}
                </Typography>
              </li>
              <li style={{ marginBottom: '1rem' }}>
                <Typography variant="body2" fontWeight="bold">
                  {t('commonScams.excessPurchase.title')}
                </Typography>
                <Typography variant="body2" sx={{ opacity: 0.6 }}>
                  {t('commonScams.excessPurchase.description')}
                </Typography>
              </li>
              <li style={{ marginBottom: '1rem' }}>
                <Typography variant="body2" fontWeight="bold">
                  {t('commonScams.unexpectedWindfall.title')}
                </Typography>
                <Typography variant="body2" sx={{ opacity: 0.6 }}>
                  {t('commonScams.unexpectedWindfall.description')}
                </Typography>
              </li>
              <li style={{ marginBottom: '1rem' }}>
                <Typography variant="body2" fontWeight="bold">
                  {t('commonScams.mysteryShopping.title')}
                </Typography>
                <Typography variant="body2" sx={{ opacity: 0.6 }}>
                  {t('commonScams.mysteryShopping.description')}
                </Typography>
              </li>
            </ul>
          </Box>
        </Box>
      </Box>
      <DialogModal
        onClose={() => setIsModalOpen(false)}
        open={isModalOpen}
        title={t('printECheck.printing.endTitle')}
        description={t('printECheck.printing.endText')}
        handleActionClick={() => {
          finishPrinting(payment.id, true);
          setIsModalOpen(false);
        }}
        handleCancelClick={() => {
          finishPrinting(payment.id, false);
          setIsModalOpen(false);
        }}
      />
    </Box>
  );
};
