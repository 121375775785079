import React from 'react';

import { Box, Typography } from 'components';

type Props = {
  color?: string;
  label: string | number;
  icon: React.ElementType;
};

export const CardBoxLabel: React.FC<Props> = ({
  label,
  icon: Icon,
  color = 'primary',
}) => {
  if (label === null || label === undefined) {
    return null;
  }

  return (
    <Box display="flex" alignItems="center" paddingBottom="0.3rem">
      <Icon color={color} />
      <Typography
        style={{
          display: '-webkit-box',
          overflow: 'hidden',
          WebkitBoxOrient: 'vertical',
          WebkitLineClamp: 1,
        }}
        paddingLeft="0.5rem"
        variant="body2"
        color="textSecondary"
      >
        {label}
      </Typography>
    </Box>
  );
};
