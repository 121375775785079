import { useMemo } from 'react';

import { State } from 'modules';

import { bindActionCreators, useDispatch, useSelector } from 'third-party';

import { DEFAULT_PAGE_SIZE } from 'constants/common';
import {
  paymentDownloadEndpoint,
  paymentEndpoint,
  paymentResendEndpoint,
  paymentSingleEndpoint,
  paymentsExport,
  requestPaymentEndpoint,
  userPaymentDetailsEndpoint,
  userPaymentEndpoint,
} from 'constants/endpoints';

import { appSelectors } from 'selectors';

import { getPageNumberAfterLoadMore } from 'utils/helper';
import { httpMethod, simplifyBuilder } from 'utils/sra';

import {
  APIPayment,
  APIPaymentRequest,
  APIPaymentSearchFilter,
  APIPaymentSearchResult,
  APIPaymentStatus,
  APIPaymentUserRequest,
  APISortOrder,
} from 'types/api';
import { APIPaymentModel } from 'types/swaggerApi';

export type PaymentState = {
  paymentList: APIPayment[];
  payment: APIPayment | null;
  loadPaymentPending: boolean;
  loadPaymentsPending: boolean;
  createPaymentPending: boolean;
  allPaymentsLoaded: boolean;
  paymentListLoaded: boolean;
  paymentPending: boolean;
  exportPaymentsPending: boolean;
  loadUserPaymentsPending: boolean;
  loadMoreUserPaymentsPending: boolean;
  requestPaymentPending: boolean;
  filters: APIPaymentSearchFilter;
  pagination: {
    pageNumber: APIPaymentSearchResult['pageNumber'];
    pageSize: APIPaymentSearchResult['pageSize'];
  };
};

export const paymentState: PaymentState = {
  paymentList: [],
  payment: null,
  loadPaymentPending: false,
  paymentPending: false,
  exportPaymentsPending: false,
  loadUserPaymentsPending: false,
  loadMoreUserPaymentsPending: false,
  requestPaymentPending: false,
  filters: {
    searchSortOrders: [
      {
        sortField: 'createdOn',
        sortOrder: APISortOrder.DESC,
      },
    ],
  },
  loadPaymentsPending: false,
  createPaymentPending: false,
  paymentListLoaded: false,
  allPaymentsLoaded: false,
  pagination: { pageNumber: 1, pageSize: DEFAULT_PAGE_SIZE },
};

const builder = simplifyBuilder(paymentState, {});

const updatePaymentFilters = builder.createReduxAction(
  (filters: APIPaymentSearchFilter) => ({
    name: 'updatePaymentFilters',
    updater: () => ({
      filters,
    }),
  }),
);

export const loadUserPayments = builder.createServerAction(
  (organizationId: string) => ({
    name: 'loadUserPayments',
    url: userPaymentEndpoint(),
    body: (state: State) => ({
      pageNumber: 1,
      pageSize: DEFAULT_PAGE_SIZE,
      organizationIds: organizationId ? [organizationId] : undefined,
      ...state.payment.filters,
    }),
    method: httpMethod.post,
    onSuccess: (state: PaymentState, payload: APIPaymentSearchResult) => ({
      paymentList: payload.result,
      paymentListLoaded: true,
      allPaymentsLoaded: payload.result.length < payload.pageSize,
      pagination: {
        pageNumber: payload.pageNumber,
        pageSize: payload.pageSize,
      },
    }),
  }),
);

export const loadMoreUserPayments = builder.createServerAction(
  (organizationId: string) => ({
    name: 'loadMoreUserPayments',
    url: userPaymentEndpoint(),
    body: (state: State) => ({
      pageNumber: getPageNumberAfterLoadMore(
        state.payment.paymentList,
        state.payment.pagination.pageSize,
      ),
      organizationIds: organizationId ? [organizationId] : undefined,
      ...state.payment.filters,
    }),
    method: httpMethod.post,
    onSuccess: (state: PaymentState, payload: APIPaymentSearchResult) => ({
      paymentList: [...(state.paymentList || []), ...payload.result],
      paymentListLoaded: true,
      allPaymentsLoaded: payload.result.length < payload.pageSize,
      pagination: {
        pageNumber: payload.pageNumber,
        pageSize: payload.pageSize,
      },
    }),
  }),
);

export const loadPayments = builder.createServerAction(
  (organizationId: string) => ({
    name: 'loadPayments',
    url: paymentEndpoint(organizationId),
    body: (state: State) => ({
      pageNumber: 1,
      pageSize: DEFAULT_PAGE_SIZE,
      ...state.payment.filters,
    }),
    method: httpMethod.post,
    onSuccess: (state: PaymentState, payload: APIPaymentSearchResult) => ({
      paymentList: payload.result,
      paymentListLoaded: true,
      allPaymentsLoaded: payload.result.length < payload.pageSize,
      pagination: {
        pageNumber: payload.pageNumber,
        pageSize: payload.pageSize,
      },
    }),
  }),
);

export const loadMorePayments = builder.createServerAction(
  (organizationId: string) => ({
    name: 'loadMorePayments',
    url: organizationId
      ? paymentEndpoint(organizationId)
      : userPaymentEndpoint(),
    body: (state: State) => ({
      pageNumber: getPageNumberAfterLoadMore(
        state.payment.paymentList,
        state.payment.pagination.pageSize,
      ),
      ...state.payment.filters,
    }),
    method: httpMethod.post,
    onSuccess: (state: PaymentState, payload: APIPaymentSearchResult) => ({
      paymentList: [...(state.paymentList || []), ...payload.result],
      paymentListLoaded: true,
      allPaymentsLoaded: payload.result.length < payload.pageSize,
      pagination: {
        pageNumber: payload.pageNumber,
        pageSize: payload.pageSize,
      },
    }),
  }),
);

export const loadPayment = builder.createServerAction(
  (organizationId: string, paymentId: string) => ({
    name: 'loadPayment',
    url: paymentSingleEndpoint(organizationId, paymentId),
    method: httpMethod.get,
    onSuccess: (state: PaymentState, payload: APIPayment) => ({
      payment: payload,
    }),
  }),
);

export const loadUserPayment = builder.createServerAction(
  (paymentId: string) => ({
    name: 'loadUserPayment',
    url: userPaymentDetailsEndpoint(paymentId),
    method: httpMethod.get,
    onSuccess: (state: PaymentState, payload: APIPayment) => ({
      payment: payload,
    }),
  }),
);

export const deletePayment = builder.createServerAction(
  (organizationId: string, paymentId: string) => ({
    name: 'deletePayment',
    url: paymentSingleEndpoint(organizationId, paymentId),
    method: httpMethod.delete,
    onSuccess: (state: PaymentState, payload: APIPaymentModel) => {
      if (payload.status === APIPaymentStatus.Draft) {
        return {
          paymentList: state.paymentList.filter(it => it.id !== paymentId),
        };
      }

      return {
        paymentList: state.paymentList.map(it =>
          it.id === paymentId ? { ...it, status: APIPaymentStatus.Void } : it,
        ),
      };
    },
  }),
);

export const updatePayment = builder.createServerAction(
  (organizationId: string, paymentId: string, model: APIPaymentRequest) => ({
    name: 'updatePayment',
    url: paymentSingleEndpoint(organizationId, paymentId),
    method: httpMethod.post,
    body: model,
    onSuccess: (state: PaymentState, result: APIPayment) => ({
      paymentList: state.paymentList.map(payment =>
        payment.id === paymentId ? result : payment,
      ),
    }),
  }),
);

export const createPayment = builder.createServerAction(
  (organizationId: string, model: APIPaymentRequest) => ({
    name: 'createPayment',
    url: paymentEndpoint(organizationId),
    method: httpMethod.put,
    body: model,
    onSuccess: (state: PaymentState, result: APIPayment) => ({
      paymentList: [result, ...state.paymentList],
    }),
  }),
);

const clearPaymentFilters = builder.createReduxAction(() => ({
  name: 'clearPaymentFilters',
  updater: () => ({
    filters: paymentState.filters,
    pagination: paymentState.pagination,
  }),
}));

const downloadPayment = builder.createServerAction(
  (organizationId: string, paymentId: string) => ({
    name: 'downloadPayment',
    url: paymentDownloadEndpoint(organizationId, paymentId),
    method: httpMethod.get,
    // onSuccess: we don't put anything to store, just return result for typeahead
  }),
);

const clearPayment = builder.createReduxAction(() => ({
  name: 'clearPayment',
  updater: () => ({
    payment: null,
  }),
}));

const resetPaymentFilters = builder.createReduxAction(() => ({
  name: 'resetPaymentFilters',
  updater: () => ({
    filters: paymentState.filters,
    pagination: paymentState.pagination,
  }),
}));

const exportPayments = builder.createServerAction((organizationId: string) => ({
  name: 'exportPayments',
  url: paymentsExport(organizationId),
  body: (state: State) => ({
    ...state.payment.filters,
  }),
  method: httpMethod.post,
  responseReader: (response: any) => response.blob(),
}));

const requestPayment = builder.createServerAction(
  (organizationId: string, model: APIPaymentUserRequest) => ({
    name: 'requestPayment',
    url: requestPaymentEndpoint(organizationId),
    body: () => model,
    method: httpMethod.post,
    onSuccess: (state: PaymentState, payload: APIPayment) => ({
      paymentList: [payload, ...state.paymentList],
    }),
  }),
);

const resendPayment = builder.createServerAction(
  (organizationId: string, id: string) => ({
    name: 'resendPayment',
    url: paymentResendEndpoint(organizationId, id),
    method: httpMethod.post,
    onSuccess: (state: PaymentState, payload: APIPayment) => ({
      paymentList: state.paymentList.map(it =>
        it.id === payload.id ? payload : it,
      ),
    }),
  }),
);

export const usePayment = () => {
  const dispatch = useDispatch();
  const organizationId = useSelector(appSelectors.organizationId);

  return useMemo(
    () =>
      bindActionCreators(
        {
          updatePaymentFilters,
          clearPaymentFilters,
          clearPayment,
          loadUserPayment,
          resetPaymentFilters,
          requestPayment,
          resendPayment: resendPayment.bind(null, organizationId),
          loadUserPayments: loadUserPayments.bind(null, organizationId),
          loadUserMorePayments: loadMoreUserPayments.bind(null, organizationId),
          exportPayments: exportPayments.bind(null, organizationId),
          downloadPayment: downloadPayment.bind(null, organizationId),
          createPayment: createPayment.bind(null, organizationId),
          updatePayment: updatePayment.bind(null, organizationId),
          deletePayment: deletePayment.bind(null, organizationId),
          loadPayment: loadPayment.bind(null, organizationId),
          loadPayments: loadPayments.bind(null, organizationId),
          loadMorePayments: loadMorePayments.bind(null, organizationId),
        },
        dispatch,
      ),
    [dispatch, organizationId],
  );
};

export const paymentReducer = builder.getReducers();
