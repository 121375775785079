import React from 'react';

import {
  CreditCardRounded,
  PaymentsOutlined,
  WorkOutlineOutlined,
} from '@mui/icons-material';

import { i18n } from 'third-party';

import {
  organizationListUrl,
  userPayeeListUrl,
  userPaymentListUrl,
} from 'constants/url';

import { MenuType } from 'types/app';

export const UserMenu = (): MenuType[] => [
  {
    subHeader: 'Activities',
    items: [
      // {
      //   url: () => dashboardUrl(),
      //   text: 'Dashboard',
      //   icon: <BarChartRoundedIcon color="primary" fontSize="small" />,
      // },
      {
        url: () => userPaymentListUrl(),
        text: i18n.t('menu.myPayments'),
        icon: <CreditCardRounded color="primary" fontSize="small" />,
      },
      {
        url: () => userPayeeListUrl(),
        text: 'Payee',
        icon: <PaymentsOutlined color="primary" fontSize="small" />,
      },
      {
        url: () => organizationListUrl(),
        text: 'Organization',
        icon: <WorkOutlineOutlined color="primary" fontSize="small" />,
      },
    ],
  },
];
