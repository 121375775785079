import React, { useEffect } from 'react';

import { Box, Typography } from '@mui/material';
import Logo from 'images/logo-white.svg';

import {
  RouterOutlet,
  useNavigate,
  useSelector,
  useTheme,
  useTranslation,
} from 'third-party';

import { dashboardUrl } from 'constants/url';

import { appSelectors } from 'selectors';

export const AnonymsView = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  const navigate = useNavigate();
  const userInfoPending = useSelector(appSelectors.userInfoPending);
  const user = useSelector(appSelectors.user);

  useEffect(() => {
    if (!userInfoPending && user) {
      navigate(dashboardUrl());
    }
  }, [navigate, user, userInfoPending]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: { xs: 'column', md: 'row' },
        flexGrow: 1,
        height: '100%',
      }}
    >
      <Box
        sx={{
          display: { xs: 'none', md: 'flex' },
          flexDirection: 'column',
          justifyContent: 'space-between',
          minWidth: '25%',
          bgcolor: theme.custom.palette.primary900,
        }}
      >
        <Typography
          variant="h3"
          m="2rem"
          color={theme.custom.palette.whiteText}
        >
          <img src={Logo} height="35px" alt="Logo" />
        </Typography>
        <Typography
          variant="subtitle1"
          m="2rem"
          color={theme.custom.palette.whiteText}
        >
          {t('common.slogan')}
        </Typography>
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          flexGrow: 1,
          width: { xs: '90%', md: '75%' },
          margin: { xs: '1rem', md: '0' },
        }}
      >
        <RouterOutlet />
      </Box>
    </Box>
  );
};
