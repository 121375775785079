import { State } from 'modules';

export const loadPaymentsPending = (state: State) =>
  state.payment.loadPaymentsPending;
export const loadPaymentPending = (state: State) =>
  state.payment.loadPaymentPending;

export const payment = (state: State) => state.payment.payment;

export const loadMorePaymentsPending = (state: State) =>
  state.payment.loadPaymentsPending;
export const allPaymentsLoaded = (state: State) =>
  state.payment.allPaymentsLoaded;
export const paymentList = (state: State) => state.payment.paymentList;
export const paymentFilters = (state: State) => state.payment.filters;
export const paymentsListLoaded = (state: State) =>
  state.payment.paymentListLoaded;

export const createPaymentPending = (state: State) =>
  state.payment.createPaymentPending;

export const paymentPending = (state: State) => state.payment.paymentPending;
export const loadUserPaymentsPending = (state: State) =>
  state.payment.loadUserPaymentsPending;

export const loadMoreUserPaymentsPending = (state: State) =>
  state.payment.loadMoreUserPaymentsPending;

export const requestPaymentPending = (state: State) =>
  state.payment.requestPaymentPending;

export const exportPaymentsPending = (state: State) =>
  state.payment.exportPaymentsPending;
