export const getImageBase64 = async (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => {
      const base64String = reader.result as string;
      resolve(base64String);
    };

    reader.onerror = error => {
      reject(error);
    };

    reader.readAsDataURL(file);
  });
};

export const getImageBase64FromForm = async (
  formData: FormData,
  fieldName: string = 'file',
): Promise<string> => {
  const file = formData.get(fieldName) as File;

  if (!file) {
    throw new Error(`No file found in form data with field name: ${fieldName}`);
  }

  return getImageBase64(file);
};
