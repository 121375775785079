import React from 'react';

import { Chip, MenuItem } from 'components';

import { dayjs } from 'third-party';

import { DISPLAY_DATE_FORMAT, PaymentColors } from 'constants/common';

import { ListFilter } from 'components/Table/Filtering/ListFilter';
import { ColumnFilteringProps, TableColumn } from 'components/Table/types';

import { APIPayment, APIPaymentStatus } from 'types/api';

export const StatusMenu = [
  <MenuItem key={APIPaymentStatus.Draft} value={APIPaymentStatus.Draft}>
    Draft
  </MenuItem>,
  <MenuItem key={APIPaymentStatus.Requested} value={APIPaymentStatus.Requested}>
    Requested
  </MenuItem>,
  <MenuItem key={APIPaymentStatus.Created} value={APIPaymentStatus.Created}>
    Created
  </MenuItem>,
  <MenuItem key={APIPaymentStatus.Void} value={APIPaymentStatus.Void}>
    Void
  </MenuItem>,
  <MenuItem
    key={APIPaymentStatus.TryToPrint}
    value={APIPaymentStatus.TryToPrint}
  >
    Try To Print
  </MenuItem>,
  <MenuItem
    key={APIPaymentStatus.CouldNotPrint}
    value={APIPaymentStatus.CouldNotPrint}
  >
    Could Not Print
  </MenuItem>,
  <MenuItem key={APIPaymentStatus.Printed} value={APIPaymentStatus.Printed}>
    Printed
  </MenuItem>,
  <MenuItem key={APIPaymentStatus.Emailed} value={APIPaymentStatus.Emailed}>
    Emailed
  </MenuItem>,
  <MenuItem key={APIPaymentStatus.Mailed} value={APIPaymentStatus.Mailed}>
    Mailed
  </MenuItem>,
  <MenuItem key={APIPaymentStatus.Expired} value={APIPaymentStatus.Expired}>
    Expired
  </MenuItem>,
  <MenuItem key={APIPaymentStatus.Cleared} value={APIPaymentStatus.Cleared}>
    Cleared
  </MenuItem>,
];

export const PaymentStatusFilter: React.FC<ColumnFilteringProps> = props => {
  return (
    <ListFilter {...props}>
      {StatusMenu}
      <MenuItem value={undefined} />
    </ListFilter>
  );
};

export const paymentColumns: TableColumn<APIPayment>[] = [
  {
    dataId: 'id',
    label: 'paymentList.column.id',
    minWidth: 80,
    width: 140,
    hidden: true,
    sortable: true,
    resizable: true,
  },
  {
    dataId: 'status',
    label: 'paymentList.column.status',
    minWidth: 80,
    width: 140,
    hidden: false,
    sortable: true,
    resizable: true,
    customFilter: PaymentStatusFilter,
    formatter: value => ({
      value: 'test',
      element: (
        <Chip
          label={value}
          style={{
            backgroundColor: PaymentColors[value as APIPaymentStatus],
          }}
        />
      ),
    }),
  },
  {
    dataId: 'bank.bankName',
    label: 'paymentList.column.bank',
    minWidth: 80,
    width: 180,
    hidden: false,
    sortable: true,
    resizable: true,
  },
  {
    dataId: 'amount',
    label: 'paymentList.column.amount',
    minWidth: 80,
    width: 120,
    hidden: false,
    sortable: true,
    formatter: value => ({ value: '$' + value.toString() }),
    resizable: true,
  },
  {
    dataId: 'checkNumber',
    label: 'paymentList.column.checkNumber',
    minWidth: 80,
    width: 150,
    hidden: false,
    sortable: true,
    resizable: true,
  },
  {
    dataId: 'checkDate',
    label: 'paymentList.column.checkDate',
    minWidth: 80,
    width: 150,
    hidden: false,
    sortable: true,
    formatter: value => ({ value: dayjs(value).format(DISPLAY_DATE_FORMAT) }),
    resizable: true,
  },
  {
    dataId: 'note',
    label: 'paymentList.column.note',
    minWidth: 80,
    width: 150,
    hidden: false,
    sortable: true,
    resizable: true,
  },
  {
    dataId: 'memo',
    label: 'paymentList.column.memo',
    minWidth: 80,
    width: 150,
    hidden: false,
    sortable: true,
    resizable: true,
  },
  {
    dataId: 'name',
    label: 'paymentList.column.name',
    minWidth: 80,
    width: 150,
    hidden: false,
    sortable: true,
    resizable: true,
  },
  {
    dataId: 'company',
    label: 'paymentList.column.company',
    minWidth: 80,
    width: 150,
    hidden: false,
    sortable: true,
    resizable: true,
  },
  {
    dataId: 'payee.nameOnCheck',
    label: 'paymentList.column.nameOnCheck',
    minWidth: 80,
    width: 150,
    hidden: false,
    sortable: true,
    resizable: true,
  },
  {
    dataId: 'referenceId',
    label: 'paymentList.column.referenceId',
    minWidth: 80,
    width: 150,
    hidden: false,
    sortable: true,
    resizable: true,
  },
  {
    dataId: 'verifyCode',
    label: 'paymentList.column.verifyCode',
    minWidth: 80,
    width: 150,
    hidden: false,
    sortable: true,
    resizable: true,
  },
  {
    dataId: 'payee.email',
    label: 'paymentList.column.email',
    minWidth: 80,
    width: 150,
    hidden: false,
    sortable: true,
    resizable: true,
  },
  {
    dataId: 'payee.phone',
    label: 'paymentList.column.phone',
    minWidth: 80,
    width: 150,
    hidden: false,
    sortable: true,
    resizable: true,
  },
  {
    dataId: 'payee.address',
    label: 'paymentList.column.address',
    minWidth: 80,
    width: 150,
    hidden: false,
    sortable: true,
    resizable: true,
  },
  {
    dataId: 'payee.city',
    label: 'paymentList.column.city',
    minWidth: 80,
    width: 150,
    hidden: false,
    sortable: true,
    resizable: true,
  },
  {
    dataId: 'payee.state',
    label: 'paymentList.column.state',
    minWidth: 80,
    width: 80,
    hidden: false,
    sortable: true,
    resizable: true,
  },
  {
    dataId: 'payee.zip',
    label: 'paymentList.column.zip',
    minWidth: 80,
    width: 150,
    hidden: false,
    sortable: true,
    resizable: true,
  },
  {
    dataId: 'kId',
    label: 'paymentList.column.kId',
    minWidth: 80,
    width: 150,
    hidden: false,
    sortable: true,
    resizable: true,
  },
];
