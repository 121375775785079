import React from 'react';

import { MenuItem } from 'components';

import { useCache } from 'modules/cache';

import { ListFilter } from 'components/Table/Filtering/ListFilter';
import { TypeaheadFilter } from 'components/Table/Filtering/TypeaheadFilter';
import { ColumnFilteringProps, TableColumn } from 'components/Table/types';

import {
  APIAccessLevel,
  APIResponse,
  APIUser,
  APIUserOrganization,
  APIUserType,
} from 'types/api';
import { TypeaheadOption } from 'types/app';

export const UserTypeFilter: React.FC<ColumnFilteringProps> = props => {
  return (
    <ListFilter {...props}>
      <MenuItem value={APIUserType.Admin}>Admin</MenuItem>
      <MenuItem value={APIUserType.User}>User</MenuItem>
      <MenuItem value="" />
    </ListFilter>
  );
};

export const AccessLevelFilter: React.FC<ColumnFilteringProps> = props => {
  return (
    <ListFilter {...props}>
      <MenuItem value={APIAccessLevel.Admin}>Admin</MenuItem>
      <MenuItem value={APIAccessLevel.User}>User</MenuItem>
      <MenuItem value={APIAccessLevel.ReadOnly}>ReadOnly</MenuItem>
      <MenuItem value={APIAccessLevel.SuperVisor}>SuperVisor</MenuItem>
      <MenuItem value="" />
    </ListFilter>
  );
};

export const OrganizationFilter: React.FC<ColumnFilteringProps> = props => {
  const { organizationTypeahead } = useCache();

  const loadOrganizations = async (value: string) => {
    const result: APIResponse<{ name: string; id: string }[]> =
      await organizationTypeahead(value);
    let options: TypeaheadOption[] = [];
    if (!result.error) {
      options = result.payload.map(it => ({
        label: it.name,
        value: it.id,
      }));
    }
    return {
      options,
    };
  };

  return <TypeaheadFilter {...props} loadOptions={loadOrganizations} />;
};

export const userColumns = (organizationId: string): TableColumn<APIUser>[] => [
  {
    dataId: 'id',
    label: 'id',
    minWidth: 80,
    width: 140,
    hidden: true,
    sortable: true,
    resizable: true,
  },
  {
    dataId: 'firstName',
    label: 'userList.column.firstName',
    minWidth: 80,
    width: 140,
    sortable: true,
    resizable: true,
  },
  {
    dataId: 'lastName',
    label: 'userList.column.lastName',
    minWidth: 80,
    width: 140,
    sortable: true,
    resizable: true,
  },
  {
    dataId: 'email',
    label: 'userList.column.email',
    minWidth: 80,
    width: 140,
    sortable: true,
    resizable: true,
  },
  {
    dataId: 'userType',
    label: 'userList.column.userType',
    minWidth: 80,
    width: 140,
    sortable: true,
    resizable: true,
    customFilter: UserTypeFilter,
    formatter: (userType, item) => ({
      value: item.userOrganizations
        .filter(it => it.organization.id == organizationId)
        .map(it => it?.userType)
        .join(', '),
    }),
  },
  {
    dataId: 'accessLevel',
    label: 'userList.column.accessLevel',
    minWidth: 80,
    width: 140,
    sortable: true,
    resizable: true,
    customFilter: AccessLevelFilter,
    formatter: (userType, item) => ({
      value: item.userOrganizations
        .filter(it => it.organization.id == organizationId)
        .map(it => it?.accessLevel)
        .join(', '),
    }),
  },
  {
    dataId: 'userOrganizations',
    label: 'userList.column.organization',
    formatter: (value: APIUserOrganization[]) => ({
      value: value
        .filter(it => it.organization.id == organizationId)
        .map(it => it?.organization?.name)
        .join(', '),
    }),
    minWidth: 80,
    width: 140,
    sortable: false,
    resizable: true,
    customFilter: OrganizationFilter,
  },
];
