import { useMemo } from 'react';

import { bindActionCreators, useDispatch } from 'third-party';

import {
  anonymousPaymentEndpoint,
  finishPrintingEndpoint,
  verifyCheckEndpoint,
} from 'constants/endpoints';

import { httpMethod, simplifyBuilder } from 'utils/sra';

import { APIPayment } from 'types/api';

export type AnonymsState = {
  payment: APIPayment | null;
  loadAnonymsPaymentError: string | null;
  loadPaymentPending: boolean;
  verifyCheckPending: boolean;
};

export const paymentState: AnonymsState = {
  payment: null,
  loadPaymentPending: false,
  loadAnonymsPaymentError: null,
  verifyCheckPending: false,
};

const builder = simplifyBuilder(paymentState, {});

export const loadAnonymsPayment = builder.createServerAction((id: string) => ({
  name: 'loadAnonymsPayment',
  url: anonymousPaymentEndpoint(id),
  method: httpMethod.get,
  onSuccess: (state: AnonymsState, payload: APIPayment) => ({
    payment: payload,
  }),
}));

export const finishPrinting = builder.createServerAction(
  (id: string, isSuccess: boolean) => ({
    name: 'finishPrinting',
    url: finishPrintingEndpoint(id),
    method: httpMethod.post,
    body: { success: isSuccess },
    onSuccess: (state: AnonymsState, payload: APIPayment) => ({
      payment: payload,
    }),
  }),
);

export const verifyCheck = builder.createServerAction((id: string) => ({
  name: 'verifyCheck',
  url: verifyCheckEndpoint(id),
  method: httpMethod.get,
  onSuccess: (state: AnonymsState, payload: APIPayment) => ({
    payment: payload,
  }),
}));

export const resetPayment = builder.createReduxAction(() => ({
  name: 'resetPayment',
  updater: () => ({
    payment: null,
  }),
}));

export const useAnonyms = () => {
  const dispatch = useDispatch();

  return useMemo(
    () =>
      bindActionCreators(
        {
          loadAnonymsPayment,
          finishPrinting,
          verifyCheck,
          resetPayment,
        },
        dispatch,
      ),
    [dispatch],
  );
};

export const anonymsReducer = builder.getReducers();
