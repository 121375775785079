import React, { useCallback, useState } from 'react';

import { SaveOutlined } from '@mui/icons-material';
import {
  Avatar,
  AvatarPhotoEditor,
  Box,
  CircularProgress,
  FormField,
  LoadingButton,
  ModalBox,
  PageHeader,
} from 'components';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { FieldError, useForm } from 'third-party';

import { useApp } from 'modules/app';

import { appSelectors } from 'selectors';

import { getAvatarUrl } from 'utils/app';

enum FormFields {
  FirstName = 'firstName',
  LastName = 'lastName',
  Email = 'email',
  Phone = 'phone',
}

type UserFormData = {
  [FormFields.FirstName]: string;
  [FormFields.LastName]: string;
  [FormFields.Email]: string;
  [FormFields.Phone]: string;
};

export const UserProfile = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const user = useSelector(appSelectors.user);
  const updateAvatarPending = useSelector(appSelectors.updateAvatarPending);
  const updateSelfInfoPending = useSelector(appSelectors.updateSelfInfoPending);

  const isFormPending = updateSelfInfoPending;

  const { updateAvatar, updateSelfInfo } = useApp();
  const { t } = useTranslation();

  const handleAvatarSave = (formData: FormData | null) => {
    if (formData) {
      updateAvatar(formData);
    }
    setIsModalOpen(false);
  };

  const onModalClose = useCallback(() => {
    setIsModalOpen(false);
  }, []);

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      [FormFields.FirstName]: user?.firstName || '',
      [FormFields.LastName]: user?.lastName || '',
      [FormFields.Email]: user?.email || '',
      [FormFields.Phone]: user?.phone || '',
    },
  });

  const onSubmit = (data: UserFormData) => {
    if (user && user.id) {
      updateSelfInfo({
        ...user,
        firstName: data[FormFields.FirstName],
        lastName: data[FormFields.LastName],
        email: data[FormFields.Email],
        phone: data[FormFields.Phone],
      });
    }
  };

  if (updateAvatarPending || !user) {
    return (
      <Box width="15rem">
        <CircularProgress sx={{ margin: '5rem' }} />
      </Box>
    );
  }

  return (
    <Box>
      <PageHeader
        header={t('userProfile.header')}
        subHeader={t('userProfile.subHeader')}
      />
      <Box
        display="flex"
        flexDirection="row"
        gap="2rem"
        mt="2rem"
        marginLeft="0.5rem"
      >
        <Avatar
          alt="Remy Sharp"
          style={{
            cursor: 'pointer',
            width: 100,
            height: 100,
            marginTop: '1rem',
          }}
          src={getAvatarUrl(user)}
          onClick={() => setIsModalOpen(true)}
        />
        <Box maxWidth="30rem">
          <form>
            <FormField
              fieldName={FormFields.FirstName}
              fieldError={errors[FormFields.FirstName] as FieldError}
              label={t('userProfile.firstName')}
              placeholder={
                t('common.form.enterValueFor') + t('userProfile.firstName')
              }
              requiredErrorMessage={t('common.form.fieldRequired')}
              control={control}
              maxLength={200}
              isRequired
              isDisabled={isFormPending}
            />
            <FormField
              fieldName={FormFields.LastName}
              fieldError={errors[FormFields.LastName] as FieldError}
              label={t('userProfile.lastName')}
              placeholder={
                t('common.form.enterValueFor') + t('userProfile.lastName')
              }
              requiredErrorMessage={t('common.form.fieldRequired')}
              control={control}
              maxLength={200}
              isRequired
              isDisabled={isFormPending}
            />
            <FormField
              fieldName={FormFields.Email}
              fieldError={errors[FormFields.Email] as FieldError}
              label={t('userProfile.email')}
              placeholder={
                t('common.form.enterValueFor') + t('userProfile.email')
              }
              requiredErrorMessage={t('common.form.fieldRequired')}
              control={control}
              maxLength={200}
              isRequired
              isDisabled
            />
            <FormField
              fieldName={FormFields.Phone}
              fieldError={errors[FormFields.Phone] as FieldError}
              label={t('userProfile.phone')}
              placeholder={
                t('common.form.enterValueFor') + t('userProfile.phone')
              }
              requiredErrorMessage={t('common.form.fieldRequired')}
              control={control}
              maxLength={22}
              isRequired
              isDisabled={isFormPending}
            />

            <LoadingButton
              style={{ marginTop: '1rem' }}
              type="submit"
              startIcon={<SaveOutlined fontSize="small" />}
              variant="outlined"
              onClick={handleSubmit(onSubmit)}
              loading={isFormPending}
              disabled={isFormPending}
              size="medium"
              color="primary"
            >
              {t('common.form.save')}
            </LoadingButton>
          </form>
        </Box>
      </Box>
      {isModalOpen && (
        <ModalBox width="19rem" height="29rem" onClose={onModalClose}>
          <Box sx={{ padding: '1rem' }}>
            <AvatarPhotoEditor
              avatarUrl={getAvatarUrl(user)}
              onAvatarSave={handleAvatarSave}
            />
          </Box>
        </ModalBox>
      )}
    </Box>
  );
};
