import React from 'react';

import { FirstPageOutlined, LastPageOutlined } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import {
  Avatar,
  Box,
  Button,
  Menu,
  MenuItem,
  PopupState,
  bindMenu,
  bindTrigger,
} from 'components';

import {
  styled,
  useNavigate,
  useParams,
  useSelector,
  useTheme,
  useTranslation,
} from 'third-party';

import { loginUrl, userProfileUrl } from 'constants/url';

import { useApp } from 'modules/app';

import { appSelectors } from 'selectors';

import { getAvatarUrl } from 'utils/app';
import { deleteUserToken } from 'utils/auth';

const StyledFilterButton = styled(Button)`
  border-radius: 10rem;
  color: ${props => props.theme.custom.palette.blackText} !important;
  font-weight: 400 !important;
  font-size: 0.8rem !important;
  text-transform: none !important;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const UserMenu = () => {
  const theme = useTheme();
  const user = useSelector(appSelectors.user);
  const navigate = useNavigate();
  const { organizationId } = useParams();

  const { userLogOut, toggleNavigation } = useApp();
  const { t } = useTranslation();
  const isNavigationCollapsed = useSelector(appSelectors.isNavigationCollapsed);

  const onLogOutClick = async () => {
    await userLogOut();
    deleteUserToken();

    navigate(loginUrl());
  };

  const onProfileClick = () => {
    navigate(userProfileUrl(organizationId));
  };

  if (!user) {
    return null;
  }

  return (
    <Box margin="1rem 1rem 1rem 2rem" display="flex" alignItems="center">
      <PopupState variant="popover" popupId="demo-popup-menu">
        {popupState => (
          <React.Fragment>
            <Avatar
              src={getAvatarUrl(user)}
              sx={{
                width: '1.6rem',
                height: '1.6rem',
                fontSize: '0.6rem',
                bgcolor: theme.custom.palette.primary800,
                cursor: 'pointer',
                textTransform: 'uppercase',
              }}
              {...bindTrigger(popupState)}
              sizes="small"
            />

            {!isNavigationCollapsed && (
              <StyledFilterButton variant="text" {...bindTrigger(popupState)}>
                {user?.firstName} {user?.lastName}
              </StyledFilterButton>
            )}
            <IconButton
              sx={{ display: 'none' }}
              onClick={() => toggleNavigation()}
            >
              {!isNavigationCollapsed ? (
                <FirstPageOutlined fontSize="small" />
              ) : (
                <LastPageOutlined fontSize="small" />
              )}
            </IconButton>
            <Menu {...bindMenu(popupState)}>
              <MenuItem
                onClick={() => {
                  popupState.close();
                  onLogOutClick();
                }}
              >
                {t('menu.logOut')}
              </MenuItem>
              <MenuItem
                onClick={() => {
                  popupState.close();
                  onProfileClick();
                }}
              >
                {t('menu.profile')}
              </MenuItem>
            </Menu>
          </React.Fragment>
        )}
      </PopupState>
    </Box>
  );
};
