import React from 'react';

import {
  Link,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from 'components';
import { useIsMobile } from 'hooks';

import { useLocation } from 'third-party';

import { NavigationItems } from 'types/app';

type Props = {
  item: NavigationItems;
  isCollapsed: boolean;
};

export const NavigationLink: React.FC<Props> = ({ item, isCollapsed }) => {
  const { pathname } = useLocation();
  const isMobile = useIsMobile();

  return (
    <Link href={item.url?.() || '#'} underline="none">
      <ListItem disablePadding>
        <ListItemButton
          selected={pathname == item.url?.()}
          sx={{
            padding: isMobile ? '0.5rem 0.75rem' : undefined,
            borderRadius: isMobile ? '8px' : undefined,
          }}
        >
          {item.icon && (
            <ListItemIcon
              sx={{
                minWidth: isMobile ? '40px' : '56px',
                color: pathname == item.url?.() ? 'primary.main' : undefined,
              }}
            >
              {item.icon}
            </ListItemIcon>
          )}
          {!isCollapsed && (
            <ListItemText
              primary={item.text}
              style={{
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
              }}
              primaryTypographyProps={{
                fontSize: isMobile ? '0.9rem' : undefined,
              }}
            />
          )}
        </ListItemButton>
      </ListItem>
    </Link>
  );
};
