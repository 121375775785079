import React, { useCallback, useEffect, useRef, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';

import {
  Box,
  Button,
  CardBox,
  CircularProgress,
  DialogModal,
  Loader,
  MenuItem,
  ModalBox,
  PageHeader,
  TextField,
} from 'components';

import { OrganizationCreateEditForm } from './OrganizationCreateEditForm';

import {
  _,
  styled,
  useNavigate,
  useSelector,
  useTranslation,
} from 'third-party';

import { USER_INPUT_DEBOUNCE_TIME_MS } from 'constants/common';
import { organizationDashboardUrl } from 'constants/url';

import { useApp } from 'modules/app';
import { useOrganization } from 'modules/organization';

import { appSelectors, organizationSelectors } from 'selectors';

import { getOrganizationAvatarUrl } from 'utils/app';

import { APIOrganization, APIUserType } from 'types/api';

const StyleInput = styled(TextField)`
  .MuiInput-input {
    padding: 0.4rem !important;
    min-width: 18rem;
  }
`;

export const OrganizationList = () => {
  const { t } = useTranslation();
  const [isFilteringOn, setIsFilteringOn] = useState(false);
  const [selectedItem, setSelectedItem] = useState<APIOrganization | null>(
    null,
  );

  const { userInfo, refreshTokens } = useApp();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const ref = useRef<HTMLInputElement>(null);
  const userType = useSelector(appSelectors.userType);
  const items = useSelector(organizationSelectors.organizationList);
  const allOrganizationLoaded = useSelector(
    organizationSelectors.allOrganizationsLoaded,
  );
  const organizationFilters = useSelector(
    organizationSelectors.organizationFilters,
  );
  const loadOrganizationsPending = useSelector(
    organizationSelectors.loadMoreOrganizationsPending,
  );
  const loadMoreOrganizationPending = useSelector(
    organizationSelectors.loadMoreOrganizationsPending,
  );

  const {
    deleteOrganization,
    loadOrganizations,
    loadMoreOrganizations,
    updateOrganizationFilters,
    resetOrganizationFilters,
  } = useOrganization();

  useEffect(() => {
    loadOrganizations();
  }, [organizationFilters, loadOrganizations]);

  const organizations = useSelector(appSelectors.organizations);
  const navigate = useNavigate();

  const onCardClick = useCallback(
    (item: APIOrganization) => {
      navigate(organizationDashboardUrl(item.id));
    },
    [navigate],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onSearchInputChange = useCallback(
    _.debounce((event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target) {
        updateOrganizationFilters({
          ...organizationFilters,
          name: event.target.value,
        });
      }
    }, USER_INPUT_DEBOUNCE_TIME_MS),
    [updateOrganizationFilters, organizationFilters],
  );

  const cardMenuItems = (item: APIOrganization, handleClose: () => void) => {
    const userOrganization = organizations?.find(
      it => it.organization.id == item.id,
    );

    if (
      userType != APIUserType.Admin &&
      (!userOrganization || userOrganization.userType != APIUserType.Admin)
    ) {
      return undefined;
    }

    return [
      <MenuItem
        key="Edit"
        onClick={() => {
          setSelectedItem(item);
          setIsModalOpen(true);
          handleClose();
        }}
      >
        <EditOutlinedIcon />
        {t('organizationList.menuItem.edit')}
      </MenuItem>,
      <MenuItem
        key="Remove"
        onClick={() => {
          setSelectedItem(item);
          handleClose();
        }}
      >
        <DeleteOutlineOutlinedIcon />
        {t('organizationList.menuItem.remove')}
      </MenuItem>,
    ];
  };

  const handleScroll = useCallback(
    (event: React.UIEvent<HTMLElement>) => {
      const target = event.target as HTMLElement;
      const bottom =
        Math.abs(target.scrollHeight - target.scrollTop - target.clientHeight) <
        1;

      if (bottom && !loadMoreOrganizationPending && !allOrganizationLoaded) {
        loadMoreOrganizations();
      }
    },
    [allOrganizationLoaded, loadMoreOrganizationPending, loadMoreOrganizations],
  );

  const onFilterReset = useCallback(() => {
    resetOrganizationFilters();
    if (ref.current) {
      ref.current.value = '';
    }
  }, [resetOrganizationFilters]);

  const getSubTitle = useCallback(
    (item: APIOrganization) => {
      const userOrganization = organizations?.find(
        it => it.organization.id == item.id,
      );
      if (!userOrganization) {
        return t('organizationList.userRole.notInTheOrganization');
      }

      return userOrganization.userType == APIUserType.Admin
        ? t('organizationList.userRole.admin')
        : t('organizationList.userRole.user');
    },
    [organizations, t],
  );

  const onAddOrganizationClick = useCallback(() => {
    setIsModalOpen(true);
  }, []);

  const onModalClose = useCallback(() => {
    userInfo();
    refreshTokens();
    setIsModalOpen(false);
    setSelectedItem(null);
  }, [refreshTokens, userInfo]);

  return (
    <Box width="100%" overflow="auto" onScroll={handleScroll}>
      <Box
        position="sticky"
        top={0}
        zIndex={11}
        bgcolor="white"
        alignItems="center"
      >
        <PageHeader
          header={t('organizationList.header')}
          subHeader={t('organizationList.subHeader')}
        >
          <>
            {isFilteringOn && (
              <Button variant="text" onClick={onFilterReset}>
                <ClearIcon />
              </Button>
            )}
            <Button variant="text" onClick={onAddOrganizationClick}>
              <AddIcon />
            </Button>
            <Button variant="text" onClick={() => setIsFilteringOn(it => !it)}>
              {isFilteringOn ? <FilterAltIcon /> : <FilterAltOffIcon />}
            </Button>
          </>
        </PageHeader>

        {isFilteringOn && (
          <Box padding="1rem 1rem 0 0 ">
            <SearchOutlinedIcon color="primary" />
            <StyleInput
              inputRef={ref}
              autoComplete="off"
              autoCorrect="off"
              id="input-with-sx"
              onChange={onSearchInputChange}
              variant="standard"
            />
          </Box>
        )}
      </Box>
      {loadOrganizationsPending && <Loader />}
      {!loadOrganizationsPending && (
        <Box
          paddingTop="1rem"
          display="flex"
          flexWrap="wrap"
          gap="1.5rem"
          sx={{ overflow: 'hidden' }}
        >
          {items?.map(it => (
            <CardBox<APIOrganization>
              item={it}
              key={it.id}
              title={it.name}
              avatarUrl={getOrganizationAvatarUrl(it)}
              width="17rem"
              height="13rem"
              subTitle={getSubTitle(it)}
              description={it.description}
              descriptionMaxLines={3}
              onClick={onCardClick}
              menuItems={cardMenuItems}
            />
          ))}
        </Box>
      )}
      {loadMoreOrganizationPending && (
        <Box display="flex" justifyContent="space-around" padding="1rem">
          <CircularProgress />
        </Box>
      )}
      {isModalOpen && (
        <ModalBox width="40rem" height="35rem" onClose={onModalClose}>
          <OrganizationCreateEditForm
            onModalClose={onModalClose}
            organizationId={selectedItem?.id}
          />
        </ModalBox>
      )}
      <DialogModal
        onClose={() => setSelectedItem(null)}
        open={!!selectedItem && !isModalOpen}
        description={t('organizationList.deleteConfirm')}
        handleActionClick={() => {
          if (selectedItem) {
            deleteOrganization(selectedItem.id);
          }
          setSelectedItem(null);
        }}
      />
    </Box>
  );
};
