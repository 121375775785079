import React, { useCallback, useEffect, useRef, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import PhoneAndroidOutlinedIcon from '@mui/icons-material/PhoneAndroidOutlined';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';

import { ArchiveOutlined, UnarchiveOutlined } from '@mui/icons-material';
import {
  Box,
  Button,
  CardBox,
  CardBoxLabel,
  Chip,
  CircularProgress,
  EmptyState,
  Loader,
  MenuItem,
  ModalBox,
  PageHeader,
  TextField,
} from 'components';

import { PayeeCreateEditForm } from './PayeeCreateEditForm';

import { _, styled, useSelector, useTranslation } from 'third-party';

import {
  PayeeColors,
  PayeeStatus,
  USER_INPUT_DEBOUNCE_TIME_MS,
} from 'constants/common';

import { usePayee } from 'modules/payee';

import { payeeSelectors } from 'selectors';

import { APIPayee, APIPayeeStatus } from 'types/api';

const StyleInput = styled(TextField)`
  .MuiInput-input {
    padding: 0.4rem !important;
    min-width: 18rem;
  }
`;

const cardActions = (item: APIPayee) => {
  return (
    <Box
      display="flex"
      width="100%"
      alignItems="center"
      justifyContent="space-between"
    >
      <Chip
        label={PayeeStatus[item.status as APIPayeeStatus]}
        style={{
          height: '1.5rem',
          padding: '0rem',
          marginLeft: '0.3rem',
          marginTop: '0.2rem',
          backgroundColor: PayeeColors[item.status as APIPayeeStatus],
        }}
      />
    </Box>
  );
};

const payeeIconColor = (payee: APIPayee) => {
  return payee.status == APIPayeeStatus.Active ? 'primary' : 'disabled';
};

export const UserPayeeList = () => {
  const { t } = useTranslation();
  const [isFilteringOn, setIsFilteringOn] = useState(false);
  const [selectedItem, setSelectedItem] = useState<APIPayee | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const ref = useRef<HTMLInputElement>(null);
  const items = useSelector(payeeSelectors.payeeList);
  const allPayeeLoaded = useSelector(payeeSelectors.allPayeesLoaded);
  const payeeFilters = useSelector(payeeSelectors.payeeFilters);
  const loadUserPayeesPending = useSelector(
    payeeSelectors.loadUserPayeesPending,
  );
  const loadMoreUserPayeesPending = useSelector(
    payeeSelectors.loadMoreUserPayeesPending,
  );

  const {
    updatePayee,
    clearPayeeList,
    loadUserPayees,
    loadMoreUserPayees,
    updatePayeeFilters,
    resetPayeeFilters,
  } = usePayee();

  useEffect(() => {
    loadUserPayees();
  }, [payeeFilters, loadUserPayees]);

  useEffect(() => {
    return () => {
      clearPayeeList();
    };
  }, [clearPayeeList]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onSearchInputChange = useCallback(
    _.debounce((event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target) {
        updatePayeeFilters({
          ...payeeFilters,
          nameOnCheck: event.target.value,
        });
      }
    }, USER_INPUT_DEBOUNCE_TIME_MS),
    [updatePayeeFilters, payeeFilters],
  );

  const cardMenuItems = (item: APIPayee, handleClose: () => void) => {
    return [
      <MenuItem
        key="Edit"
        onClick={() => {
          setSelectedItem(item);
          setIsModalOpen(true);
          handleClose();
        }}
      >
        <EditOutlinedIcon />
        {t('payeeList.menuItem.edit')}
      </MenuItem>,
      <MenuItem
        key="Remove"
        disabled={
          item.status !== APIPayeeStatus.Archive &&
          item.status !== APIPayeeStatus.Active
        }
        onClick={() => {
          updatePayee(item.organization.id, item.id, {
            ...item,
            userId: item.user.id,
            status:
              item.status == APIPayeeStatus.Archive
                ? APIPayeeStatus.Active
                : APIPayeeStatus.Archive,
          });
        }}
      >
        {item.status == APIPayeeStatus.Archive ? (
          <ArchiveOutlined />
        ) : (
          <UnarchiveOutlined />
        )}

        {item.status == APIPayeeStatus.Archive
          ? t('common.activate')
          : t('common.archive')}
      </MenuItem>,
    ];
  };

  const handleScroll = useCallback(
    (event: React.UIEvent<HTMLElement>) => {
      const target = event.target as HTMLElement;
      const bottom =
        Math.abs(target.scrollHeight - target.scrollTop - target.clientHeight) <
        1;

      if (bottom && !loadMoreUserPayeesPending && !allPayeeLoaded) {
        loadMoreUserPayees();
      }
    },
    [allPayeeLoaded, loadMoreUserPayeesPending, loadMoreUserPayees],
  );

  const onFilterReset = useCallback(() => {
    resetPayeeFilters();
    if (ref.current) {
      ref.current.value = '';
    }
  }, [resetPayeeFilters]);

  const onAddPayeeClick = useCallback(() => {
    setIsModalOpen(true);
  }, []);

  const onModalClose = useCallback(() => {
    setIsModalOpen(false);
    setSelectedItem(null);
  }, []);

  return (
    <Box width="100%" overflow="auto" onScroll={handleScroll}>
      <Box
        position="sticky"
        top={0}
        zIndex={11}
        bgcolor="white"
        alignItems="center"
      >
        <PageHeader
          header={t('payeeList.header')}
          subHeader={t('payeeList.subHeader')}
        >
          <>
            {isFilteringOn && (
              <Button variant="text" onClick={onFilterReset}>
                <ClearIcon />
              </Button>
            )}
            <Button variant="text" onClick={onAddPayeeClick}>
              <AddIcon />
            </Button>
            <Button variant="text" onClick={() => setIsFilteringOn(it => !it)}>
              {isFilteringOn ? <FilterAltIcon /> : <FilterAltOffIcon />}
            </Button>
          </>
        </PageHeader>

        {isFilteringOn && (
          <Box padding="1rem 1rem 0 0 ">
            <SearchOutlinedIcon color="primary" />
            <StyleInput
              inputRef={ref}
              autoComplete="off"
              autoCorrect="off"
              id="input-with-sx"
              onChange={onSearchInputChange}
              variant="standard"
            />
          </Box>
        )}
      </Box>
      {loadUserPayeesPending && <Loader />}
      {!loadUserPayeesPending && (
        <Box
          paddingTop="1rem"
          display="flex"
          flexWrap="wrap"
          gap="1.5rem"
          sx={{
            overflow: 'hidden',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {items?.map(it => (
            <CardBox<APIPayee>
              item={it}
              key={it.id}
              width="17rem"
              height="15rem"
              showAvatar={false}
              title={it.nameOnCheck}
              subTitle={it.organization.name}
              menuItems={cardMenuItems}
              cardActions={cardActions}
            >
              <CardBoxLabel
                color={payeeIconColor(it)}
                label={`${it.city}, ${it.address}, ${it.zip}`}
                icon={HomeOutlinedIcon}
              />

              <CardBoxLabel
                color={payeeIconColor(it)}
                label={it.phone}
                icon={PhoneAndroidOutlinedIcon}
              />
              <CardBoxLabel
                color={payeeIconColor(it)}
                label={it.email}
                icon={EmailOutlinedIcon}
              />
              <CardBoxLabel
                color={payeeIconColor(it)}
                label={it.nameOnCheck}
                icon={PersonOutlineOutlinedIcon}
              />
            </CardBox>
          ))}
          {!items?.length && (
            <EmptyState
              showResetFilterButton={isFilteringOn}
              onFilterReset={onFilterReset}
            />
          )}
        </Box>
      )}
      {loadMoreUserPayeesPending && (
        <Box display="flex" justifyContent="space-around" padding="1rem">
          <CircularProgress />
        </Box>
      )}
      {isModalOpen && (
        <ModalBox width="30rem" height="39rem" onClose={onModalClose}>
          <PayeeCreateEditForm
            onModalClose={onModalClose}
            payeeId={selectedItem?.id}
          />
        </ModalBox>
      )}
    </Box>
  );
};
