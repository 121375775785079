import React, { useEffect, useState } from 'react';

import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';

import {
  CheckCircleOutlineOutlined,
  ForwardToInboxOutlined,
} from '@mui/icons-material';
import { Box, Button, Container, IconButton, Typography } from 'components';
import { useNotifications } from 'hooks';

import {
  styled,
  useForm,
  useNavigate,
  useSelector,
  useTranslation,
} from 'third-party';

import { dashboardUrl, loginUrl } from 'constants/url';

import { useApp } from 'modules/app';

import { appSelectors } from 'selectors';

import { FormField } from 'components/FormField/FormField';

import { emailField } from 'utils/validations';

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

enum FormFields {
  Email = 'email',
}

const forgotPasswordFormDefaultValues = {
  [FormFields.Email]: '',
};

type FormData = typeof forgotPasswordFormDefaultValues;

export const UserForgotPassword = () => {
  const { t } = useTranslation();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<typeof forgotPasswordFormDefaultValues>({
    defaultValues: forgotPasswordFormDefaultValues,
  });

  const navigate = useNavigate();
  const { userSendPasswordResetLink } = useApp();
  const { error } = useNotifications();
  const userInfoPending = useSelector(appSelectors.userInfoPending);
  const user = useSelector(appSelectors.user);

  const [isResetLinkSent, setIsResetLinkSent] = useState(false);

  useEffect(() => {
    if (!userInfoPending && user) {
      navigate(dashboardUrl());
    }
  }, [navigate, user, userInfoPending]);

  const onSubmit = async ({ email }: FormData) => {
    const result = await userSendPasswordResetLink(email);

    if (result.error) {
      error(result.payload.body?.title || t('forgotPassword.genericError'));
      return;
    }

    setIsResetLinkSent(true);
  };

  const backToLogin = () => {
    navigate(loginUrl());
  };

  if (isResetLinkSent) {
    return (
      <Container maxWidth="sm">
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            minHeight: '100vh',
            textAlign: 'center',
            gap: 3,
          }}
        >
          <CheckCircleOutlineOutlined
            sx={{ fontSize: 100, color: 'primary.main' }}
          />

          <Typography
            variant="h2"
            sx={{
              textAlign: 'center',
              width: '100%',
            }}
          >
            {t('forgotPassword.emailSent.header')}
          </Typography>
          <Box>
            <Typography variant="h2" sx={{ textAlign: 'center' }}>
              {t('forgotPassword.emailSent.subHeader')}
            </Typography>
          </Box>

          <Button
            variant="contained"
            size="large"
            onClick={backToLogin}
            sx={{ mt: 2 }}
          >
            {t('common.navigation.backToLogin')}
          </Button>
        </Box>
      </Container>
    );
  }

  return (
    <Box>
      <>
        <Box
          sx={{
            width: '100%',
            position: 'relative',
            paddingBottom: '0.3rem',
          }}
        >
          <IconButton
            onClick={backToLogin}
            sx={{
              position: 'absolute',
              left: '1.5rem',
              top: '50%',
              transform: 'translateY(-50%)',
            }}
          >
            <ArrowBackOutlinedIcon color="primary" fontSize="small" />
          </IconButton>

          <Typography
            variant="h2"
            sx={{
              textAlign: 'center',
              width: '100%',
            }}
          >
            {t('forgotPassword.header')}
          </Typography>
        </Box>

        <Box sx={{ paddingBottom: '1rem', textAlign: 'center' }}>
          <Typography variant="subtitle1" sx={{ paddingTop: '0.8rem' }}>
            {t('forgotPassword.subHeader')}
          </Typography>
        </Box>
      </>

      <Form onSubmit={handleSubmit(onSubmit)}>
        <FormField
          fieldName={FormFields.Email}
          label={t('forgotPassword.fields.email')}
          fieldError={errors[FormFields.Email]}
          placeholder={
            t('common.form.enterValueFor') + t('forgotPassword.fields.email')
          }
          requiredErrorMessage={t('errors.fields.emptyFieldError')}
          control={control}
          maxLength={emailField.maxLength}
          isRequired
          showIsRequiredMark={false}
          rules={{
            validate: {
              invalidEmail: (value: string) =>
                emailField.validate(value) ||
                t('common.form.email.error.invalid'),
            },
          }}
        />
        <Box
          display="flex"
          flexDirection="column"
          paddingTop="1rem"
          gap="1.7rem"
        >
          <Button
            type="submit"
            variant="outlined"
            size="medium"
            color="primary"
          >
            <ForwardToInboxOutlined />
            {t('forgotPassword.sendResetEmail')}
          </Button>
        </Box>
      </Form>
    </Box>
  );
};
