import { State } from 'modules';

export const loadPaymentPending = (state: State) =>
  state.anonyms.loadPaymentPending;

export const payment = (state: State) => state.anonyms.payment;
export const loadAnonymsPaymentError = (state: State) =>
  state.anonyms.loadAnonymsPaymentError;

export const verifyCheckPending = (state: State) =>
  state.anonyms.verifyCheckPending;
