import React from 'react';

import { Box } from 'components';
import { useIsMobile } from 'hooks';

import { Navigation } from '../Navigation/Navigation';
import { UserMenu } from '../Navigation/UserMenu';

import { RouterOutlet, useSelector, useTheme } from 'third-party';

import { appSelectors } from 'selectors';

export const MainView = () => {
  const isNavigationCollapsed = useSelector(appSelectors.isNavigationCollapsed);
  const theme = useTheme();
  const isMobile = useIsMobile();

  return (
    <>
      <Box
        sx={{
          display: isMobile ? 'none' : 'flex',
          flexDirection: 'column',
          borderRight: `1px solid ${theme.custom.palette.borderColor}`,
          transition: 'width 0.3s ease-in-out',
          width: isNavigationCollapsed ? '5.5rem' : '14rem',
        }}
      >
        <Navigation />
        <UserMenu />
      </Box>
      {isMobile && <Navigation />}
      <Box
        sx={{
          display: 'flex',
          overflow: 'auto',
          flexDirection: 'column',
          flexGrow: 1,
          margin: isMobile ? '4rem 0.5rem 0.5rem 0' : '1rem',
          width: isMobile ? '100%' : 'auto',
          padding: isMobile ? '0 1rem' : '0',
        }}
      >
        <RouterOutlet />
      </Box>
    </>
  );
};
