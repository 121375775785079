import React, { useEffect } from 'react';

import {
  AvatarPhotoEditor,
  Box,
  Button,
  FormField,
  Loader,
  PageHeader,
} from 'components';
import { useNotifications } from 'hooks';

import { FieldError, useForm, useSelector, useTranslation } from 'third-party';

import { useOrganization } from 'modules/organization';

import { organizationSelectors } from 'selectors';

import { getOrganizationAvatarUrl } from 'utils/app';

import { APIOrganization } from 'types/api';

enum FormFields {
  Name = 'name',
  Description = 'description',
}

type OrganizationFormData = {
  [FormFields.Name]: string;
  [FormFields.Description]: string;
};

type CreateEditProps = {
  onModalClose: () => void;
  organizationId?: string | null;
};

export const OrganizationCreateEditForm: React.FC<CreateEditProps> = ({
  onModalClose,
  organizationId,
}) => {
  const { loadOrganization, clearOrganization } = useOrganization();
  const organization = useSelector(organizationSelectors.organization);
  const isEditMode = !!organizationId;

  useEffect(() => {
    if (organizationId) {
      loadOrganization(organizationId);
    }
    return () => {
      clearOrganization();
    };
  }, [organizationId, loadOrganization, clearOrganization]);

  if (isEditMode && !organization) {
    return <Loader />;
  }

  return (
    <CreateEditForm
      organization={organization}
      isEditMode={isEditMode}
      onModalClose={onModalClose}
    />
  );
};

type Props = {
  organization: APIOrganization | null;
  onModalClose: () => void;
  isEditMode: boolean;
};

export const CreateEditForm: React.FC<Props> = ({
  organization,
  isEditMode,
  onModalClose,
}) => {
  const avatarEditorRef =
    React.useRef<React.ElementRef<typeof AvatarPhotoEditor>>(null);
  const { error, success } = useNotifications();
  const { updateOrganization, createOrganization } = useOrganization();
  const { t } = useTranslation();

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<OrganizationFormData>({
    mode: 'onChange',
    defaultValues: {
      [FormFields.Name]: organization?.name || '',
      [FormFields.Description]: organization?.description || '',
    },
  });

  const isFormPending = false;

  const handleCancel = () => {
    onModalClose();
  };

  const onSubmit = async (formData: OrganizationFormData) => {
    const avatarFormData = await avatarEditorRef.current?.getAvatarImage();
    if (isEditMode && organization) {
      const submitFormData = new FormData();

      if (avatarFormData) {
        const fileEntry = Array.from(avatarFormData.entries())[0];
        if (fileEntry && fileEntry[1] instanceof Blob) {
          submitFormData.append('file', fileEntry[1], 'profile-picture.png');
        }
      }

      submitFormData.append('name', formData.name);
      submitFormData.append('description', formData.description || '');
      submitFormData.append('id', organization.id);

      const result = await updateOrganization(organization.id, submitFormData);

      if (result.error) {
        error(result.payload.body?.title || t('organizationForm.edit.error'));
      } else {
        success(t('organizationForm.edit.sucscefull'));
        onModalClose();
      }
      return;
    }

    const submitFormData = new FormData();

    if (avatarFormData) {
      const fileEntry = Array.from(avatarFormData.entries())[0];
      if (fileEntry && fileEntry[1] instanceof Blob) {
        submitFormData.append('file', fileEntry[1], 'profile-picture.png');
      }
    }

    submitFormData.append('name', formData.name);
    submitFormData.append('description', formData.description || '');

    const result = await createOrganization(submitFormData);

    if (result.error) {
      error(result.payload.body?.title || t('organizationForm.create.error'));
    } else {
      success(t('organizationForm.create.sucscefull'));
      onModalClose();
    }
  };

  return (
    <Box padding="1rem  1rem  0 1rem">
      <PageHeader
        header={
          isEditMode
            ? t('organizationForm.edit.header')
            : t('organizationForm.create.header')
        }
        subHeader={
          isEditMode
            ? t('organizationForm.edit.subHeader')
            : t('organizationForm.create.subHeader')
        }
      >
        <Box display="flex" columnGap="1rem">
          <Button
            type="submit"
            variant="outlined"
            onClick={handleSubmit(onSubmit)}
            size="medium"
            color="primary"
          >
            {t('common.form.save')}
          </Button>
          <Button
            type="button"
            variant="outlined"
            onClick={handleCancel}
            size="medium"
            color="error"
          >
            {t('common.form.cancel')}
          </Button>
        </Box>
      </PageHeader>
      <Box display="flex" flexDirection="row" gap="2rem">
        <Box sx={{ padding: '1rem' }}>
          <AvatarPhotoEditor
            ref={avatarEditorRef}
            //@ts-ignore
            avatarUrl={getOrganizationAvatarUrl(organization)}
            showSaveButton={false}
          />
        </Box>
        <form>
          <FormField
            fieldName={FormFields.Name}
            fieldError={errors[FormFields.Name] as FieldError}
            label={t('organizationList.column.name')}
            placeholder={
              t('common.form.enterValueFor') + t('organizationList.column.name')
            }
            requiredErrorMessage={t('common.form.fieldRequired')}
            control={control}
            maxLength={150}
            isRequired
            isDisabled={isFormPending}
          />
          <FormField
            fieldName={FormFields.Description}
            fieldError={errors[FormFields.Description] as FieldError}
            label={t('organizationList.column.description')}
            placeholder={
              t('common.form.enterValueFor') +
              t('organizationList.column.description')
            }
            control={control}
            maxLength={250}
            isRequired={false}
            isDisabled={isFormPending}
          />
        </form>
      </Box>
    </Box>
  );
};
