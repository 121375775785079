import React, { useState } from 'react';

import MenuIcon from '@mui/icons-material/Menu';

import {
  AppBar,
  Box,
  Drawer,
  IconButton,
  NavigationMenu,
  Toolbar,
} from 'components';
import { useIsMobile } from 'hooks';
import logo from 'images/logo-black.svg';
import logoSmall from 'images/logo-small.svg';
import logoWhite from 'images/logo-white.svg';

import { useMenu } from './Menu/useMenu';
import { UserMenu } from './UserMenu';

import { useNavigate, useSelector } from 'third-party';

import { dashboardUrl } from 'constants/url';

import { appSelectors } from 'selectors';

export const Navigation = () => {
  const menu = useMenu();
  const navigate = useNavigate();
  const isNavigationCollapsed = useSelector(appSelectors.isNavigationCollapsed);
  const isMobile = useIsMobile();
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const onLogoClick = () => {
    navigate(dashboardUrl());
  };

  const navigationContent = (
    <Box display="flex" flexDirection="column" height="100%">
      <Box
        margin={isMobile ? '1rem' : '1.5rem 2rem 0.8rem 1rem'}
        alignContent="center"
        justifyContent="center"
        display="flex"
        sx={{ cursor: 'pointer' }}
        onClick={onLogoClick}
      >
        {isNavigationCollapsed && !isMobile ? (
          <img src={logoSmall} height="33px" style={{ paddingLeft: '1rem' }} />
        ) : (
          <img
            src={isMobile ? logoWhite : logo}
            height={isMobile ? '28px' : '33px'}
          />
        )}
      </Box>
      <Box display="flex" overflow="auto" flexDirection="column" flexGrow="1">
        <Box>
          {menu.map((it, index) => (
            <NavigationMenu
              key={index}
              menu={it}
              isCollapsed={isMobile ? false : isNavigationCollapsed}
            />
          ))}
        </Box>
      </Box>
      {isMobile && (
        <Box mt="auto">
          <UserMenu />
        </Box>
      )}
    </Box>
  );

  if (isMobile) {
    return (
      <>
        <AppBar
          position="fixed"
          sx={{ zIndex: theme => theme.zIndex.drawer + 1 }}
        >
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2 }}
            >
              <MenuIcon />
            </IconButton>
            <Box
              sx={{ flexGrow: 1, display: 'flex', justifyContent: 'center' }}
            >
              <img
                src={logoWhite}
                height="28px"
                onClick={onLogoClick}
                style={{ cursor: 'pointer' }}
              />
            </Box>
          </Toolbar>
        </AppBar>
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            display: { xs: 'block', md: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: 240 },
          }}
        >
          {navigationContent}
        </Drawer>
      </>
    );
  }

  return navigationContent;
};
