import React, { useEffect, useState } from 'react';

import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';

import { Box, Link, Loader, PaymentInfo, Typography } from 'components';
import { Trans } from 'react-i18next';

import {
  useNavigate,
  useParams,
  useSelector,
  useTheme,
  useTranslation,
} from 'third-party';

import { verifyCheckUrl } from 'constants/url';

import { useAnonyms } from 'modules/anonyms';

import { anonymsSelectors } from 'selectors';

type Props = {
  title: string;
  value: string | null;
};
export const CheckItem: React.FC<Props> = ({ title, value }) => {
  return (
    <>
      <Typography variant="h3" gutterBottom>
        {title}
      </Typography>
      <Typography variant="h4" gutterBottom>
        {value}
      </Typography>
    </>
  );
};

export const VerifyCheckResult = () => {
  const { t } = useTranslation();
  const { code } = useParams();
  const [error, setError] = useState(false);

  const theme = useTheme();
  const navigate = useNavigate();
  const { verifyCheck, resetPayment } = useAnonyms();

  const verifyCheckPending = useSelector(anonymsSelectors.verifyCheckPending);
  const payment = useSelector(anonymsSelectors.payment);

  useEffect(() => {
    if (code) {
      setError(false);
      verifyCheck(code).then(it => {
        if (it.error) {
          setError(true);
        }
      });
    }
    return () => {
      resetPayment();
    };
  }, [code, navigate, resetPayment, verifyCheck]);

  return (
    <>
      <Box display="flex" flexDirection="column" alignItems="center">
        {code && verifyCheckPending && <Loader />}

        {code && !verifyCheckPending && !error && payment && (
          <PaymentInfo payment={payment} />
        )}

        {code && !verifyCheckPending && error && (
          <Box display="flex" flexDirection="column" alignItems="center">
            <ErrorOutlineOutlinedIcon
              sx={{ fontSize: 100, color: theme.custom.palette.danger600 }}
            />

            <Typography
              variant="h1"
              color={theme.custom.palette.danger600}
              gutterBottom
            >
              {t('verifyCheck.checkValid')}
            </Typography>

            <Typography variant="h3" color="text.secondary">
              <Trans
                i18nKey="verifyCheck.checkValidText"
                /** @ts-ignore */
                components={{ code }}
              />
            </Typography>
            <Link
              component="button"
              variant="body2"
              onClick={() => {
                setError(false);
                navigate(verifyCheckUrl());
              }}
            >
              {t('verifyCheck.returnBack')}
            </Link>
          </Box>
        )}
      </Box>
    </>
  );
};
