import React, {
  ChangeEvent,
  MouseEventHandler,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import {
  FolderOpenOutlined,
  RotateLeftOutlined,
  RotateRightOutlined,
  SaveOutlined,
} from '@mui/icons-material';
import { Box, IconButton, Slider, Tooltip, Typography } from 'components';

import { AvatarEditor, Dropzone, useTranslation } from 'third-party';

import { TOOLTIP_APPEAR_DELAY } from 'constants/common';

import { convertUrlToFile } from 'utils/app';

type State = {
  image: string | File;
  allowZoomOut: boolean;
  position: AvatarEditor.Position;
  scale: number;
  rotate: number;
  borderRadius: number;
  preview?: {
    img: string;
    rect: {
      x: number;
      y: number;
      width: number;
      height: number;
    };
    scale: number;
    width: number;
    height: number;
    borderRadius: number;
  };
  width: number;
  height: number;
  disableCanvasRotation: boolean;
  isTransparent: boolean;
  backgroundColor?: string;
  showGrid: boolean;
  borderColor: string;
};

type Props = {
  avatarUrl: string | File;
  showSaveButton?: boolean;
  isRound?: boolean;
  onAvatarSave?: (formData: FormData | null) => void;
};

export interface AvatarPhotoEditorMethods {
  getAvatarImage: () => Promise<FormData | null>;
}

export const AvatarPhotoEditor = React.forwardRef<
  AvatarPhotoEditorMethods,
  Props
>(({ avatarUrl, showSaveButton = true, isRound = true, onAvatarSave }, ref) => {
  const editor = useRef<AvatarEditor>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [state, setState] = useState<State>({
    image: avatarUrl,
    allowZoomOut: false,
    position: { x: 0.5, y: 0.5 },
    scale: 1,
    rotate: 0,
    borderRadius: isRound ? 50 : 0,
    preview: undefined,
    width: 200,
    height: 200,
    disableCanvasRotation: false,
    isTransparent: false,
    backgroundColor: undefined,
    showGrid: false,
    borderColor: '#ffffff80',
  });

  const { t } = useTranslation();

  useEffect(() => {
    if (!avatarUrl) return;

    if (avatarUrl instanceof File) {
      setState(prevState => ({ ...prevState, image: avatarUrl }));
      return;
    }

    if (typeof avatarUrl === 'string') {
      if (avatarUrl.startsWith('data:')) {
        setState(prevState => ({ ...prevState, image: avatarUrl }));
      } else {
        convertUrlToFile(avatarUrl)
          .then(file => {
            setState(prevState => ({ ...prevState, image: file || avatarUrl }));
          })
          .catch(error => {
            console.error('Error converting avatar URL to file:', error);
            setState(prevState => ({ ...prevState, image: avatarUrl }));
          });
      }
    } else {
      setState(prevState => ({ ...prevState, image: avatarUrl }));
    }
  }, [avatarUrl]);

  const getAvatarImage = async (): Promise<FormData | null> => {
    if (editor.current) {
      const canvas = editor.current.getImageScaledToCanvas();
      try {
        return new Promise<FormData | null>(resolve => {
          canvas?.toBlob(blob => {
            if (!blob) {
              console.error('Failed to create blob from canvas');
              resolve(null);
              return;
            }

            const formData = new FormData();
            formData.append('file', blob, 'profile-picture.png');
            resolve(formData);
          }, 'image/png');
        });
      } catch {
        return null;
      }
    }
    return null;
  };

  useImperativeHandle(ref, () => ({
    getAvatarImage,
  }));

  const handleNewImage = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files?.[0]) {
      return;
    }
    setState({ ...state, image: e.target.files[0] });
  };

  const handleSave = async () => {
    if (!editor.current) return;

    try {
      const formData = await getAvatarImage();
      onAvatarSave?.(formData);

      if (editor.current && formData) {
        const canvas = editor.current.getImageScaledToCanvas();
        const img = canvas.toDataURL();
        const rect = editor.current.getCroppingRect();

        if (!rect) return;

        setState({
          ...state,
          preview: {
            img,
            rect,
            scale: state.scale,
            width: state.width,
            height: state.height,
            borderRadius: state.borderRadius,
          },
        });
      }
    } catch {
      onAvatarSave?.(null);
    }
  };

  const handleRotateLeft: MouseEventHandler<HTMLButtonElement> = e => {
    e.preventDefault();
    setState({ ...state, rotate: (state.rotate - 90) % 360 });
  };

  const handleRotateRight: MouseEventHandler<HTMLButtonElement> = e => {
    e.preventDefault();
    setState({ ...state, rotate: (state.rotate + 90) % 360 });
  };

  const handlePositionChange = (position: AvatarEditor.Position) => {
    setState({ ...state, position });
  };

  const handleZoomChange = (event: Event, value: number | number[]) => {
    const scale = Array.isArray(value) ? value[0] : value;
    setState({ ...state, scale });
  };

  const handleRotateScale = (event: Event, value: number | number[]) => {
    const rotate = Array.isArray(value) ? value[0] : value;
    setState({ ...state, rotate: rotate });
  };

  const handleOpen = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  return (
    <Box width="15rem">
      <input
        ref={fileInputRef}
        type="file"
        accept="image/jpeg,image/jpg,image/png"
        style={{ display: 'none' }}
        onChange={handleNewImage}
      />
      <Dropzone
        onDrop={([image]) => setState({ ...state, image })}
        noClick
        multiple={false}
      >
        {({ getRootProps, getInputProps }) => (
          <div {...getRootProps()} className="preview">
            <AvatarEditor
              ref={editor}
              scale={state.scale}
              width={state.width}
              height={state.height}
              position={state.position}
              onPositionChange={handlePositionChange}
              rotate={state.rotate}
              borderRadius={state.width / (100 / state.borderRadius)}
              backgroundColor={state.backgroundColor}
              image={state.image}
              disableCanvasRotation={state.disableCanvasRotation}
            />
            <input
              name="newImage"
              type="file"
              onChange={handleNewImage}
              {...getInputProps()}
            />
          </div>
        )}
      </Dropzone>
      <Box margin="1rem" width="15rem">
        <Typography variant="body2">{t('avatar.zoom')}</Typography>
        <Slider
          name="scale"
          onChange={handleZoomChange}
          min={state.allowZoomOut ? 0.1 : 1}
          max={2}
          step={0.01}
          defaultValue={1}
          aria-label="Default"
          valueLabelDisplay="auto"
        />
        <Typography variant="body2">{t('avatar.rotation')}</Typography>
        <Slider
          name="rotation"
          onChange={handleRotateScale}
          min={0}
          max={180}
          step={1}
          defaultValue={0}
          aria-label="Default"
          valueLabelDisplay="auto"
        />
        <Typography variant="body2">{t('avatar.rotate')}</Typography>

        <Box display="flex" justifyContent="space-between">
          <Box>
            <IconButton onClick={handleRotateLeft}>
              <RotateLeftOutlined fontSize="medium" />
            </IconButton>
            <IconButton onClick={handleRotateRight}>
              <RotateRightOutlined fontSize="medium" />
            </IconButton>
          </Box>

          <Box>
            <Tooltip
              placement="top-start"
              enterDelay={TOOLTIP_APPEAR_DELAY}
              enterNextDelay={TOOLTIP_APPEAR_DELAY}
              title={t('avatar.open')}
            >
              <IconButton onClick={handleOpen}>
                <FolderOpenOutlined fontSize="medium" color="primary" />
              </IconButton>
            </Tooltip>

            {showSaveButton && (
              <Tooltip
                placement="top-start"
                enterDelay={TOOLTIP_APPEAR_DELAY}
                enterNextDelay={TOOLTIP_APPEAR_DELAY}
                title={t('avatar.save')}
              >
                <IconButton onClick={handleSave}>
                  <SaveOutlined fontSize="medium" color="primary" />
                </IconButton>
              </Tooltip>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
});
