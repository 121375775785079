import React from 'react';

import { apiSpecEndpoint } from 'constants/endpoints';

export const ApiDocs = () => {
  return (
    <iframe
      src={apiSpecEndpoint()}
      frameBorder="0"
      height="100%"
      width="100%"
    />
  );
};
