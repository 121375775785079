import { useMemo } from 'react';

import { Template } from '@pdfme/common';

import { bindActionCreators, useDispatch, useSelector } from 'third-party';

import {
  bankCheckTemplateEndpoint,
  settingEndpoint,
  settingSingleEndpoint,
} from 'constants/endpoints';

import { appSelectors } from 'selectors';

import { httpMethod, simplifyBuilder } from 'utils/sra';

import { APISetting, APISettingName, PrintType } from 'types/api';

export const EMPTY_EMAIL_MESSAGE: any = {
  root: {
    type: 'EmailLayout',
    data: {
      backdropColor: '#F5F5F5',
      canvasColor: '#FFFFFF',
      textColor: '#262626',
      fontFamily: 'MODERN_SANS',
      childrenIds: [],
    },
  },
};

export type SettingsState = {
  emailTemplate: any;
  checkTemplate: Template | null;
  selectedTemplate: APISettingName;
  selectedBlockId: string | null;
  selectedSidebarTab: 'block-configuration' | 'styles';
  selectedMainTab: 'editor' | 'preview';
  selectedScreenSize: 'desktop' | 'mobile';
  loadCheckTemplatePending: boolean;
  saveCheckTemplatePending: boolean;
};

export const settingsState: SettingsState = {
  emailTemplate: EMPTY_EMAIL_MESSAGE,
  checkTemplate: null,
  selectedTemplate: APISettingName.NewECheckReceiveEmail,
  selectedBlockId: null,
  selectedSidebarTab: 'styles',
  selectedMainTab: 'editor',
  selectedScreenSize: 'desktop',
  loadCheckTemplatePending: false,
  saveCheckTemplatePending: false,
};

const builder = simplifyBuilder(settingsState, {});

export const setEmailTemplate = builder.createReduxAction((template: any) => ({
  name: 'setEmailTemplate',
  updater: state => ({
    emailTemplate: { ...state.emailTemplate, ...template },
  }),
}));

export const resetEmailTemplate = builder.createReduxAction(
  (template: any = EMPTY_EMAIL_MESSAGE) => ({
    name: 'resetEmailTemplate',
    updater: () => ({
      emailTemplate: template,
      selectedSidebarTab: 'styles',
      selectedBlockId: null,
    }),
  }),
);

export const setSelectedTemplateBlockId = builder.createReduxAction(
  (selectedBlockId: string | null) => ({
    name: 'setSelectedTemplateBlockId',
    updater: () => ({
      selectedBlockId,
      selectedSidebarTab:
        selectedBlockId === null ? 'styles' : 'block-configuration',
    }),
  }),
);

export const setSelectedSidebarTab = builder.createReduxAction(
  (selectedSidebarTab: 'block-configuration' | 'styles') => ({
    name: 'setSelectedSidebarTab',
    updater: () => ({
      selectedSidebarTab: selectedSidebarTab,
    }),
  }),
);

export const setSelectedScreenSize = builder.createReduxAction(
  (selectedScreenSize: 'desktop' | 'mobile') => ({
    name: 'setSelectedScreenSize',
    updater: () => ({
      selectedScreenSize: selectedScreenSize,
    }),
  }),
);

export const setSelectedMainTab = builder.createReduxAction(
  (selectedMainTab: 'editor' | 'preview') => ({
    name: 'setSelectedMainTab',
    updater: () => ({
      selectedMainTab: selectedMainTab,
    }),
  }),
);

const loadSetting = builder.createServerAction(
  (organizationId: string, name: APISettingName) => ({
    name: 'loadSetting',
    url: settingSingleEndpoint(organizationId, name),
    method: httpMethod.get,
    // onSuccess: we don't put anything to store, just return result for typeahead
  }),
);

const loadEmailTemplate = builder.createServerAction(
  (organizationId: string, name: APISettingName) => ({
    name: 'loadEmailTemplate',
    url: settingSingleEndpoint(organizationId, name),
    method: httpMethod.get,
    onSuccess: (state: SettingsState, payload: APISetting) => {
      const res = JSON.parse(payload.value)?.json ?? null;
      return {
        emailTemplate: res ?? EMPTY_EMAIL_MESSAGE,
      };
    },
  }),
);

export const setSelectedTemplate = builder.createReduxAction(
  (templateName: APISettingName) => ({
    name: 'setSelectedTemplate',
    updater: () => ({
      selectedTemplate: templateName,
    }),
  }),
);

const saveSetting = builder.createServerAction(
  (organizationId: string, setting: APISetting) => ({
    name: 'saveSetting',
    url: settingEndpoint(organizationId),
    body: setting,
    method: httpMethod.put,
    // onSuccess: we don't put anything to store, just return result for typeahead
  }),
);

const loadCheckTemplate = builder.createServerAction(
  (organizationId: string, bankId: string, type: PrintType) => ({
    name: 'loadCheckTemplate',
    url: bankCheckTemplateEndpoint(organizationId, bankId, type),
    method: httpMethod.get,
    onSuccess: (state: SettingsState, payload: Template) => ({
      checkTemplate: payload,
    }),
  }),
);

const saveCheckTemplate = builder.createServerAction(
  (
    organizationId: string,
    bankId: string,
    template: Template,
    type: PrintType,
  ) => ({
    name: 'saveCheckTemplate',
    url: bankCheckTemplateEndpoint(organizationId, bankId, type),
    body: template,
    method: httpMethod.post,
    onSuccess: (state: SettingsState, payload: Template) => ({
      checkTemplate: payload,
    }),
  }),
);

export const useSettings = () => {
  const dispatch = useDispatch();
  const organizationId = useSelector(appSelectors.organizationId);

  return useMemo(
    () =>
      bindActionCreators(
        {
          loadSetting: loadSetting.bind(null, organizationId),
          saveSetting: saveSetting.bind(null, organizationId),
          loadEmailTemplate: loadEmailTemplate.bind(null, organizationId),
          loadCheckTemplate: loadCheckTemplate.bind(null, organizationId),
          saveCheckTemplate: saveCheckTemplate.bind(null, organizationId),
          setSelectedTemplate,
          setEmailTemplate,
          setSelectedTemplateBlockId,
          setSelectedSidebarTab,
          setSelectedMainTab,
          setSelectedScreenSize,
          resetEmailTemplate,
        },
        dispatch,
      ),
    [dispatch, organizationId],
  );
};

export const settingsReducer = builder.getReducers();
