import React from 'react';

import { Chip, MenuItem } from 'components';
import { ListFilter } from 'lucide-react';

import { PayeeColors } from 'constants/common';

import { ColumnFilteringProps, TableColumn } from 'components/Table/types';

import { APIPayee, APIPayeeStatus } from 'types/api';

export const StatusMenu = [
  <MenuItem key={APIPayeeStatus.NotActive} value={APIPayeeStatus.NotActive}>
    NotActive
  </MenuItem>,
  <MenuItem key={APIPayeeStatus.Deleted} value={APIPayeeStatus.Deleted}>
    Deleted
  </MenuItem>,
  <MenuItem key={APIPayeeStatus.Archive} value={APIPayeeStatus.Archive}>
    Archive
  </MenuItem>,
  <MenuItem key={APIPayeeStatus.Active} value={APIPayeeStatus.Active}>
    Active
  </MenuItem>,
  <MenuItem key={APIPayeeStatus.Rejected} value={APIPayeeStatus.Rejected}>
    Rejected
  </MenuItem>,
  <MenuItem
    key={APIPayeeStatus.PendingForReview}
    value={APIPayeeStatus.PendingForReview}
  >
    PendingForReview
  </MenuItem>,
];

export const PayeeStatusFilter: React.FC<ColumnFilteringProps> = props => {
  return (
    <ListFilter {...props}>
      {StatusMenu}
      <MenuItem value={undefined} />
    </ListFilter>
  );
};

export const payeeColumns: TableColumn<APIPayee>[] = [
  {
    dataId: 'id',
    label: 'payeeList.column.id',
    minWidth: 80,
    width: 140,
    hidden: true,
    sortable: true,
    resizable: true,
  },
  {
    dataId: 'status',
    label: 'payeeList.column.status',
    minWidth: 80,
    width: 140,
    hidden: false,
    sortable: true,
    resizable: true,
    customFilter: PayeeStatusFilter,
    formatter: value => ({
      value: 'test',
      element: (
        <Chip
          label={value}
          style={{
            backgroundColor: PayeeColors[value as APIPayeeStatus],
          }}
        />
      ),
    }),
  },
  {
    dataId: 'nameOnCheck',
    label: 'payeeList.column.nameOnCheck',
    minWidth: 80,
    width: 140,
    hidden: false,
    sortable: true,
    resizable: true,
  },
  {
    dataId: 'email',
    label: 'payeeList.column.email',
    minWidth: 80,
    width: 140,
    hidden: false,
    sortable: true,
    resizable: true,
  },
  {
    dataId: 'phone',
    label: 'payeeList.column.phone',
    minWidth: 80,
    width: 100,
    hidden: false,
    sortable: true,
    resizable: true,
  },
  {
    dataId: 'address',
    label: 'payeeList.column.address',
    minWidth: 80,
    width: 170,
    hidden: false,
    sortable: true,
    resizable: true,
  },
  {
    dataId: 'city',
    label: 'payeeList.column.city',
    minWidth: 80,
    width: 140,
    hidden: false,
    sortable: true,
    resizable: true,
  },
  {
    dataId: 'state',
    label: 'payeeList.column.state',
    minWidth: 80,
    width: 80,
    hidden: false,
    sortable: true,
    resizable: true,
  },
  {
    dataId: 'zip',
    label: 'payeeList.column.zip',
    minWidth: 80,
    width: 100,
    hidden: false,
    sortable: true,
    resizable: true,
  },
  {
    dataId: 'user.firstName',
    label: 'payeeList.column.user.firstName',
    minWidth: 80,
    width: 100,
    hidden: true,
    sortable: true,
    resizable: true,
  },
  {
    dataId: 'user.lastName',
    label: 'payeeList.column.user.lastName',
    minWidth: 80,
    width: 100,
    hidden: true,
    sortable: true,
    resizable: true,
  },
  {
    dataId: 'user.email',
    label: 'payeeList.column.user.email',
    minWidth: 80,
    width: 100,
    hidden: true,
    sortable: true,
    resizable: true,
  },
];
