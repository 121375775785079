import { AnonymsState, anonymsReducer } from './anonyms';
import { AppState, appReducer } from './app';
import { BankState, bankReducer } from './bank';
import { CacheState, cacheReducer } from './cache';
import { OrganizationState, organizationReducer } from './organization';
import { PayeeState, payeeReducer } from './payee';
import { PaymentState, paymentReducer } from './payment';
import { SettingsState, settingsReducer } from './setting';
import { UserState, userReducer } from './user';

import { applyMiddleware, createStore } from 'third-party';

import { getUserToken } from 'utils/auth';
import { combineReducers, compose, middlewareBuilder } from 'utils/sra';

export type State = {
  app: AppState;
  bank: BankState;
  organization: OrganizationState;
  user: UserState;
  cache: CacheState;
  payment: PaymentState;
  payee: PayeeState;
  settings: SettingsState;
  anonyms: AnonymsState;
};

// custom middleware for web requests
const middleWare = [
  middlewareBuilder({
    httpRequestHandler: async (url: string, options?: Partial<RequestInit>) => {
      const isFormData = options?.body instanceof FormData;

      const headers: Record<string, string> = {
        Authorization: getUserToken(),
      };

      // Only add Content-Type if not FormData
      if (!isFormData) {
        headers['Content-Type'] = 'application/json';
        headers['accept'] = 'application/json';
        headers['client-os'] = 'web';
      }

      // Merge with any custom headers
      const mergedHeaders = {
        ...headers,
        ...options?.headers,
      };

      return await fetch(url, {
        ...options,
        headers: new Headers(mergedHeaders),
      });
    },
  }),
];
// added support for redux-dev tool
export const composeEnhancers =
  (window && (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

// create store for redux
export const combinedReducer = combineReducers({
  app: appReducer,
  bank: bankReducer,
  user: userReducer,
  cache: cacheReducer,
  organization: organizationReducer,
  payment: paymentReducer,
  payee: payeeReducer,
  settings: settingsReducer,
  anonyms: anonymsReducer,
});

export const store = createStore(
  combinedReducer,
  composeEnhancers(applyMiddleware(...middleWare)),
);
