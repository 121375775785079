import { APIPayeeStatus, APIPaymentStatus } from 'types/swaggerApi';

export const SUPPORT_EMAIL = 'support@EasyCheck.org';

export const INFINITE_SCROLL_BOTTOM_OFFSET_PX = 300;
export const INFINITE_SCROLL_DEBOUNCE_TIME_MS = 3000;
export const TOOLTIP_APPEAR_DELAY = 1000;

export const REFRESH_TOKEN_IN_MINUTES = 10;
export const DEFAULT_PAGE_SIZE = 20;
export const USER_INPUT_DEBOUNCE_TIME_MS = 500;

export const DISPLAY_DATE_FORMAT = 'MM/DD/YYYY';

export const PaymentColors = {
  [APIPaymentStatus.Draft]: '#D3D3D3',
  [APIPaymentStatus.Requested]: '#addce6',
  [APIPaymentStatus.Created]: '#fbf198',
  [APIPaymentStatus.Void]: '#FFB6C1',
  [APIPaymentStatus.TryToPrint]: '#B0E0E6',
  [APIPaymentStatus.OpenToPrint]: '#FFA07A',
  [APIPaymentStatus.CouldNotPrint]: '#FFA07A',
  [APIPaymentStatus.Printed]: '#90EE90',
  [APIPaymentStatus.Emailed]: '#98FB98',
  [APIPaymentStatus.Mailed]: '#8FBC8F',
  [APIPaymentStatus.Expired]: '#F08080',
  [APIPaymentStatus.Cleared]: '#90EE90',
};

export const PaymentStatus = {
  [APIPaymentStatus.Draft]: 'Draft',
  [APIPaymentStatus.Requested]: 'Requested',
  [APIPaymentStatus.Created]: 'Created',
  [APIPaymentStatus.Void]: 'Void',
  [APIPaymentStatus.TryToPrint]: 'Try To Print',
  [APIPaymentStatus.OpenToPrint]: 'Open To Print',
  [APIPaymentStatus.CouldNotPrint]: 'Could Not Print',
  [APIPaymentStatus.Printed]: 'Printed',
  [APIPaymentStatus.Emailed]: 'Emailed',
  [APIPaymentStatus.Mailed]: 'Mailed',
  [APIPaymentStatus.Expired]: 'Expired',
  [APIPaymentStatus.Cleared]: 'Cleared',
};

export const PayeeColors = {
  [APIPayeeStatus.NotActive]: '#F08080',
  [APIPayeeStatus.Deleted]: '#FFB6C1',
  [APIPayeeStatus.Archive]: '#D3D3D3',
  [APIPayeeStatus.Active]: '#90EE90',
  [APIPayeeStatus.Rejected]: '#FFA07A',
  [APIPayeeStatus.PendingForReview]: '#fbf198',
};

export const PayeeStatus = {
  [APIPayeeStatus.NotActive]: 'Not Active',
  [APIPayeeStatus.Deleted]: 'Deleted',
  [APIPayeeStatus.Archive]: 'Archive',
  [APIPayeeStatus.Active]: 'Active',
  [APIPayeeStatus.Rejected]: 'Rejected',
  [APIPayeeStatus.PendingForReview]: 'Pending For Review',
};
