export const printPdf = (() => {
  const isFirefox: boolean = /Gecko\/\d/.test(navigator.userAgent);
  const isAndroid: boolean = /Android/.test(navigator.userAgent);
  const firefoxDelay: number = 1000;
  let iframe: HTMLIFrameElement | null = null;

  return function (url: string, callback?: () => void): void {
    if (iframe) {
      iframe.parentNode?.removeChild(iframe);
    }

    iframe = document.createElement('iframe');
    iframe.style.cssText =
      'width: 1px; height: 100px; position: fixed; left: 0; top: 0; opacity: 0; border-width: 0; margin: 0; padding: 0';

    const xhr = new XMLHttpRequest();
    try {
      xhr.responseType = 'arraybuffer';
    } catch {
      window.open(url, '_blank');
      return;
    }

    xhr.addEventListener('load', function () {
      if (xhr.status === 200 || xhr.status === 201) {
        const pdfBlob = new Blob([xhr.response as ArrayBuffer], {
          type: 'application/pdf',
        });
        const iframeUrl = URL.createObjectURL(pdfBlob);

        if (iframe) {
          iframe.src = iframeUrl;

          iframe.addEventListener('load', function () {
            const openPrintPreview = (): void => {
              try {
                iframe?.focus();
                iframe?.contentWindow?.print();
              } catch (error) {
                console.error('Print preview failed: ' + error, error);
              } finally {
                URL.revokeObjectURL(iframeUrl);
              }
            };

            if (isFirefox) {
              window.setTimeout(openPrintPreview, firefoxDelay);
            } else if (isAndroid) {
              const newWindow = window.open('', '_blank');
              if (newWindow) {
                newWindow.document.write(`
                  <!DOCTYPE html>
                  <html lang="en">
                  <head>
                      <meta charset="UTF-8">
                      <meta name="viewport" content="width=device-width, initial-scale=1.0">
                      <title>Print PDF</title>
                      <style>
                          /* CSS Reset */
                          * {
                              margin: 0;
                              padding: 0;
                              box-sizing: border-box;
                          }
                  
                          html, body {
                              width: 100%;
                              height: 100%;
                              overflow: hidden;
                          }
                  
                          #printButton {
                              position: fixed;
                              bottom: 10px;
                              left: 50px;
                              z-index: 9999;
                              padding: 10px;
                              background: #1F51FF;
                              color: #fff;
                              cursor: pointer;
                          }
                  
                          #pdfContainer {
                              width: 100%;
                              height: 100%;
                              display: flex;
                              align-items: center;
                              justify-content: center;
                          }
                  
                          @media print {
                              #printButton {
                                  display: none;
                              }
                  
                              html, body, #pdfContainer {
                                  margin: 0 !important;
                                  padding: 0 !important;
                                  width: 100%;
                                  height: 100%;
                                  overflow: hidden !important;
                              }
                  
                              #pdfContainer canvas {
                                  width: 100% !important;
                                  height: 100% !important;
                              }
                          }
                      </style>
                  </head>
                  <body>
                  <button id="printButton">Print</button>
                  <div id="pdfContainer"></div>
                  
                  <script src="https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.11.338/pdf.min.js"></script>
                  <script>
                      document.getElementById('printButton').addEventListener('click', function() {
                          window.print();
                      });
                  
                      // Initialize PDF.js
                      const pdfContainer = document.getElementById('pdfContainer');
                      const loadingTask = pdfjsLib.getDocument("${iframeUrl}"); // Use the blob URL created for the PDF
                      loadingTask.promise.then(function(pdf) {
                          // Fetch the first page
                          pdf.getPage(1).then(function(page) {
                              const scale = 2; // Adjust scale to ensure full-page fit
                              const viewport = page.getViewport({ scale: scale });
                              const canvas = document.createElement('canvas');
                              const context = canvas.getContext('2d');
                              canvas.height = viewport.height;
                              canvas.width = viewport.width;
                              const renderContext = {
                                  canvasContext: context,
                                  viewport: viewport
                              };
                              page.render(renderContext).promise.then(function() {
                                  pdfContainer.innerHTML = '';
                                  pdfContainer.appendChild(canvas);
                                  // Adjust canvas to fit the container
                                  canvas.style.width = '100%';
                                  canvas.style.height = '100%';
                              });
                          });
                      });
                  </script>
                  </body>
                  </html>
                `);
                newWindow.document.close();
              } else {
                alert('Please allow popups for this website');
              }
            } else {
              openPrintPreview();
            }
          });

          document.body.appendChild(iframe);
        }
      }
    });

    if (callback) {
      xhr.addEventListener('load', function () {
        if (xhr.status === 200 || xhr.status === 201) {
          setTimeout(callback, isFirefox ? firefoxDelay + 100 : 100);
        }
      });
    }

    xhr.open('GET', url, true);
    xhr.send();
  };
})();
