import React, { useEffect, useState } from 'react';

import {
  CheckCircleOutlineOutlined,
  Visibility,
  VisibilityOff,
} from '@mui/icons-material';
import {
  Box,
  Button,
  Container,
  IconButton,
  InputAdornment,
  Loader,
  Typography,
} from 'components';
import { useNotifications } from 'hooks';

import {
  styled,
  useForm,
  useNavigate,
  useParams,
  useSelector,
  useTranslation,
} from 'third-party';

import { dashboardUrl, loginUrl, notFoundUrl } from 'constants/url';

import { useApp } from 'modules/app';

import { appSelectors } from 'selectors';

import { FormField } from 'components/FormField/FormField';

import { passwordField } from 'utils/validations';

const Form = styled.form`
  display: flex;
  flex-direction: column;

  min-width: 25rem;
`;

enum FormFields {
  Password = 'password',
  ConfirmPassword = 'confirmPassword',
}

const resetPasswordFormDefaultValues = {
  [FormFields.Password]: '',
  [FormFields.ConfirmPassword]: '',
};

type FormData = typeof resetPasswordFormDefaultValues;

export const ResetPassword = () => {
  const { t } = useTranslation();
  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<typeof resetPasswordFormDefaultValues>({
    defaultValues: resetPasswordFormDefaultValues,
  });

  const navigate = useNavigate();
  const { validateUserResetPasswordToken, userResetPassword } = useApp();
  const { email, token } = useParams();
  const { error } = useNotifications();
  const userInfoPending = useSelector(appSelectors.userInfoPending);
  const user = useSelector(appSelectors.user);

  const [isPasswordResetTokenValid, setIsPasswordResetTokenValid] =
    useState(false);
  const [resetCompleted, setResetCompleted] = useState(false);

  const [showPassword, setShowPassword] = useState(false);

  const watchedPasswordField = watch(FormFields.Password);

  useEffect(() => {
    if (!userInfoPending && user) {
      navigate(dashboardUrl());
    }
  }, [navigate, user, userInfoPending]);

  const onSubmit = async (formData: FormData) => {
    const { password } = formData;
    if (!email || !token) {
      error(t('resetPassword.invalidTokenOrEmail'));
      return;
    }
    const result = await userResetPassword({
      email: email,
      password: password,
      resetPasswordToken: token,
    });

    if (result.error) {
      error(result.payload.body?.title || t('register.genericError'));
      return;
    }
    setResetCompleted(true);
  };

  const backToLogin = () => {
    navigate(loginUrl());
  };

  useEffect(() => {
    if (resetCompleted) {
      return;
    }

    if (!email || !token) {
      error(t('resetPassword.invalidTokenOrEmail'));
      navigate(notFoundUrl());
      return;
    }

    const validateToken = async () => {
      const result = await validateUserResetPasswordToken(email, token);
      if (result.error || !result.payload) {
        error(t('resetPassword.invalidTokenOrEmail'));
        navigate(notFoundUrl());
        return;
      }
      setIsPasswordResetTokenValid(result.payload);
    };
    validateToken();
  }, [
    email,
    token,
    error,
    navigate,
    t,
    validateUserResetPasswordToken,
    resetCompleted,
  ]);

  if (!isPasswordResetTokenValid) {
    return <Loader />;
  }

  if (resetCompleted) {
    return (
      <Container maxWidth="sm">
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            minHeight: '100vh',
            textAlign: 'center',
            gap: 3,
          }}
        >
          <CheckCircleOutlineOutlined
            sx={{ fontSize: 100, color: 'primary.main' }}
          />

          <Typography
            variant="h2"
            sx={{
              textAlign: 'center',
              width: '100%',
            }}
          >
            {t('resetPassword.completed.header')}
          </Typography>
          <Box>
            <Typography variant="h2" sx={{ textAlign: 'center' }}>
              {t('resetPassword.completed.subHeader')}
            </Typography>
          </Box>

          <Button
            variant="contained"
            size="large"
            onClick={backToLogin}
            sx={{ mt: 2 }}
          >
            {t('common.navigation.backToLogin')}
          </Button>
        </Box>
      </Container>
    );
  }

  return (
    <Box>
      <>
        <Box
          sx={{
            width: '100%',
            position: 'relative',
            paddingBottom: '0.3rem',
          }}
        >
          <Typography
            variant="h2"
            sx={{
              textAlign: 'center',
              width: '100%',
            }}
          >
            {t('resetPassword.header')}
          </Typography>
        </Box>

        <Box sx={{ paddingBottom: '1rem', textAlign: 'center' }}>
          <Typography variant="subtitle1" sx={{ paddingTop: '0.8rem' }}>
            {t('resetPassword.subHeader')}
          </Typography>
        </Box>
      </>

      <Form onSubmit={handleSubmit(onSubmit)}>
        <FormField
          type={showPassword ? 'text' : 'password'}
          fieldName={FormFields.Password}
          fieldError={errors[FormFields.Password]}
          label={t('register.fields.password')}
          placeholder={
            t('common.form.enterValueFor') + t('register.fields.password')
          }
          requiredErrorMessage={t('errors.fields.emptyFieldError')}
          control={control}
          maxLength={passwordField.maxLength}
          isRequired
          showIsRequiredMark={false}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  sx={{ padding: 0 }}
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <FormField
          type={showPassword ? 'text' : 'password'}
          fieldName={FormFields.ConfirmPassword}
          fieldError={errors[FormFields.ConfirmPassword]}
          label={t('register.fields.confirmPassword')}
          placeholder={
            t('common.form.enterValueFor') +
            t('register.fields.confirmPassword')
          }
          // showError={touchedFields[FormFields.ConfirmPassword] === true}
          requiredErrorMessage={t('errors.fields.emptyFieldError')}
          control={control}
          maxLength={passwordField.maxLength}
          isRequired
          showIsRequiredMark={false}
          rules={{
            validate: {
              matchPasswordField: (value: string) =>
                !watchedPasswordField ||
                value === watchedPasswordField ||
                t('register.error.passwordNotSame'),
            },
            deps: [FormFields.Password],
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  sx={{ padding: 0 }}
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <Box
          display="flex"
          flexDirection="column"
          paddingTop="1rem"
          gap="1.7rem"
        >
          <Button
            type="submit"
            variant="outlined"
            size="medium"
            color="primary"
          >
            {t('resetPassword.confirm')}
          </Button>
        </Box>
      </Form>
    </Box>
  );
};
