import React from 'react';

import { Box, Typography } from 'components';

type Props = {
  header?: string;
  subHeader?: string;
  textAlignment?: 'center' | 'left' | 'right';
  children?: React.ReactNode;
};
export const PageHeader: React.FC<Props> = ({
  header,
  subHeader,
  children,
  textAlignment,
}) => {
  return (
    <Box zIndex={10} top={0} position="sticky" bgcolor="white">
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        gap="1rem"
        paddingTop="0.5rem"
      >
        <Box>
          {header && (
            <Typography textAlign={textAlignment} variant="h2">
              {header}
            </Typography>
          )}
          {subHeader && (
            <Typography
              sx={{ display: { xs: 'none' } }}
              textAlign={textAlignment}
              variant="subtitle1"
            >
              {subHeader}
            </Typography>
          )}
        </Box>
        <Box>{children}</Box>
      </Box>
    </Box>
  );
};
