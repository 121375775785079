import avatar from 'images/avatar.png';
import company from 'images/company.png';

import { cdnEndpoint } from 'constants/endpoints';

import {
  APIOrganization,
  APIPayment,
  APIPaymentStatus,
  APIUser,
} from 'types/api';

export const userCanDownloadCheck = (item: APIPayment) => {
  return (
    item.status === APIPaymentStatus.Created ||
    item.status === APIPaymentStatus.OpenToPrint ||
    item.status === APIPaymentStatus.TryToPrint ||
    item.status === APIPaymentStatus.CouldNotPrint
  );
};

export const getAvatarUrl = (user: APIUser) => {
  if (!user.avatar) {
    return avatar;
  }
  return `${cdnEndpoint()}files/${user.id}/images/${user.avatar}`;
};

export const getOrganizationAvatarUrl = (
  organization?: APIOrganization | undefined,
) => {
  if (!organization || !organization.avatar) {
    return company;
  }
  return `${cdnEndpoint()}files/${organization.id}/images/${
    organization.avatar
  }`;
};

export const convertUrlToFile = async (url: string): Promise<File | null> => {
  try {
    const response = await fetch(url);
    if (!response.ok) {
      console.error('Failed to fetch avatar image:', response.statusText);
      return null;
    }

    const blob = await response.blob();
    const fileName = url.split('/').pop() || 'profile-picture.png';
    const fileType = blob.type || 'image/png';
    return new File([blob], fileName, { type: fileType });
  } catch (error) {
    console.error('Error converting avatar URL to file:', error);
    return null;
  }
};

export const downloadExportFile = (blob: Blob, filename: string) => {
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.download = filename;
  document.body.appendChild(link);
  link.click();
  window.URL.revokeObjectURL(url);
  document.body.removeChild(link);
};

export const moneyFormatter = (money: string | number | null) => {
  const formattedMoney = parseFloat(money?.toString() ?? '0').toLocaleString(
    'en-US',
    {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
  );

  return `$${formattedMoney}`;
};
