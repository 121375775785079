import React, { useEffect, useState } from 'react';

import PersonOutlineRoundedIcon from '@mui/icons-material/PersonOutlineRounded';

import { Visibility, VisibilityOff } from '@mui/icons-material';
import {
  Box,
  Button,
  Divider,
  IconButton,
  InputAdornment,
  Link,
  LoadingButton,
  Typography,
} from 'components';
import { useNotifications } from 'hooks';
import logo from 'images/logo-black.svg';

import { useForm, useNavigate, useSelector, useTranslation } from 'third-party';

import { dashboardUrl, forgotPasswordUrl, registerUrl } from 'constants/url';

import { useApp } from 'modules/app';

import { appSelectors } from 'selectors';

import { FormField } from 'components/FormField/FormField';

import { emailField } from 'utils/validations';

enum FormFields {
  Email = 'email',
  Password = 'password',
}

const loginFormDefaultValues = {
  [FormFields.Email]: '',
  [FormFields.Password]: '',
};

type LoginFormData = typeof loginFormDefaultValues;

export const Login = () => {
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = useState(false);
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<typeof loginFormDefaultValues>({
    defaultValues: loginFormDefaultValues,
  });

  const navigate = useNavigate();
  const { userLogIn, userInfo } = useApp();
  const { error } = useNotifications();
  const userInfoPending = useSelector(appSelectors.userInfoPending);
  const userLoginInPending = useSelector(appSelectors.userLoginInPending);
  const user = useSelector(appSelectors.user);

  useEffect(() => {
    if (!userInfoPending && user) {
      navigate(dashboardUrl());
    }
  }, [navigate, user, userInfoPending]);

  const onSubmit = async ({ email, password }: LoginFormData) => {
    const result = await userLogIn(email, password);

    if (result.error) {
      error(t('login.invalidCredentials'));
      return;
    }
    await userInfo();
    navigate(dashboardUrl());
  };

  const onRegisterClick = () => {
    navigate(registerUrl());
  };

  const onForgotPasswordClick = () => {
    navigate(forgotPasswordUrl());
  };

  return (
    <Box>
      <Box paddingBottom="1rem">
        <Box
          margin="1.5rem 2rem 0.8rem 1rem"
          alignContent="center"
          justifyContent="center"
          display={{ xs: 'flex', md: 'none' }}
        >
          <img src={logo} height="40px" />
        </Box>

        <Typography variant="h2" textAlign="center">
          {t('login.header')}
        </Typography>
        <Typography variant="subtitle1" paddingTop="0.8rem">
          {t('login.subHeader')}
        </Typography>
      </Box>
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        display="flex"
        flexDirection="column"
      >
        <FormField
          fieldName={FormFields.Email}
          fieldError={errors[FormFields.Email]}
          placeholder={t('common.form.email.placeholder')}
          requiredErrorMessage={t('errors.fields.emptyFieldError')}
          control={control}
          isDisabled={userLoginInPending}
          maxLength={emailField.maxLength}
          isRequired
          showIsRequiredMark={false}
          rules={{
            validate: {
              invalidEmail: (value: string) =>
                emailField.validate(value) ||
                t('common.form.email.error.invalid'),
            },
          }}
        />
        <FormField
          type={showPassword ? 'text' : 'password'}
          fieldName={FormFields.Password}
          fieldError={errors[FormFields.Password]}
          placeholder={t('common.form.password.placeholder')}
          requiredErrorMessage={t('errors.fields.emptyFieldError')}
          control={control}
          maxLength={emailField.maxLength}
          isDisabled={userLoginInPending}
          isRequired
          showIsRequiredMark={false}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  sx={{ padding: 0 }}
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <Box
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
          paddingTop="0.2rem"
        >
          <Link
            component="button"
            variant="body2"
            onClick={onForgotPasswordClick}
          >
            {t('login.forgotPassword')}
          </Link>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          paddingTop="1rem"
          gap="1.7rem"
        >
          <LoadingButton
            type="submit"
            variant="contained"
            size="medium"
            color="primary"
            loading={userLoginInPending}
          >
            {t('login.signIn')}
          </LoadingButton>
          <Divider>{t('login.continueWith')}</Divider>
          <Button
            variant="outlined"
            size="medium"
            color="primary"
            onClick={onRegisterClick}
          >
            <PersonOutlineRoundedIcon />
            {t('login.register')}
          </Button>
          <Typography variant="subtitle1" textAlign="center" maxWidth="25rem">
            {t('login.terms')}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
