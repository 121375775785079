import React, { useCallback, useEffect, useState } from 'react';

import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';

import { DialogModal, Loader, MenuItem, ModalBox, Table } from 'components';

import { PayeeCreateEditForm } from './PayeeCreateEditForm';
import { payeeColumns } from './payeeColumns';

import { dayjs, useSelector, useTranslation } from 'third-party';

import { usePayee } from 'modules/payee';

import { appSelectors, payeeSelectors } from 'selectors';

import { downloadExportFile } from 'utils/app';

import { APIPayee, APIPayeeStatus, APISortOrder } from 'types/api';

export const PayeeList = () => {
  const { t } = useTranslation();
  const [columns, setColumns] = useState(payeeColumns);
  const [selectedItem, setSelectedItem] = useState<APIPayee | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const {
    loadPayees,
    loadMorePayees,
    updatePayeeFilters,
    deletePayee,
    clearPayeeList,
    updatePayee,
    resetPayeeFilters,
    exportPayees,
  } = usePayee();

  const items = useSelector(payeeSelectors.payeeList);
  const organizationId = useSelector(appSelectors.organizationId);
  const payeesListLoaded = useSelector(payeeSelectors.payeesListLoaded);
  const allPayeesLoaded = useSelector(payeeSelectors.allPayeesLoaded);
  const payeeFilters = useSelector(payeeSelectors.payeeFilters);
  const loadPayeesPending = useSelector(payeeSelectors.loadPayeesPending);
  const loadMorePayeesPending = useSelector(
    payeeSelectors.loadMorePayeesPending,
  );
  const exportPayeesPending = useSelector(payeeSelectors.exportPayeesPending);
  const isAdminUser = useSelector(appSelectors.isAdminUser);

  useEffect(() => {
    return () => {
      clearPayeeList();
      resetPayeeFilters();
    };
  }, [clearPayeeList, resetPayeeFilters]);

  useEffect(() => {
    loadPayees();
  }, [payeeFilters, loadPayees]);

  const onColumnFilterChange = useCallback(
    (columnId: string, value: string) => {
      setColumns(
        columns.map(column => {
          if (column.dataId === columnId) {
            return { ...column, filter: value };
          }
          return column;
        }),
      );
      updatePayeeFilters({ ...payeeFilters, [columnId]: value });
    },
    [payeeFilters, columns, updatePayeeFilters],
  );

  const onFilterReset = useCallback(() => {
    resetPayeeFilters();
    setColumns(it => it.map(column => ({ ...column, filter: undefined })));
  }, [resetPayeeFilters]);

  const onColumnVisibilityChanged = useCallback(
    (columnId: string, value: boolean) => {
      setColumns(it =>
        it.map(column => {
          if (column.dataId === columnId) {
            return { ...column, hidden: !value };
          }
          return column;
        }),
      );
    },
    [],
  );

  const rowOptionsMenu = useCallback(
    (item: APIPayee, onRowMenuClose: () => void): React.ReactElement[] => [
      <MenuItem
        key={item.status == APIPayeeStatus.Archive ? 'activate' : 'archive'}
        onClick={() => {
          updatePayee(item.organization.id, item.id, {
            ...item,
            userId: item.user.id,
            status:
              item.status == APIPayeeStatus.Archive
                ? APIPayeeStatus.Active
                : APIPayeeStatus.Active,
          });
        }}
      >
        <DeleteOutlineOutlinedIcon
          color="primary"
          style={{ paddingRight: '0.5rem' }}
        />
        {t('table.delete')}
      </MenuItem>,
      <MenuItem
        key={'edit'}
        onClick={() => {
          setSelectedItem(item);
          setIsModalOpen(true);
          onRowMenuClose();
        }}
      >
        <EditOutlinedIcon color="primary" style={{ paddingRight: '0.5rem' }} />
        {t('table.edit')}
      </MenuItem>,
    ],
    [t, updatePayee],
  );

  const onModalClose = useCallback(() => {
    setIsModalOpen(false);
    setSelectedItem(null);
  }, []);

  const onColumnHeaderClicked = useCallback(
    (columnDataId: string) => {
      const existingSortOrder = payeeFilters.searchSortOrders?.find(
        order => order.sortField === columnDataId,
      );

      const newSortOrder =
        existingSortOrder && existingSortOrder.sortOrder === APISortOrder.DESC
          ? APISortOrder.ASC
          : APISortOrder.DESC;

      updatePayeeFilters({
        ...payeeFilters,
        searchSortOrders: [
          {
            sortField: columnDataId,
            sortOrder: newSortOrder,
          },
        ],
      });
    },
    [payeeFilters, updatePayeeFilters],
  );

  if (!payeesListLoaded) {
    return <Loader />;
  }

  const onExportClick = async () => {
    const response = await exportPayees();

    downloadExportFile(
      response.payload,
      `payments-export-${dayjs().format('YYYY-MM-DD')}.xlsx`,
    );
  };

  return (
    <>
      <Table<APIPayee>
        header={t('payeeList.header')}
        subHeader={t('payeeList.subHeader')}
        filterable
        allItemsLoaded={allPayeesLoaded}
        infiniteScroll
        tableMinHeight="20rem"
        columns={columns}
        items={items || []}
        sortOrder={payeeFilters.searchSortOrders?.[0]?.sortOrder}
        rowOptionsMenu={isAdminUser ? rowOptionsMenu : undefined}
        onColumnHeaderClicked={onColumnHeaderClicked}
        onColumnFilterChange={onColumnFilterChange}
        onColumnVisibilityChanged={onColumnVisibilityChanged}
        isExportPending={exportPayeesPending}
        onExportClick={onExportClick}
        loadItemsPending={loadPayeesPending}
        loadMoreItemsPending={loadMorePayeesPending}
        onLoadMoreClicked={loadMorePayees}
        onFilterReset={onFilterReset}
        bodyRowHeight="1rem"
        headerRowHeight="0.2rem"
        itemsLoaded={payeesListLoaded}
        onNewItemButtonClicked={
          isAdminUser ? () => setIsModalOpen(true) : undefined
        }
        emptyText={t('table.empty')}
        loadMoreButtonText={t('table.loadMore')}
      />
      {isModalOpen && (
        <ModalBox width="30rem" height="39rem" onClose={onModalClose}>
          <PayeeCreateEditForm
            organizationId={organizationId}
            onModalClose={onModalClose}
            payeeId={selectedItem?.id}
          />
        </ModalBox>
      )}
      <DialogModal
        onClose={() => setSelectedItem(null)}
        open={!!selectedItem && !isModalOpen}
        description={t('payeeList.deleteConfirm')}
        handleActionClick={() => {
          if (selectedItem) {
            deletePayee(selectedItem.id);
          }
          setSelectedItem(null);
        }}
      />
    </>
  );
};
